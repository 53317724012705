<div class="form-group flex-item d-flex" [formGroup]="control">
  <div class="item" *ngIf="i>0">
    <div class="form-group">
      <div class="group-input">
        <ng-select
          [markFirst]="false"
          [items]="interConditionSet"
          bindLabel="name"
          dropdownPosition="auto"
          bindLabel="name"
          bindValue="id"
          placeholder="Select Condition"
          [clearable]="false"
          [closeOnSelect]="true"
          formControlName="conjuction"
          appendTo="body"
        >
        <ng-template ng-label-tmp let-item="item">
          <span [innerHtml]="item.name"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span [innerHtml]="item.name"></span>
    </ng-template>
        </ng-select>
      </div>
      <span 
      *ngIf="control?.controls && control.controls['conjuction'].invalid && (control.controls['conjuction'].dirty || control.controls['conjuction'].touched)" 
      class="logic-error">This field is required</span>
    </div>
  </div>
  <div class="item" *ngIf="i==0" [formGroup]="parentForm">
    <div class="form-group">
      <div class="group-input">
        <ng-select
        [markFirst]="false"
        [items]="querstionOrRaterGroup"
        bindLabel="name"
        dropdownPosition="auto"
        bindValue="id"
        formControlName="group_type"
        placeholder="Question Or RaterGroup"
        [clearable]="false"
        [closeOnSelect]="true"
        appendTo="body"
        (change)="onQuestionOrRaterGroupSelect($event)"
      ></ng-select>
      </div>
    </div>
  </div>
  <div class="item" *ngIf="i==0 && isRaterGroup" [formGroup]="parentForm">
    <div class="form-group">
      <div class="group-input">
        <ng-select
        [markFirst]="false"
        [items]="raterGroupList"
        bindLabel="name"
        dropdownPosition="auto"
        bindValue="id"
        formControlName="user_type"
        placeholder="Select Rater Group"
        [clearable]="false"
        [closeOnSelect]="true"
        appendTo="body"
        (change)="onRaterGroupSelect($event)"
      ></ng-select>
      </div>
    </div>
  </div>
  <div class="item" *ngIf="i==0">
    <div class="form-group">
      <div class="group-input">
        <input
          type="text"
          formControlName="question_id_from_locator"
          class="form-control"
          [disabled]="true"
          readonly="true"
        />
      </div>
    </div>
  </div>
  <!-- Selected Question is MCQ Type -->
  <ng-container *ngIf="selectedQuesIsMCQ">
    <div class="item" *ngIf="!isRaterGroup">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [markFirst]="false"
            [items]="quesOptions$ | async"
            bindLabel="name"
            dropdownPosition="auto"
            bindLabel="text"
            bindValue="id"
            placeholder="Select Option"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="question_choice_locator"
            appendTo="body"
            (change)="onMcqResponseOptionSelection($event)"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.text"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.text"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
      *ngIf="control?.controls && control.controls['question_choice_locator'].invalid && (control.controls['question_choice_locator'].dirty || control.controls['question_choice_locator'].touched)" 
      class="logic-error">This field is required</span>
      </div>
    </div>

    <div class="item" *ngIf="showConditionSetForMCQ && !isRaterGroup">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [items]="conditionSetForMCQ"
            bindLabel="name"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="id"
            placeholder="Select Question Condition"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="operator"
            appendTo="body"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['operator'].invalid && (control.controls['operator'].dirty || control.controls['operator'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
  </ng-container>

  <!-- Selected Question type is Textbox -->

  <ng-container *ngIf="selectedQuesIsTextbox">
    <div class="item" *ngIf="!isRaterGroup">
      <div class="form-group">
        <div class="group-input">
          <ng-select
            [items]="conditionSetForTextbox"
            bindLabel="name"
            dropdownPosition="auto"
            bindLabel="name"
            bindValue="id"
            placeholder="Select Question Condition"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="operator"
            (change)="onTextboxConditionChange($event)"
            appendTo="body"
          >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHtml]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [innerHtml]="item.name"></span>
      </ng-template>
          </ng-select>
        </div>
        <span 
        *ngIf="control?.controls && control.controls['operator'].invalid && (control.controls['operator'].dirty || control.controls['operator'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
    <div class="item" *ngIf="showOperatorValue && !isRaterGroup">
      <div class="form-group">
        <div class="group-input">
          <input
            type="{{TextBoxConditionInputFieldType}}"
            formControlName="operator_value"
            class="form-control"
          />
        </div>
        <span 
        *ngIf="control?.controls && control.controls['operator_value'].invalid && (control.controls['operator_value'].dirty || control.controls['operator_value'].touched)" 
        class="logic-error">This field is required</span>
      </div>
    </div>
  </ng-container>

  <div
    class="action-col item d-flex align-items-end flex-column-reverse justify-content-center"
  >
    <div
      class="d-inline-block add-row text-success2"
      *ngIf="getArrayControl().length<50 && !isRaterGroup"
      (click)="addRowEvent()"
    >
      <i class="fas fa-plus-square"></i>
    </div>
    <!-- <div
      *ngIf="i==0"
      class="d-inline-block remove-row text-danger2"
      (click)="clear()"
    >
      <i class="fas fa-minus-square"></i>
    </div> -->
    <div
      class="d-inline-block remove-row text-danger2"
      (click)="removeRowEvent()"
    >
      <i class="fas fa-minus-square"></i>
    </div>
  </div>
</div>
