import { Subscription, Observable, concat, of, Subject } from "rxjs";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  NgbModal,
  NgbDatepickerConfig,
  NgbActiveModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { IAlertContent } from "../../models/alert-content.model";
import { RoleDataService } from "../../../app/core/role-data.service";
import { UserDataService } from "../../core/user-data";
import { AssessmentDataService } from "../../core/assessment-data.service";
import { ISurvey } from "../../../app/models/survey.model";
import { ISection } from "../../../app/models/section.model";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { EmailTimerService } from "../../../app/core/email-timer.service";
import { Papa } from "ngx-papaparse";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { AuthenticationService } from "../../../app/core";
import * as moment from "moment";
import { HeaderDataService } from "../../../app/core/header-data.service";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { SURVEY_LAUNCH_METHOD } from "../../constants/survey-launch-method.contant";
import { Project } from "../../models/project.model";
import { PortalDataService } from "../../core/portal-data.service";
import { ConfirmMultiraterModalComponent } from "../../modules/assessments/create-assessment/confirm-multirater-modal/confirm-multirater-modal.component";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
  providers: [NgbActiveModal],
})
export class AlertComponent implements OnInit {
  @Output()
  onCloseModalTriggered: EventEmitter<any> = new EventEmitter();

  @Output()
  ondownloadSample: EventEmitter<any> = new EventEmitter();

  @Output()
  onuploadNormativeData: EventEmitter<any> = new EventEmitter();

  @Output()
  onUploadBlacklistedData: EventEmitter<any> = new EventEmitter();

  @Input("content")
  content: IAlertContent;

  @Input()
  surveySettings: boolean = false;
  @ViewChild("companyModal", { static: false }) companyModal: NgbModalRef;
  public scopeOfSurvey: number;
  public surveyTitle = "";
  public columnName = "";
  public copyTemplateTitle = "";
  public showDeleteRoleButton: boolean = false;
  public showDeleteUserButton: boolean = false;
  public showDeleteSection: boolean = false;
  public showDeactivateSurveyButton: boolean = false;
  public showDeleteSUserButton: boolean = false;
  public showDeleteSurveyButton: boolean = false;
  public showDeleteColumnButton: boolean = false;
  public showDeleteCompanyButton: boolean = false;
  public showDeleteNormButton: boolean = false;
  public showUpdateexpirationButton: boolean = false;
  public showCreateSurvey: boolean = false;
  public showCreateColumn: boolean = false;
  public showShareSurvey: boolean = false;
  public showCSVUploader: boolean = false;
  public showBlacklistedEmailCSVUploader: boolean = false;
  public showResetSheet: boolean = false;
  public showLaunchSurveyButton: boolean = false;
  public showSendEmail: boolean = false;
  public showSaveEmail: boolean = false;
  public showScheduleEmail: boolean = false;
  public sendScheduleMultipleEmail: boolean = false;
  public showReloadRecords: boolean = false;
  public showDeleteRecords: boolean = false;
  public skipAnswer: boolean = false;
  public showDeleteRaterButton: boolean = false;
  public showDeleteGroups: boolean = false;
  public showLaunchLater: boolean = false;
  public showEditCompany: boolean = false;
  public showCreateCompany: boolean = false;

  public showCopySurveyTemplate: boolean = false;
  public showSaveAsTemplate: boolean = false;
  public showAddNewSectionButton: boolean = false;
  public showUpdateSectionButton: boolean = false;

  public showResendEmailButton: boolean = false;
  public showUploadNDButton: boolean;
  public showScaling: boolean = false;
  public display_project_field = false;
  public surveyType:any;
  public projectType: number = 0;
  /**
   * user approves to policy
   */
  public approvePolicyUpdate: boolean = false;
  public launchSurveyInternally: boolean = false;
  public launchSurveyInternallyObj: any;
  isCopy = false;
  customToastText = "";
  sharingUrl = "";
  model: any;
  schedule_date_model: any;
  sectionForm: any;
  SurveyName: string;
  uploadedFile: any = "";
  showDeleteBackup: boolean;
  files: FileList;
  confirm_box: any;
  showPolicy = false;
  showScheduleSurvey = false;
  isShowPolicy = false;
  surveyData: any;

  @ViewChild("embedCodeCopyPopup", { static: false }) embedCodeCopyPopup: NgbModalRef;
  @ViewChild("addCompanyModal", { static: false }) addCompanyModal: ElementRef;
  @ViewChild("scheduleUpdateConfirmation", { static: false }) scheduleUpdateConfirmation: ElementRef;

  editPostLaunchAttribute: boolean = false;
  embedCode: any;
  showScheduleOptions: boolean = false;
  mytime: any;
  scheduleTimeVal: any;
  companyAlertRef: any;
  showCreateCompanyViaCopySurvey: boolean = false;
  showConfirmButton: boolean;
  private activeModal: NgbActiveModal;
  companyTitle = "";
  @Input() active: any;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastService: ToastrService,
    private roleData: RoleDataService,
    private userData: UserDataService,
    private assessmentData: AssessmentDataService,
    private config: NgbDatepickerConfig,
    private papaParse: Papa,
    private router: Router,
    private emailTimerService: EmailTimerService,
    private authenticationService: AuthenticationService,
    private userDataService: UserDataService,
    private headerDataService: HeaderDataService,
    private portalDataService: PortalDataService,
    activeModal: NgbActiveModal
  ) {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    // config.minDate = {
    //   year: tomorrow.getFullYear(),
    //   month: tomorrow.getMonth() + 1,
    //   day: tomorrow.getDate(),
    // };
    // config.maxDate = {
    //   year: tomorrow.getFullYear() + 20,
    //   month: 12,
    //   day: 31,
    // };

    config.outsideDays = "hidden";
    this.activeModal = activeModal;
  }

  modalValueChanged(event: any) {
    // {year: 2019, month: 12, day: 26}
    setTimeout(() => {
      let text_box = document.getElementById("date_picker_format");
      if (text_box) {
        text_box["value"] = `${event.month < 9 ? "0" + event.month : event.month
          }-${event.day < 9 ? "0" + event.day : event.day}-${event.year}`;
      }
    });
  }

  scheduleDateChanged(event: any) {
    setTimeout(() => {
      let text_box = document.getElementById("schedule_date");
      if (text_box) {
        text_box["value"] = `${event.month < 9 ? "0" + event.month : event.month
          }-${event.day < 9 ? "0" + event.day : event.day}-${event.year}`;
      }
    });
  }

  currentTimeObject: any;
  scheduleTimeChanged(event: any) {
    if (event) {
      this.mytime = event;
      this.currentTimeObject = event;
      this.scheduleTimeVal = this.formatTime(event);
    }
  }

  updateMyTime() {
    if (!this.mytime && this.currentTimeObject) {
      this.mytime = this.currentTimeObject;
    }
  }

  isScheduleLater: boolean = false;
  scheduleNow($event: any) {
    if ($event.target.value) {
      this.isScheduleLater = false;
    } else {
      this.isScheduleLater = true;
    }
  }

  scheduleLater($event: any) {
    if ($event.target.value) {
      this.isScheduleLater = true;
      this.scheduleDateChanged(this.schedule_date_model);
    } else {
      this.isScheduleLater = false;
    }
  }
  requiredErrMsg = "This field is required.";

  ifNoValue(val: any) {
    if (!val || !val.trim()) {
      return true;
    } else {
      return false;
    }
  }

  checkTypedWord(type: string) {
    let word = type ? "DEACTIVATE" : "DELETE";
    if (this.confirm_box && this.confirm_box !== word) {
      return false;
    }
    return true;
  }
  ifMoreValue(control: any) {
    return this.getSectionForm && control.value && control.value.length > 20
      ? true
      : false;
  }
  myExpireTime: any;
  currentExpireTimeObject: any;
  scheduleExpireTimeVal: any;

  updateMyExpireTime() {
    if (!this.myExpireTime && this.currentExpireTimeObject) {
      this.myExpireTime = this.currentExpireTimeObject;
    }
  }
  ngOnInit() {
    this.timezoneUpdate();
    if (this.content.positiveAction == "ChooseResponseOption") {
      this.selectedOptionType = this.content.data.options[0].text;
    }
    switch (this.content.positiveAction) {
      case "deleteRole":
        this.showDeleteRoleButton = true;
        break;
      case "deleteUser":
        this.showDeleteUserButton = true;
        break;
      case "deleteStandardUser":
        this.showDeleteSUserButton = true;
        break;
      case "deleteSurvey":
        this.showDeleteSurveyButton = true;
        break;
      case "deleteCompany":
        this.showDeleteCompanyButton = true;
        break;
      case "selectConfirm":
        this.showConfirmButton = true;
        // this.modalService.dismissAll('selected');
        break;
      case "deleteColumn":
        this.showDeleteColumnButton = true;
        break;
      case "deleteSurveyTemplate":
        this.showDeleteSurveyButton = true;
        break;
      case "deleteNormTemplate":
        this.showDeleteNormButton = true;
        break;
      case "updateSurvey":
        this.projectType = this.content.data.project_type;
        this.getMinDate();
        this.showUpdateexpirationButton = true;
        break;
      case "sendEmail":
        this.showSendEmail = true;
        break;
      case "saveEmail":
        this.showSaveEmail = true;
        break;
      case "sendScheduleEmail":
        this.showScheduleEmail = true;
        break;

      case "sendScheduleMultipleEmail":
        this.sendScheduleMultipleEmail = true;
        break;
      case "launchSurvey":
        this.showLaunchSurveyButton = true;
        break;
      case "deactivateSurvey":
        this.showDeactivateSurveyButton = true;
        break;
      case "deleteSection":
        this.showDeleteSection = true;
        break;
      case "createSurvey":
        this.showCreateSurvey = true;
        if (this.content.data.hasOwnProperty("id")) {
          this.isCopy = true;
          let surveysData = this.content.data.surveyData;
          this.display_project_field = surveysData.display_project;
          this.assessmentData
            .getSurvey(this.content.data.id)
            .subscribe((survey: any) => {
              this.surveyTitle = survey.project_name + " copy";
              this.createCompanyForm(survey);
              this.surveyData = survey;
              this.companyName = {
                id: survey.company,
                name: survey.company_name,
              };
              this.initialCompanyList = [
                {
                  id: survey.company,
                  name: survey.company_name,
                },
              ];
            });
        }
        break;

      case "editPostLaunchAttribute":
        this.editPostLaunchAttribute = true;
        if (this.content.data.hasOwnProperty("id")) {
          this.isCopy = true;
          let surveysData = this.content.data.surveyData;
          this.display_project_field = surveysData.display_project;
          this.assessmentData
            .getSurvey(this.content.data.id)
            .subscribe((survey: any) => {
              this.createCompanyForm(survey);
              this.surveyData = survey;
              this.companyName = {
                id: survey.company,
                name: survey.company_name,
              };
              this.initialCompanyList = [
                {
                  id: survey.company,
                  name: survey.company_name,
                },
              ];
              setTimeout(() => {
                this.openCompanyInfo();
              });
            });
        }
        break;

      case "createColumn":
        this.showCreateColumn = true;
        this.surveyType = this.content.data.surveyType;
        this.columnName = this.content.data.col
          ? this.content.data.col.name
          : "";
        break;
      case "copySurveyTemplate":
        this.showCopySurveyTemplate = true;
        if (this.content.data.hasOwnProperty("id")) {
          this.assessmentData
            .getSurvey(this.content.data.id)
            .subscribe((survey: any) => {
              this.copyTemplateTitle =
                (survey.template_name ? survey.template_name : "") + " copy";
            });
        }
        break;
      case "shareSurvey":
        this.showShareSurvey = true;
        break;
      case "uploadCsv":
        this.showCSVUploader = true;
        break;
      case "uploadBlacklistedCsv":
        this.showBlacklistedEmailCSVUploader = true;
        break;
      case "uploadNormativeData":
        this.showUploadNDButton = true;
        break;
      case "resetSheet":
        this.showResetSheet = true;
        break;
      case "reloadRecords":
        this.showReloadRecords = true;
        break;
      case "deleteRecords":
        this.showDeleteRecords = true;
        break;
      case "skipAnswer":
        this.skipAnswer = true;
        break;
      case "deleteGroups":
        this.showDeleteGroups = true;
        break;
      case "createCompany":
        this.showCreateCompany = true;
        this.companyName = this.content.data["companyName"];
        break;
      case "editCompany":
        this.showEditCompany = true;
        this.companyObj = this.content.data["companyObj"];
        this.companyTitle = this.content.data["companyObj"].name;
        break;
      case "createCompanyViaCopySurvey":
        this.showCreateCompanyViaCopySurvey = true;
        this.companyName = this.content.data["companyName"];
        break;

      case "saveAsTemplate":
        this.showSaveAsTemplate = true;
        if (this.content.data.hasOwnProperty("SurveyName")) {
          this.templateName = this.content.data["SurveyName"];
        }
        break;
      case "addNewSection":
        this.showAddNewSectionButton = true;
        this.initSectionParamsForm();
        break;
      case "updateSection":
        this.showUpdateSectionButton = true;
        this.initSectionParamsForm();
        break;
      case "deleteRater":
        this.showDeleteRaterButton = true;
        break;

      case "resendEmail":
        this.showResendEmailButton = true;
        break;
      case "scaling":
        this.showScaling = true;
        break;
      case "confirmBackUpAndDelete":
        this.showDeleteBackup = true;
        break;
      case "ChooseResponseOption":
        this.checkResponse = true;
        break;
      case "approvePolicyUpdate":
        this.approvePolicyUpdate = true;
        break;
      case "forPolicy":
        this.showPolicy = true;
        break;
      case "forScheduleNowOrLater":
        this.getScheduleNowOrLaterMinDate(true);
        break;

      case "forScheduleDateTimeUpdate":
        this.showLaunchLater = true;
        this.isScheduleLater = true;
        this.getScheduleLaterMinDate(false);
        break;
      default:
        break;
    }

    if (this.showShareSurvey) {
      this.sharingUrl = `${environment.PLATFORM_BASE_URL}survey/${this.content.data["survey"].identifier}/resp`;
      this.scopeOfSurvey = this.content.data["survey_scope"];

      this.embedCode = `
      <div id="av-embed-${this.content.data["survey"].identifier}" data-embedtype="1" style="height: 100vh;overflow:auto"></div>
      <script id="avscript-embed-${this.content.data["survey"].identifier}"  data-embedtype="1"    src="${environment.PLATFORM_BASE_URL}snippet.js" async ></script>
      `;
    }

    if (this.showUpdateexpirationButton) {
      let _expiry = this.content.data["survey_expiry_date"];
      if (_expiry) {
        let date1;
        if (!this.surveySettings) {
          date1 = moment(this.content.data["survey_expiry_date"]).parseZone();
        } else {
          date1 = this.content.data["survey_expiry_date"].parseZone();
        }
        if (!this.surveySettings) {
          this.model = {
            year: +date1.format("YYYY"),
            month: +date1.format("M"),
            day: +date1.format("D"),
          };
        } else {
          this.model = {
            year: +date1.format("YYYY"),
            month: +date1.format("M") - 1,
            day: +date1.format("D"),
          };
        }
        this.modalValueChanged(this.model);

        this.scheduleExpireTimeVal = moment(_expiry)
          .parseZone()
          .format("hh:mm A");
        this.myExpireTime = {
          hour: +moment(_expiry).parseZone().format("HH"),
          minute: +moment(_expiry).parseZone().format("mm"),
          second: 0,
        };
      }
    }
  }

  minDateSchedule: any = {
    year: 1970,
    month: 1,
    day: 1,
  };

  getScheduleNowOrLaterMinDate(showScheduleOptions: boolean) {
    this.showScheduleOptions = showScheduleOptions;
    this.showScheduleSurvey = true;

    let timezoneDataApi:Observable<any>;
    let id = this.userDataService.userInfo
      ? this.userDataService.userInfo.id
      : this.headerDataService.userId
        ? this.headerDataService.userId
        : null;
    if (id) {
      timezoneDataApi = this.userDataService.getCaTimezoneData(id);
    } else {
      timezoneDataApi = this.userDataService.me();
    }

    timezoneDataApi.subscribe({
      next:(data)=>{
        let userTimezoneTime = data ? data["user_timezone_current_time"] : null;
        let date = moment.parseZone(userTimezoneTime); //todays date
        //here
        let minTime = moment(date).add(0, "hours");
        this.schedule_date_model = {
          year: +minTime.format("YYYY"),
          month: +minTime.format("M"),
          day: +minTime.format("D"),
        };
    
        this.mytime = {
          hour: +minTime.format("HH"),
          minute: +minTime.format("mm"),
          second: 0,
        };
        this.minDateSchedule = {
          year: +date.format("YYYY"),
          month: +date.format("M"),
          day: +date.format("D"),
        };
        this.scheduleDateChanged(this.schedule_date_model);
        this.scheduleTimeChanged(this.mytime);
      }
    })
   
  }

  createColumn() {
    this.assessmentData.columnAdded$.next({
      name: this.columnName,
      origCol: this.content.data.col ? this.content.data.col : null,
      isDelete: this.content.data.isDelete ? true : null,
      idx: this.content.data.idx ? this.content.data.idx : 0,
      grpIndex: this.content.data.grpIndex,
    });
  }

  isUniqueColumn(columnName: any) {
    if (!columnName) {
      return true;
    }
    let name = columnName.toLowerCase();
    if (this.content.data.columnObj && this.content.data.columnObj[name]) {
      return false;
    }
    return true;
  }

  isRestritedColumn(columnName: any) {
    if (!columnName) {
      return false;
    }
    let _columnName = columnName.toLowerCase();
    if (
      _columnName == "email" ||
      _columnName == "first_name" ||
      _columnName == "last_name" ||
      _columnName == "role" ||
      _columnName == "id" ||
      _columnName == "status"
    ) {
      return true;
    }
    return false;
  }

  getScheduleLaterMinDate(showScheduleOptions: boolean) {
    this.showScheduleOptions = showScheduleOptions;
    this.showScheduleSurvey = true;
    let userTimezoneTime = this.userTimezoneData
      ? this.userTimezoneData["user_timezone_current_time"]
      : null;
    let date = moment.parseZone(userTimezoneTime); //todays date
    this.minDateSchedule = {
      year: +date.format("YYYY"),
      month: +date.format("M"),
      day: +date.format("D"),
    };

    let survey = this.content.data.survey;
    let launchDate = survey.launch_at.split("T")[0].split("-");
    let time = survey.launch_at.split("T")[1].split(":");

    this.schedule_date_model = {
      year: +launchDate[0],
      month: +launchDate[1],
      day: +launchDate[2],
    };

    this.mytime = {
      hour: +time[0],
      minute: +time[1],
      second: 0,
    };

    this.scheduleDateChanged(this.schedule_date_model);
    this.scheduleTimeChanged(this.mytime);
  }

  scheduleExpireTimeChanged(event: any) {
    if (event) {
      this.currentExpireTimeObject = event;
      this.scheduleExpireTimeVal = this.formatTime(event);
    }
  }

  formatTime(time: any) {
    let hh = time.hour;
    let m = time.minute;
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h == 0) {
      h = 12;
    }
    m = m < 10 ? "0" + m : m;

    // if you want 2 digit hours:
    h = h < 10 ? "0" + h : h;

    var pattern = new RegExp("0?" + hh + ":" + m);

    var replacement = h + ":" + m;
    /* if you want to add seconds
    replacement += ":"+s;  */
    replacement += " " + dd;

    return replacement;
  }
  userTimezoneData: any = null;
  timezoneNote = "";
  timezoneUpdate() {
    this.subscription.add(
      this.userDataService.userTimezoneData$.subscribe((data: any) => {
        this.userTimezoneData = data;
        if (data && data["user_timezone_display"]) {
          this.timezoneNote = `Please note that the current timezone is set to ${data["user_timezone_display"].split(":")[0]
            }.`;
        }
      })
    );
  }
  minDate: any = {
    year: 1970,
    month: 1,
    day: 1,
  };

  getMinDate() {
    let userTimezoneTime = this.userTimezoneData
      ? this.userTimezoneData["user_timezone_current_time"]
      : null;
    let date = moment.parseZone(userTimezoneTime); //todays date
    let minDate = moment(date).add(1, "days");
    this.minDate = {
      year: +minDate.format("YYYY"),
      month: +minDate.format("M"),
      day: +minDate.format("D"),
    };

    let _expiry = this.content.data["survey_expiry_date"];
    if (!_expiry) {
      let modDate = moment(date).add(21, "days");
      this.model = {
        year: +modDate.format("YYYY"),
        month: +modDate.format("M"),
        day: +modDate.format("D"),
      };
      this.modalValueChanged(this.model);

      this.scheduleExpireTimeVal = "11:59 PM";
      this.myExpireTime = {
        hour: 23,
        minute: 59,
        second: 0,
      };
    }
  }
  updateUserPolicyStatus() {
    let id = this.content.data.id;
    this.assessmentData.setLoader({ bool: true });
    this.userData
      .updateUserPolicy(id, this.isShowPolicy)
      .subscribe((data: any) => {
        this.assessmentData.setLoader({ bool: false });
      });
  }

  scheduleSurvey() { }
  subscription = new Subscription();
  /**
   * system tokens removed
   */
  private removeTokens() {
    this.subscription.add(
      this.authenticationService.logout().subscribe((data) => { })
    );
  }

  public selectedOptionType: any;
  public checkResponse: boolean = false;
  public confirm(event: any, data: any): void {
    if (event) {
      event.preventDefault();
    }
    if (this.showDeactivateSurveyButton) {
      this.deactivateSurvey();
    } else if (this.showLaunchSurveyButton) {
      // this.launchSurvey();
      this.modalService.dismissAll("launched");
    } else if (this.showResetSheet) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "doResetSheet",
      });
    } else if (this.showReloadRecords) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "reloadRecords",
      });
    } else if (this.showDeleteRecords) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "deleteRecords",
      });
    } else if (this.showDeleteSection) {
      this.deleteSection();
      this.modalService.dismissAll("sectionDeleted");
    } else if (this.showDeleteRaterButton) {
      this.assessmentData
        .deleteContact(
          this.content.data.survey_email_id,
          this.content.data.contact_id
        )
        .toPromise()
        .then((response: any) => {
          if (response !== undefined) {
            this.onCloseModalTriggered.emit({
              closeEmitter: "deleteRaterConfirmed",
            });
          }
        });
    } else if (this.showScaling) {
      this.modalService.dismissAll("confirmScaling");
    } else if (this.showDeleteBackup) {
      this.modalService.dismissAll(
        this.content.data.type ? "deactivate" : "deleteBackup"
      );
    } else if (this.checkResponse) {
      this.modalService.dismissAll(this.selectedOptionType);
    } else if (this.showPolicy) {
      //update backend about acceptance of policy
      this.updateUserPolicyStatus();
      this.modalService.dismissAll(this.isShowPolicy);
    } else if (this.showScheduleSurvey && !this.showLaunchLater) {
      let obj = {};
      if (this.isScheduleLater) {
        let scheduleLaterDateTime =
          this.schedule_date_model.year +
          "-" +
          this.schedule_date_model.month +
          "-" +
          this.schedule_date_model.day +
          "T" +
          this.mytime.hour +
          ":" +
          this.mytime.minute +
          ":00";
        obj["schedule"] = "schedule_later";
        obj["launch_at"] = scheduleLaterDateTime;
        obj["status"] = "launched";
      } else {
        obj["schedule"] = "schedule_now";
        obj["status"] = "scheduled";
      }
      if(data.survey.project_type === 2){
        this.modalService.open(this.isLaunchInternally, {
          centered: true,
          keyboard: false,
          backdrop: "static",
          size: "sm",
          windowClass: "modal-holder",
        });
        this.launchSurveyInternallyObj = obj;
      }
      else{
        this.launchSurvey(obj);
      }
    } else if (this.showLaunchLater) {
      let obj = {};
      if (this.isScheduleLater) {
        let scheduleLaterDateTime =
          this.schedule_date_model.year +
          "-" +
          this.schedule_date_model.month +
          "-" +
          this.schedule_date_model.day +
          "T" +
          this.mytime.hour +
          ":" +
          this.mytime.minute +
          ":00";
        obj["schedule"] = "schedule_later";
        obj["launch_at"] = scheduleLaterDateTime;
        obj["status"] = "launched";
      }
      this.scheduleLaunchSurvey(obj);
    } else if (this.content.positiveAction == "deleteResponseUser") {
      this.modalService.dismissAll("deleteResponseUser");
    } else if (this.content.positiveAction == "editResponseUser") {
      this.modalService.dismissAll("editResponseUser");
    } else if (this.showConfirmButton) {
      this.cancelClicked.emit(this.content.data);
    } else if (this.content.positiveAction == "approvePolicyUpdate") {
      //policy update approved by the user
      this.modalService.dismissAll("approved");
    } else if (this.content.positiveAction == "approvePolicyUpdate") {
      //policy update approved by the user
      this.modalService.dismissAll("approved");
    } else if (this.content.positiveAction == "editCompany") {
      this.content.data["companyObj"]["name"] = this.companyTitle;
      this.updateClicked.emit(this.content.data["companyObj"]);
      this.modalService.dismissAll();
    }
  }

  CancelSchedule() {

    this.companyAlertRef = this.modalService.open(this.scheduleUpdateConfirmation, {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "lg",
      windowClass: "modal-holder",
    });

    this.companyAlertRef.result.then(
      (result: any) => {
        if (result) {
          let surveyId = this.content.data.survey.identifier;
          let survey = this.content.data.survey;
          this.assessmentData.setLoader({ bool: true });
          this.assessmentData.cancelScheduledSurvey(surveyId, survey).subscribe(data => {
            this.modalService.dismissAll();
            this.assessmentData.setLoader({ bool: false });
          })
        } else {
          this.modalService.dismissAll();
        }

      },
      (reason: string) => {
        //change here
      }
    );




  }

  @Output() cancelClicked = new EventEmitter();
  @Output() updateClicked = new EventEmitter();
  public cancel(): void {
    if (this.content.positiveAction === "createSurvey") {
      this.router.navigate(["/campaign-dashboard"]);
      this.modalService.dismissAll();
    } else if (this.surveySettings && this.showUpdateexpirationButton) {
      this.cancelClicked.emit(false);
    } else if (this.showResetSheet) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "resetSheet",
      });
    } else if (this.showSendEmail) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "emailCancelled",
      });
    } else if (this.showSaveEmail) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "emailCancelled",
      });
    } else if (this.showScheduleEmail) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "emailCancelled",
      });
    } else if (this.sendScheduleMultipleEmail) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "emailCancelled",
      });
    } else if (this.showReloadRecords) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "reloadRecords",
      });
    } else if (this.showDeleteRecords) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "deleteRecordsCancelled",
      });
    } else if (this.showDeleteRaterButton) {
      this.onCloseModalTriggered.emit({
        closeEmitter: "deleteRaterCancelled",
      });
    } else if (this.showPolicy) {
      //policy check removed
      if (this.isShowPolicy) {
        this.isShowPolicy = false;
      }
      //tokens removed
      if (!localStorage.getItem("is_superadmin")) this.removeTokens();
      this.modalService.dismissAll();
    } else if (this.showScheduleSurvey) {
      this.modalService.dismissAll(false);
    } else if (
      this.showCreateColumn ||
      this.showDeleteColumnButton ||
      this.showCreateCompanyViaCopySurvey
    ) {
      this.cancelClicked.emit(false);
    } else if (this.showConfirmButton) {
      this.cancelClicked.emit(false);
    } else {
      this.modalService.dismissAll();
    }
  }

  resendEmailToContact(): void {
    let contact = this.content.data.contact;
    let surveyEmailID = this.content.data.surveyEmailID;
    this.assessmentData
      .resendEmailToContact(surveyEmailID, contact.id, contact)
      .toPromise()
      .then((response: any) => {
        this.onCloseModalTriggered.emit({
          closeEmitter: "emailResentDone",
        });
      })
      .catch((err) => { });
  }

  templateName: any;

  companyName: any;
  companyObj: any = {};

  checkIfName(type: any) {
    if (type && type.trim()) {
      return false;
    } else {
      return true;
    }
  }

  createCompany(type: any) {
    this.onCloseModalTriggered.emit({ name: type.trim() });
  }
  editCompany(type: any) {
    this.onCloseModalTriggered.emit(type);
  }

  // public launchSurvey(obj: any): void {
  //   const survey = this.content.data.survey;
  //   this.assessmentData.getSurvey(survey.identifier).subscribe((surveyResp) => {
  //     this.assessmentData.launchSurvey(survey.identifier, survey).subscribe(
  //       (response) => {
  //         this.modalService.dismissAll(obj);
  //       },
  //       (err) => {
  //         this.toastService.warning("Something went wrong.");
  //         this.modalService.dismissAll(null);
  //       }
  //     );
  //   });
  // }

  public launchSurvey(obj: any): void {
    let survey = this.content.data.survey;
    let result = { identifier: survey.identifier };
    if (obj["schedule"] == "schedule_later") {
      result["status"] = "scheduled";
      result["launch_at"] = obj["launch_at"];
    } else if (obj["schedule"] == "schedule_now") {
      result["status"] = "launched";
      result["launch_at"] = null;
    }

    obj["result"] = result;
    this.modalService.dismissAll(obj);
    // this.assessmentData.launchSurvey(survey.identifier, result).subscribe(
    //   (response) => {
    //     this.modalService.dismissAll(obj);
    //   },
    //   (err) => {
    //     this.toastService.warning(err.error.error_msg);
    //     this.modalService.dismissAll(null);
    //   }
    // );
  }

  public scheduleLaunchSurvey(obj: any): void {
    let survey = this.content.data.survey;
    let result = { identifier: survey.identifier };
    result["status"] = "scheduled";
    result["launch_at"] = obj["launch_at"];
    this.assessmentData
      .scheduleLaunchUpdate(survey.project_identifier, result)
      .subscribe(
        (response) => {
          this.modalService.dismissAll(obj);
          this.toastService.success("Survey schedule updated.");
        },
        (err) => {
          this.toastService.warning(err.error.error_msg);
          this.modalService.dismissAll(null);
        }
      );
  }

  public deactivateSurvey(): void {
    const survey = this.content.data.survey;
    
    if (survey.project_type === 2) {
      this.openDeactivationModal();
    } else {
      this.processDeactivation(survey);
    }
  }
  
  public updateDeactivation(is_all: boolean): void {
    const survey = this.content.data.survey;
    survey.is_survey = is_all;
    this.processDeactivation(survey);
  }
  
  private openDeactivationModal(): void {
    this.modalService.open(this.isDeativateInternally, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      size: "sm",
      windowClass: "modal-holder",
    });
  }
  
  private processDeactivation(survey: any): void {
    this.assessmentData.deactivateSurvey(survey.identifier, survey).subscribe(() => {
      this.modalService.dismissAll("deactivated");
      this.toastService.success("Survey has been successfully deactivated.");
    });
  }

  public deleteRole(): void {
    // todo
    this.roleData
      .deleteRole(this.content.data["roleId"])
      .subscribe((response) => {
        this.modalService.dismissAll();
        this.toastService.success("Role has been deleted successfully.");
      });
  }

  sending_email = false;

  public sendEmail(): void {
    if (this.content.data.project) {
      this.sendEmailPortal();
      return;
    }
    if (!this.sending_email) {
      this.sending_email = true;
      if (this.content.data["emailData"].hasOwnProperty("broadcast")) {
        this.assessmentData
          .sendReminderToAll(
            this.content.data["surveyId"],
            this.content.data["emailData"]
          )
          .subscribe(
            (response: any) => {
              delete this.content.data["emailData"].broadcast;
              this.sending_email = false;
              if (response.task_id !== undefined) {
                if (!this.content.data["isTest"]) {
                  if (this.content.data["emailData"]["self_obj"]) {
                    localStorage.setItem(
                      "ratee",
                      JSON.stringify(this.content.data["emailData"]["self_obj"])
                    );
                  }
                  localStorage.setItem("task_id", response.task_id);
                  this.emailTimerService.setTimerState({
                    val: Math.random(),
                    content_data: this.content.data,
                  });
                  this.onCloseModalTriggered.emit({
                    closeEmitter: "emailSent",
                  });
                } else {
                  this.toastService.success("Test email sent successfully.");
                  this.onCloseModalTriggered.emit({
                    closeEmitter: "testEmailSent",
                  });
                }
              }
            },
            (err: any) => {
              this.sending_email = false;
              this.toastService.warning(err.error.error_msg);
            }
          );
      } else {
        this.assessmentData
          .publishSurveyByEmail(
            this.content.data["surveyId"],
            this.content.data["emailData"]
          )
          .subscribe(
            (response: any) => {
              this.sending_email = false;
              if (response.task_id !== undefined) {
                if (!this.content.data["isTest"]) {
                  if (this.content.data["emailData"]["self_obj"]) {
                    localStorage.setItem(
                      "ratee",
                      JSON.stringify(this.content.data["emailData"]["self_obj"])
                    );
                  }
                  localStorage.setItem("task_id", response.task_id);
                  this.onCloseModalTriggered.emit({
                    closeEmitter: "emailSent",
                  });
                  this.emailTimerService.setTimerState({
                    val: Math.random(),
                    content_data: this.content.data,
                  });
                } else {
                  this.toastService.success("Test email sent successfully.");
                  this.onCloseModalTriggered.emit({
                    closeEmitter: "testEmailSent",
                  });
                }
              }
            },
            (err: any) => {
              this.sending_email = false;
              this.toastService.warning(err.error.error_msg);
            }
          );
      }
    }
  }

  public sendEmailPortal(): void {
    let project: Project = this.content.data.project;
    let body = { ...this.content.data.emailData };
    // let participants = body.recipients.map(participant => {
    //   return {
    //     to_email: participant.email,
    //     ...participant
    //   }

    // })
    // body.participant = participants;

    // delete body.recipients;


    if (!this.sending_email) {
      this.sending_email = true;
      if (body.hasOwnProperty("broadcast")) {
        this.assessmentData
          .sendProjectReminderToAll(
            project.identifier,
            body
          )
          .subscribe(
            (response: any) => {
              delete body.broadcast;
              this.sending_email = false;
              if (response && response.task_id !== undefined) {
                if (!this.content.data["isTest"]) {
                  if (body["self_obj"]) {
                    localStorage.setItem(
                      "ratee",
                      JSON.stringify(body["self_obj"])
                    );
                  }
                  localStorage.setItem("task_id", response.task_id);
                  this.emailTimerService.setTimerState({
                    val: Math.random(),
                    content_data: this.content.data,
                  });
                  this.onCloseModalTriggered.emit({
                    closeEmitter: "emailSent",
                  });
                  this.toastService.success("Email sent successfully.");
                } else {
                  this.toastService.success("Test email sent successfully.");
                  this.onCloseModalTriggered.emit({
                    closeEmitter: "testEmailSent",
                  });
                }
              }
              this.onCloseModalTriggered.emit({
                closeEmitter: "emailSent",
              });
              this.onCloseModalTriggered.emit({
                closeEmitter: "testEmailSent",
              });
            },
            (err: any) => {
              this.sending_email = false;
              this.toastService.warning(err.error.error_msg);
            }
          );
      } else {
        this.assessmentData
          .publishProjectByEmail(
            project.identifier,
            body
          )
          .subscribe(
            (response: any) => {
              this.toastService.success("Email sent successfully.");
              this.onCloseModalTriggered.emit({
                closeEmitter: "testEmailSent",
              });
            },
            (err: any) => {
              this.sending_email = false;
              this.toastService.warning(err.error.error_msg);
            }
          );
      }
    }
  }

  public saveEmail(): void {
    if (!this.sending_email) {
      this.sending_email = true;
      let project = this.content.data.project;

      let apiToCall;
      if (project) {
        apiToCall = this.portalDataService.sendEmailAll(project.identifier, this.content.data["emailData"])

      } else {
        apiToCall = this.assessmentData
        .saveEmail(
          this.content.data["surveyId"],
          this.content.data["emailData"],
          this.content.data["isUpdate"]
        )
      }

      
      apiToCall.subscribe(
          (response: any) => {
            this.sending_email = false;

            this.onCloseModalTriggered.emit({
              closeEmitter: "emailSaved",
            });
            if (
              this.content &&
              this.content.data &&
              this.content.data["isUpdate"]
            ) {
              this.getDateTime(response.launch_at);
            } else {
              // this.toastService.success(
              //   `Email ${
              //     this.content.data["isUpdate"] ? "updated" : "saved"
              //   } successfully`
              // );
              this.toastService.success(
                `Your survey launch email(s) have been successfully scheduled.`
              );
            }
          },
          (err: any) => {
            this.sending_email = false;
            this.toastService.warning(err.error.error_msg);
          }
        );
    }
  }

  getDateTime(launch_at: any) {
    let date = launch_at.split("T")[0].split("-");
    let time = launch_at.split("T")[1].split(":");
    let dateRes = date[1] + "-" + date[2] + "-" + date[0];
    let timeRes = this.formatTime({ hour: time[0], minute: time[1] });
    this.toastService.success(
      `The launch email for ${timeRes} on ${dateRes} has been updated and has replaced the previously scheduled launch email.`
    );
  }

  public sendScheduleReminderToAll(): void {
    if (!this.sending_email) {
      this.sending_email = true;
      let project = this.content.data.project;

      let apiToCall;
      if (project) {
        apiToCall = this.portalDataService.scheduleReminderToAll(project.identifier, this.content.data["emailData"])

      } else {
        apiToCall = this.assessmentData
          .sendScheduleReminderToAll(
            this.content.data["surveyId"],
            this.content.data["emailData"]
          )
      }
      if (this.content.data["emailData"].hasOwnProperty("reminderToAll")) {
        apiToCall
          .subscribe(
            (response: any) => {
              this.sending_email = false;
              this.toastService.success(
                "Your survey reminder email(s) have been successfully scheduled."
              );
              this.onCloseModalTriggered.emit({
                closeEmitter: "emailSent",
              });
            },
            (err: any) => {
              this.sending_email = false;
              this.toastService.warning(err.error.error_msg);
            }
          );
      }
    }
  }

  public sendMultipleSchedule(): void {
    if (!this.sending_email) {
      this.sending_email = true;
      if (this.content.data["emailData"]) {
        this.assessmentData
          .sendMultipleSchedule(
            this.content.data["surveyId"],
            this.content.data["emailData"]
          )
          .subscribe(
            (response: any) => {
              this.sending_email = false;
              this.toastService.success(
                "Your survey email(s) have been successfully scheduled."
              );
              this.onCloseModalTriggered.emit({
                closeEmitter: "emailSent",
              });
            },
            (err: any) => {
              this.sending_email = false;
              this.toastService.warning(err.error.error_msg);
            }
          );
      }
    }
  }
  public deleteUser(): void {
    this.userData.deleteUser(this.content.data["userId"]).subscribe(
      (response) => {
        this.modalService.dismissAll();
        this.toastService.success("User has been deleted successfully.");
      },
      (error) => { }
    );
  }

  public deleteStandardUser(): void {
    this.userData.deleteStandardUser(this.content.data["suserId"]).subscribe(
      (response) => {
        this.modalService.dismissAll();
        this.toastService.success("User has been deleted successfully.");
      },
      (error) => { }
    );
  }

  public deleteSurvey(): void {
    let isProject: boolean = this.content.data["isProject"]

    let apiToCall;
    if (isProject) {
      apiToCall = this.assessmentData.deleteProject(this.content.data["project_identifier"]);
    } else {
      apiToCall = this.assessmentData.deleteSurvey(this.content.data["surveyId"]);
    }
    apiToCall.subscribe(
      (response) => {
        this.modalService.dismissAll("deleted");
        this.toastService.success(
          `${isProject ? "Project" : "Survey"} ${this.content.positiveAction == "deleteSurveyTemplate"
            ? "template"
            : ""
          } has been deleted successfully.`
        );
      },
      (error) => { }
    );
  }
  public confirmSelection() {
    // this.modalService.dismissAll("selected");
    this.active.close("selected");
  }

  public deleteNorm(): void {
    this.modalService.dismissAll("deleted");
  }

  public deleteGroups(): void {
    this.onCloseModalTriggered.emit({
      closeEmitter: "deleteSelected",
    });
  }

  @ViewChild("expiryUpdateWarning", { static: false }) expiryUpdateWarning: NgbModalRef;
  @ViewChild("isExpiryUpdateInternally", { static: false }) isExpiryUpdateInternally: NgbModalRef;
  @ViewChild("isDeativateInternally", { static: false }) isDeativateInternally: NgbModalRef;
  @ViewChild("isLaunchInternally", { static: false }) isLaunchInternally: NgbModalRef;

  public updateSurveyExpiration(type: any): void {
    let isProject: boolean = !!this.content.data.project;
    let isExpired: boolean;
    if (isProject) {
      isExpired = this.content.data.project.status.toLowerCase() == "expired"
    } else {
      isExpired = this.content.data["surveyStatus"].toLowerCase() == "expired"
    }
    if (
      !this.surveySettings &&
      isExpired
    ) {
      this.modalService.open(this.expiryUpdateWarning, {
        centered: true,
        keyboard: false,
        backdrop: "static",
        size: "sm",
        windowClass: "modal-holder",
      });
    } else {
      if(this.projectType == 2){
        this.modalService.open(this.isExpiryUpdateInternally, {
          centered: true,
          keyboard: false,
          backdrop: "static",
          size: "sm",
          windowClass: "modal-holder",
        });
      }
      else{
        this.updateSurveyExpiry(false);
      }
    }
  }

  clearExpiryWarningModal(type: any, typeOf: any) {
    if (type) {
      this.updateSurveyExpiry(false);
    } else {
      this.modalService.dismissAll();
    }
    // for closing and redirecting to dashboard on choosing embed modal
    if (typeOf && this.router.url.toString().includes("builder")) {
      this.router.navigate(["/"]);
    }
  }

  updateSurveyExpiry(expiryAll: boolean) {
    let isProject: boolean = !!this.content.data?.project;

    let date = this.model.year + "-" + this.model.month + "-" + this.model.day;
    let time =
      this.myExpireTime.hour.toString() +
      ":" +
      this.myExpireTime.minute.toString() +
      ":00";
    let value = date + "T" + time;
    let apiToCall;
    if (isProject) {
      apiToCall = this.assessmentData.updateProjectExpiry(this.content.data.project.project_identifier, value, expiryAll)
    } else {
      apiToCall = this.assessmentData.updateSurveyExpiration(this.content.data["surveyId"], value)

    }
    if (!this.surveySettings) {
      apiToCall
        .subscribe(
          (response) => {
            this.modalService.dismissAll({
              expiryUpdated: response ? response.value : null,
            });
            this.toastService.success(
              "Expiry date and time changed successfully."
            );
          },
          (error) => {
            this.toastService.error(
              error.error && error.error.error_msg
                ? error.error.error_msg
                : "Something went wrong."
            );
            this.modalService.dismissAll();
          }
        );
    } else {
      this.assessmentData.dateUpdate$.next(value);
      this.cancelClicked.emit(false);
    }
  }

  public copySurveyTemplate(): void {
    if (this.content.data.hasOwnProperty("id")) {
      // &is_tempate=true
      this.assessmentData
        .copySurvey(this.content.data.id, this.copyTemplateTitle)
        .subscribe(
          (surveyCopy) => {
            this.modalService.dismissAll("copied");
            this.toastService.success(
              "Project template has been copied successfully."
            );
          },
          (err: any) => {
            this.modalService.dismissAll();
            this.toastService.warning(
              "Something went wrong! Please try again later."
            );
          }
        );
    }
  }

  is_submitted: boolean = false;
  is_requestsent: boolean = false;
  updatedCompanyAttributeData: any;
  public createSurvey(): void {
    this.is_submitted = true;
    if (this.surveyTitle.trim()) {
      //changes here
      if (this.content.data.hasOwnProperty("id")) {
        this.is_requestsent = true;
        let body = this.updatedCompanyAttributeData
          ? this.updatedCompanyAttributeData
          : this.createCompanyObject(this.surveyData);

        let apiToCall, id;
        let isCopyProject: boolean = this.content.data.copyUsingProject;
        if (isCopyProject) {
          id = this.content.data.surveyData.project_identifier
          apiToCall = this.assessmentData.copyProjectWithAttributeUpdates(id,
            body,
            this.surveyTitle.trim());
        } else {
          id = this.content.data.id
          apiToCall = this.assessmentData.copySurveyWithAttributeUpdates(id,
            body,
            this.surveyTitle.trim());

        }

        let multiraterProject = this.content.data?.surveyData?.project_type == 2;
        let hasProjectName = !!this.companyAttributeForm.getRawValue().project;
        if (multiraterProject && !hasProjectName) {
          this.modalService.open(ConfirmMultiraterModalComponent, {
            centered: true,
            keyboard: false,
            backdrop: "static",
            windowClass: "modal-holder",
          }).result.then(
            (_) => {

            },
            (_) => {
              apiToCall.subscribe(
                (_) => {
                  this.modalService.dismissAll("copied");
                  this.is_requestsent = false;
                  let message: string = isCopyProject ? "Project has been copied successfully." : "Survey has been copied successfully."
                  this.toastService.success(message);
                },
                (err) => {
                  let message: string = isCopyProject ? "Unable to copy Project. Please try again later." : "Unable to copy survey. Please try again later."

                  this.toastService.warning(message);
                  this.is_requestsent = false;
                }
              );
            },
          )
        } else {
          apiToCall.subscribe(
            (_) => {
              this.modalService.dismissAll("copied");
              this.is_requestsent = false;
              let message: string = isCopyProject ? "Project has been copied successfully." : "Survey has been copied successfully."
              this.toastService.success(message);
            },
            (err) => {
              let message: string = isCopyProject ? "Unable to copy Project. Please try again later." : "Unable to copy survey. Please try again later."

              this.toastService.warning(message);
              this.is_requestsent = false;
            }
          );
        }


      } else {
        const survey: any = {
          title: this.surveyTitle,
        };
        this.assessmentData
          .createSurvey(survey)
          .subscribe((survey: any) => {
            this.toastService.success("New survey created successfully.");
            this.modalService.dismissAll();
            this.router
              .navigate([`survey/${survey.identifier}/builder`])
              .then(() => {
                this.assessmentData
                  .createSection({ survey: survey.url, position: 1 })
                  .subscribe((section: ISection) => {
                    this.toastService.success(
                      "New Section added successfully."
                    );
                    // setting survey expiry date to infinite date - 2100-1-1
                    this.assessmentData
                      .getSurveyPreview(survey.identifier)
                      .subscribe((preview) => {
                        const surveySetting = preview.setting;
                        surveySetting.access[2].value = "2100-1-1";

                        this.assessmentData
                          .updateSurveySetting(survey.identifier, surveySetting)
                          .subscribe(() => { });
                      });
                  });
              });
          });
      }
    }
  }

  public deleteSection(): void {
    let sectionUrl: string = this.content.data["sectionUrl"];
    this.assessmentData.deleteSection(sectionUrl).subscribe(
      (response: any) => {
        this.toastService.success("Section deleted successfully.");
      },
      (error: any) => {
        this.toastService.warning(error.error.detail);
      }
    );
  }
  
  launchInternally(is_launch: any){
    this.launchSurveyInternally = is_launch;
    this.content.data.survey.is_survey = this.launchSurveyInternally;
    this.launchSurvey(this.launchSurveyInternallyObj)
  }
  
  public async goToShareByEmail(survey: any): Promise<void> {
    if(survey){
      await this.assessmentData.updateQuestionTags(survey.identifier).toPromise();
    }
    if (
      this.content.data["survey_scope"] == undefined ||
      this.content.data["survey_scope"] == null ||
      this.content.data["survey_scope"] == 1
    ) {
      this.assessmentData.setLoader({ bool: true });
      this.content.data["result"].is_survey = this.content.data.survey.is_survey;
      this.content.data["result"]["launch_method"] = SURVEY_LAUNCH_METHOD.EMAIL
      this.assessmentData
        .launchSurveyUsingProject(survey.project_identifier, this.content.data["result"])
        .toPromise()
        .then((response: any) => {
          if (response !== undefined) {
            this.toastService.success(
              `Project has been ${survey["status"]} successfully.`
            );
            this.assessmentData.updateSurveyScope(survey, 1).subscribe(
              (resp) => {
                this.modalService.dismissAll("updated");
                this.assessmentData.setLoader({ bool: false });
                this.router.navigate([`/survey/${survey.identifier}/report`]);
              },
              (err) => {
                this.toastService.warning(err.error.error_msg);
                this.modalService.dismissAll(null);
                this.assessmentData.setLoader({ bool: false });
              }
            );
          } else {
            this.toastService.warning(
              "Unable to launch survey. Please try later."
            );
          }
        })
        .catch((failure: any) => {
          this.assessmentData.setLoader({ bool: false });
          this.toastService.warning(
            failure.error && failure.error.error_msg
              ? failure.error.error_msg
              : "Unable to launch survey. Please try later."
          );
        });
    } else {
      this.modalService.dismissAll("updated");
      this.router.navigate([`/survey/${survey.identifier}/report`]);
    }
  }

  public async goToShareByPortal(survey: any): Promise<void> {
    if(survey){
      await this.assessmentData.updateQuestionTags(survey.identifier).toPromise();
    }
    if (
      this.content.data["survey_scope"] == undefined ||
      this.content.data["survey_scope"] == null ||
      this.content.data["survey_scope"] == 1
    ) {
      this.assessmentData.setLoader({ bool: true });
      this.content.data["result"].is_survey = this.content.data.survey.is_survey;
      this.content.data["result"]["launch_method"] = SURVEY_LAUNCH_METHOD.PORTAL
      this.assessmentData
        .launchSurveyUsingProject(survey.project_identifier, this.content.data["result"])
        .toPromise()
        .then((response: any) => {
          if (response !== undefined) {
            this.toastService.success(
              `Project has been ${survey["status"]} successfully.`
            );
            this.assessmentData.updateSurveyScope(survey, 1).subscribe(
              (resp) => {
                this.modalService.dismissAll("updated");
                this.assessmentData.setLoader({ bool: false });
                let projectIdentifier: string = this.content.data.survey.project_identifier
                this.router.navigate([`/project/${projectIdentifier}/report`]);
              },
              (err) => {
                this.toastService.warning(err.error.error_msg);
                this.modalService.dismissAll(null);
                this.assessmentData.setLoader({ bool: false });
              }
            );
          } else {
            this.toastService.warning(
              "Unable to launch Project. Please try later."
            );
          }
        })
        .catch((failure: any) => {
          this.assessmentData.setLoader({ bool: false });
          this.toastService.warning(
            failure.error && failure.error.error_msg
              ? failure.error.error_msg
              : "Unable to launch survey. Please try later."
          );
        });
    } else {
      this.modalService.dismissAll("updated");
      this.router.navigate([`/survey/${survey.identifier}/report`]);
    }
  }

  public async copyLink(survey: any): Promise<void> {
    if(survey){
      await this.assessmentData.updateQuestionTags(survey.identifier).toPromise();
    }
    if (
      (this.content.data["survey_scope"] == undefined ||
      this.content.data["survey_scope"] == null ||
      this.content.data["survey_scope"] == 0) && this.content.data['result']

    ) {
      this.content.data["result"]["launch_method"] = SURVEY_LAUNCH_METHOD.WEB
      this.content.data["result"].is_survey = this.content.data.survey.is_survey;
      this.assessmentData
        .launchSurveyUsingProject(survey.project_identifier, this.content.data["result"])
        .toPromise()
        .then((response: any) => {
          if (response !== undefined) {
            this.toastService.success(
              `Survey has been ${survey["status"]} successfully.`
            );
            this.assessmentData.updateSurveyScope(survey, 0).subscribe(
              (resp) => {
                this.modalService.dismissAll("linkCopied");
                this.toastService.success("Link has been copied successfully.");

                // only when we are in the builder page we can move to root page after copying link
                if (this.router.url.toString().includes("builder")) {
                  this.router.navigate(["/"]);
                }
              },
              (err) => {
                this.toastService.warning(err.error.error_msg);
              }
            );
          } else {
            this.toastService.warning(
              "Unable to launch survey. Please try later."
            );
          }
        })
        .catch((failure: any) => {
          this.toastService.warning(
            failure.error && failure.error.error_msg
              ? failure.error.error_msg
              : "Unable to launch survey. Please try later."
          );
        });
    } else {
      this.modalService.dismissAll("linkCopied");
      this.toastService.success("Link has been copied successfully.");

      // only when we are in the builder page we can move to root page after copying link
      if (this.router.url.toString().includes("builder")) {
        this.router.navigate(["/"]);
      }
    }
  }

  public async shareWithSocialMedia(survey: any): Promise<void> {
    if(survey){
      await this.assessmentData.updateQuestionTags(survey.identifier).toPromise();
    }
    if (
      this.content.data["survey_scope"] == undefined ||
      this.content.data["survey_scope"] == null
    ) {
      this.content.data["result"]["launch_method"] = SURVEY_LAUNCH_METHOD.SOCIAL
      this.content.data["result"].is_survey = this.content.data.survey.is_survey;
      this.assessmentData
        .launchSurveyUsingProject(survey.project_identifier, this.content.data["result"])
        .toPromise()
        .then((response: any) => {
          if (response !== undefined) {
            this.toastService.success(
              `Survey has been ${survey["status"]} successfully.`
            );
            this.assessmentData.updateSurveyScope(survey, 0).subscribe(
              (resp) => {
                if (
                  this.router.url.toString() !== "/assessments" &&
                  this.router.url.toString() !== "/campaign-dashboard"
                ) {
                  this.router.navigate(["/"]).then(() => {
                    this.modalService.dismissAll("updated");
                  });
                }
              },
              (err) => {
                this.toastService.warning(err.error.error_msg);
              }
            );
          } else {
            this.toastService.warning(
              "Unable to launch survey. Please try later."
            );
          }
        })
        .catch((failure: any) => {
          this.toastService.warning(
            failure.error && failure.error.error_msg
              ? failure.error.error_msg
              : "Unable to launch survey. Please try later."
          );
        });
    } else {
      if (
        this.router.url.toString() !== "/assessments" &&
        this.router.url.toString() !== "/campaign-dashboard"
      ) {
        this.router.navigate(["/"]).then(() => {
          this.modalService.dismissAll("updated");
        });
      }
    }
  }

  ifEnterSubmit(event: any, val: any) {
    if (event.keyCode === 13 || event.key == "Enter") {
      if (val.trim()) {
        this.createSurvey();
      }
    }
  }

  ifEnterClicked(event: any, val: any) {
    if (event.keyCode === 13 || event.key == "Enter") {
      if (val.trim()) {
        // this.createSurvey();
        //chnage here
      }
    }
  }

  response_building: boolean = false;
  csv_report_data: any;

  nd_upload_err: any;

  onFileChange(event) {
    let files = event.target.files;
    let fileExtension = files[0].name
      .substr(files[0].name.lastIndexOf(".") + 1)
      .toLowerCase();

    if (fileExtension === "csv") {
      if (files[0].size / 1024 <= 2000) {
        this.uploadedFile = files[0].name;
        this.files = files;
      }
    } else {
      this.uploadedFile = null;
      this.nd_upload_err = null;
    }
  }

  removeFile() {
    this.uploadedFile = null;
    this.nd_upload_err = null;
  }

  onClickUpload() {
    if (this.showCSVUploader) {
      this.response_building = true;
      const questionUrl: string = this.content.data["questionUrl"];

      const file: File = this.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv: any = reader.result;
        const parsed = this.papaParse.parse(csv, { header: false });

        let data: string = parsed.data;
        let result = { data: data };
        // parsed.data.forEach((value: any, i: any) => {
        //   if (parsed.data[i][0] && parsed.data[i][0].length && i !== 0) {
        //     result[data].push(parsed.data[i][0]);
        //   }
        // });

        if (!result["data"] || !result["data"].length) {
          this.toastService.error("Please fill complete information.");
          return;
        }

        this.assessmentData.importCsv(result, questionUrl, '').subscribe(
          (response: any) => {
            this.modalService.dismissAll();
            this.response_building = false;
            this.toastService.success("CSV file uploaded successfully.");
          },
          (err) => {
            this.response_building = false;
            if (err && err.error && err.error.error_msg) {
              this.toastService.warning(err.error.error_msg);
            } else {
              this.toastService.warning(
                "Unable to upload data. Please try again later."
              );
            }
          }
        );
      };
    } else if (this.showBlacklistedEmailCSVUploader) {
      this.response_building = true;
      const file: File = this.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv: any = reader.result;
        const parsed = this.papaParse.parse(csv, { header: false });

        let domains: string = parsed.data[0][0] + "";
        domains = domains.toLowerCase();
        let result = {};
        result[domains] = [];
        parsed.data.forEach((value: any, i: any) => {
          if (parsed.data[i][0] && parsed.data[i][0].length && i !== 0) {
            result[domains].push(parsed.data[i][0]);
          }
        });

        if (!result[domains] || !result[domains].length) {
          this.toastService.error("Please fill complete information.");
          return;
        }
        if (this.content.data.userId) {
          this.assessmentData
            .importBlackistedEmailsCsv(result, this.content.data.userId)
            .subscribe(
              (response: any) => {
                this.onUploadBlacklistedData.emit(true);
                this.modalService.dismissAll();
                this.response_building = false;
                this.toastService.success("CSV file uploaded successfully.");
              },
              (err: any) => {
                this.response_building = false;
                if (err && err.error && err.error.error_msg) {
                  this.toastService.warning(err.error.error_msg);
                } else {
                  this.toastService.warning(
                    "Unable to upload data. Please try again later."
                  );
                }
              }
            );
        }
      };
    } else {
      this.response_building = true;
      const surveyID = this.content.data.surveyID;
      delete this.nd_upload_err;
      const file: File = this.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv: any = reader.result;
        const parsed = this.papaParse.parse(csv, { header: false });
        if (
          parsed.data[parsed.data.length - 1].length != parsed.data[0].length
        ) {
          parsed.data.splice(parsed.data.length - 1, 1);
        }
        if (this.content.data.surveyID) {
          this.assessmentData.importCsv(parsed.data, '', surveyID).subscribe(
            (response: any) => {
              this.onuploadNormativeData.emit();
              this.modalService.dismissAll(true);
              this.response_building = false;
              this.toastService.success(
                "Normative data has been uploaded successfully."
              );
            },
            (err: any) => {
              this.response_building = false;
              this.nd_upload_err =
                "The normative data you uploaded is not formatted correctly. Please re-download the template, re-add your data, and attempt to re-upload.";
            }
          );
        }

        if (this.content.data.groupID) {
          this.assessmentData
            .importGroupCsv(parsed.data, this.content.data.groupID)
            .subscribe(
              (response: any) => {
                this.modalService.dismissAll();
                this.response_building = false;
                this.toastService.success(
                  "Normative data has been uploaded successfully."
                );
              },
              (err: any) => {
                this.response_building = false;
                this.nd_upload_err =
                  "The normative data you uploaded is not formatted correctly. Please re-download the template, re-add your data, and attempt to re-upload.";
              }
            );
        }
      };
    }
  }

  public delete(): void {
    if (this.showDeleteRoleButton) {
      this.deleteRole();
    } else if (this.showDeleteUserButton) {
      this.deleteUser();
    } else if (this.showDeleteSUserButton) {
      this.deleteStandardUser();
    } else if (this.showDeleteSurveyButton) {
      this.deleteSurvey();
    } else if (this.showDeleteCompanyButton) {
      this.updateClicked.emit(this.content.data);
    } else if (this.showDeleteNormButton) {
      this.deleteNorm();
    } else if (this.showDeleteGroups) {
      this.deleteGroups();
    } else if (this.showDeleteColumnButton) {
      this.createColumn();
    }
  }
  @Output() canSkipAnswerEvent = new EventEmitter();
  public canSkipAnswer(val: boolean): void {
    // this.modalService.dismissAll(val);
    this.canSkipAnswerEvent.emit(val);
  }

  initSectionParamsForm(): void {
    this.sectionForm = this.formBuilder.group({
      name: ["", Validators.required],
      abbreviation: ["", Validators.required],
      description: ["", [Validators.maxLength(300)]],
    });

    if (this.content.data.sectionToEdit !== undefined) {
      let section = this.content.data.sectionToEdit;
      this.sectionForm.controls["name"].setValue(section.name);
      this.sectionForm.controls["abbreviation"].setValue(section.abbreviation);
      this.sectionForm.controls["description"].setValue(section.description);
    } else {
      let section = this.content.data.newSection;
      this.sectionForm.controls["name"].setValue(
        "Section " + (section.sectionLength + 1)
      );
      this.sectionForm.controls["abbreviation"].setValue(
        "Section " + (section.sectionLength + 1)
      );
    }
    this.sectionForm.controls["name"].valueChanges.subscribe((data: any) => {
      this.sectionForm.controls["abbreviation"].setValue(data.substr(0, 10));
    });
  }

  downloadSample() {
    if (this.content.data.Row) {
      this.ondownloadSample.emit(this.content.data.Row);
    } else {
      this.ondownloadSample.emit("true");
    }
  }

  addNewSectionToSurvey(): void {
    this.sectionFormSubmitted = true;
    if (this.sectionForm.valid) {
      let sectionData = this.content.data.newSection;
      sectionData = { ...sectionData, ...this.sectionForm.value };
      this.assessmentData.createSection(sectionData).subscribe(
        (section: ISection) => {
          this.modalService.dismissAll({ section });
        },
        (err) => { }
      );
    }
  }
  sectionFormSubmitted: boolean = false;

  updateSection(): void {
    this.sectionFormSubmitted = true;
    if (this.sectionForm.valid) {
      this.assessmentData
        .updateSection(
          this.content.data.sectionToEdit.url,
          this.sectionForm.value
        )
        .subscribe(
          (sectionData: any) => {
            this.modalService.dismissAll({
              sectionUpdated: true,
              section: { ...this.sectionForm.value },
            });
            this.sectionFormSubmitted = false;
          },
          (err) => {
            this.sectionFormSubmitted = false;
          }
        );
    }
  }

  get getSectionForm() {
    return this.sectionForm.controls;
  }

  public async shareEmbedCode(survey: any, type: any): Promise<void> {
    if(survey){
      await this.assessmentData.updateQuestionTags(survey.identifier).toPromise();
    }
    if(this.content.data["result"]){
      this.content.data["result"]["launch_method"] = SURVEY_LAUNCH_METHOD.SITE
      this.content.data["result"].is_survey = this.content.data.survey.is_survey;
      this.assessmentData
        .launchSurveyUsingProject(survey.project_identifier, this.content.data["result"])
        .toPromise()
        .then((response: any) => {
          if (response !== undefined) {
            this.toastService.success("Project has been launched successfully.");
            this.assessmentData.updateSurveyScope(survey, 0).subscribe(
              (resp) => {
                this.openEmbedPopup(type);
              },
              (err) => {
                this.toastService.warning(
                  "Unable to do this action. Please try later."
                );
              }
            );
          } else {
            this.toastService.warning(
              "Unable to launch project. Please try later."
            );
          }
        })
        .catch((failure: any) => {
          this.toastService.warning(
            failure.error && failure.error.error_msg
              ? failure.error.error_msg
              : "Unable to launch project. Please try later."
          );
        });
    }
    else{
      this.openEmbedPopup(type);
    }
  }

  openEmbedPopup(type: any) {
    this.activeEmbedRadio = type === "SiteIntercept"
    this.modalService.open(this.embedCodeCopyPopup, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      size: "sm",
      windowClass: "modal-holder",
    });
    if (this.activeEmbedRadio) {
      this.setEmbedValue("SiteIntercept")
    }
  }
  activeEmbedRadio: boolean = false;
  embedOptionChange(e: any, val) {
    this.activeEmbedRadio = val;
    switch (e.target.value) {
      case "SiteIntercept":
        this.setEmbedValue("SiteIntercept");
        break;
      case "Embed":
        this.setEmbedValue();
        break;
    }
  }
  copyEmbedCode() {
    this.toastService.success("Code copied successfully.");
    this.modalService.dismissAll("updated");
    if (this.router.url.toString().includes("builder")) {
      this.router.navigate(["/"]);
    }
  }

  checkBoxChecked(event: any, type: any) {
    let d: any = document.querySelector(`#embed_${type}`);
    d["disabled"] = event.target.checked;
    if (event.target.checked) {
      d["value"] = "";
      this.setEmbedValue(
        parseInt(this.embedCode.split("data-embedtype=")[1][1]) == 1
          ? ""
          : "SiteIntercept"
      );
    }
  }

  setEmbedValue(val?: any) {
    let d: any = document.querySelector(`#embed_height`);
    let w: any = document.querySelector(`#embed_width`);
    this.embedCode = `
      <div id="av-embed-${this.content.data["survey"].identifier
      }" data-embedtype="${val ? 2 : 1}"
        ${d["value"] ||
        d["value"]
        ? 'style= "' +
        (d["value"]
          ? "height: " +
          (d["value"] + "px;")
          : val
            ? "height: 300px"
            : "height: 100vh;") +
        (w["value"]
          ? "width: " +
          (w["value"] + "px;")
          : val
            ? "width:400px;"
            : "") +
        '"'
        : val
          ? 'style="height: 300px;width:400px;"'
          : 'style="height: 100vh;"'
      }
        ></div>
      <script id="avscript-embed-${this.content.data["survey"].identifier
      }" data-embedtype="${val ? 2 : 1}" src="${environment.PLATFORM_BASE_URL
      }snippet.js" async ></script>
    `;
  }

  showOptionalContainer: boolean = false;
  public openCompanyInfo(): void {
    this.getInitialCompanies();
    this.companyAlertRef = this.modalService.open(this.companyModal, {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "lg",
      windowClass: "modal-holder",
    });

    this.companyAlertRef.result.then(
      (result: any) => { },
      (reason: string) => {
        //change here
        if (reason === "emailSaved") {
          this.companyAlertRef.close();
          this.companyAlertRef = null;
        }
      }
    );
  }

  closeCompanyModal() {
    this.companyAlertRef.close();
    this.companyAlertRef = null;
    this.companyPageNo = 0;
    if (this.editPostLaunchAttribute) {
      this.modalService.dismissAll();
    }
  }
  companyAttributeForm: FormGroup;
  createCompanyForm(data: any) {
    this.companyAttributeForm = new FormGroup({
      company: new FormControl(data.company, [Validators.required]),
      cohort: new FormControl(data.cohort ? data.cohort : null),
      division: new FormControl(data.division ? data.division : null),
      department: new FormControl(data.department ? data.department : null),
      level: new FormControl(data.level ? data.level : null),
      team: new FormControl(data.team ? data.team : null),
      project: new FormControl(data?.project || data?.project_name),
      display_project: new FormControl(data?.display_project?data?.display_project:this.display_project_field),
      location: new FormControl(data.location ? data.location : null),
    });
  }

  companyPageNo = 0;
  templatePageNo = 0;
  companies_loaded = false;
  getInitialCompanies() {
    if (!this.companies_loaded) {
      this.companyPageNo++;
      this.assessmentData.getCompany(this.companyPageNo).subscribe(
        (data: any) => {
          let ids: any = [];
          ids = this.initialCompanyList.map((obj: any) => {
            return obj.id;
          });
          if (this.initialCompanyList.length) {
            for (let index = 0; index < data["results"].length; index++) {
              const element: any = data["results"][index];
              if (ids.indexOf(element.id) == -1) {
                this.initialCompanyList = [
                  ...this.initialCompanyList,
                  ...[element],
                ];
                this.loadCompanies();
              } else {
                this.initialCompanyList = [...this.initialCompanyList];
                this.loadCompanies();
              }
            }
          } else {
            this.initialCompanyList = data["results"];
            this.loadCompanies();
          }
        },
        (err) => {
          if (err.error.error_msg == "Invalid page.") {
            this.companies_loaded = true;
          }
          this.loadCompanies();
        }
      );
    }
  }

  search_company_term: any = "";
  onScrollToEnd(type?: any) {
    if (type == "company") {
      if (!this.search_company_term) {
        this.getInitialCompanies();
      }
    }
  }

  filteredCompanies$: Observable<any>;
  initialCompanyList: any = [];

  typedValue$ = new Subject<string>();
  searchLoading: boolean;

  loadCompanies() {
    this.filteredCompanies$ = concat(
      of(this.initialCompanyList),
      this.typedValue$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((val) => {
          this.searchLoading = true;
        }),
        switchMap((term) =>
          this.assessmentData
            .search(
              `companies/?ordering=name&search=${term && term.trim() ? term.trim() : ""
              }`,
              "Company"
            )
            .pipe(
              catchError(() => of([{ name: `Add New Company` }])), // empty list on error
              tap((data) => {
                {
                  setTimeout(() => {
                    $(".company_name ng-select ng-dropdown-panel .ng-option")
                      .last()
                      .addClass("add-button");
                  }, 500);
                  this.searchLoading = false;
                }
              })
            )
        )
      )
    );
  }

  getOrganizationName(event: any) {
    this.companyPageNo = 0;
    this.companies_loaded = false;
    this.search_company_term =
      event.target.value && event.target.value.trim() ? event.target.value : "";
  }

  getCharacters(event: any) {
    if (
      !event.target.value ||
      (event.target.value && event.target.value.length < 70)
    ) {
      return;
    } else {
      event.preventDefault();
    }
  }

  getBlurValue() {
    if (
      !this.companyName ||
      !this.companyAttributeForm.controls["company"].value
    ) {
      this.itemCleared();
    }
  }

  itemCleared() {
    this.companyPageNo = 1;
    this.companies_loaded = false;
    this.search_company_term = "";
    this.companyAttributeForm.controls["company"].setValue(null);
  }

  alertContent: any;
  addNewCompanyModal: any;
  getValue(event: any) {
    if (!event) {
      this.companyAttributeForm.controls["company"].setValue(null);
    } else {
      if (!event.id) {
        this.companyName = "";
        this.alertContent = {
          title: "Add New Company",
          message: "",
          data: { companyName: this.search_company_term },
          positiveAction: "createCompanyViaCopySurvey",
        };
        setTimeout(() => {
          this.addNewCompanyModal = this.modalService.open(
            this.addCompanyModal,
            {
              centered: true,
              keyboard: false,
              backdrop: "static",
              size: "lg",
              windowClass: "modal-holder",
            }
          );
        }, 1000);
      } else {
        this.search_company_term = event.name.trim();
        this.companyAttributeForm.controls["company"].setValue(event.id);
      }
    }
  }

  get f() {
    return this.companyAttributeForm.controls;
  }

  onActiveModalClosed(event: any) {
    // this.isLoading = true;
    this.assessmentData.setLoader({ bool: true });
    this.assessmentData.addCompany(event).subscribe(
      (data) => {
        this.toastService.success("Company added successfully.");
        this.companyName = data;
        this.search_company_term = event.name.trim();
        this.companyAttributeForm.controls["company"].setValue(data["id"]);
        //change here
        this.addNewCompanyModal.close();
        this.addNewCompanyModal = null;
        // this.isLoading = false;
        this.assessmentData.setLoader({ bool: false });
      },
      (err) => {
        this.toastService.error(
          err.error && err.error.error_msg
            ? err.error.error_msg
            : "Unable to add company. Please try again later."
        );
        // this.isLoading = false;
        this.assessmentData.setLoader({ bool: false });
      }
    );
  }

  closeCurrentModal(e: boolean) {
    if (this.addNewCompanyModal) {
      this.addNewCompanyModal.close();
      this.addNewCompanyModal = null;
    }
  }

  isSubmitted: boolean = false;
  companyAttributeUpdateForCopySurvey() {
    this.isSubmitted = true;
    if (this.companyAttributeForm.valid) {
      this.companyPageNo = 0;
      this.content.data.surveyData.company__name = this.companyName.name;
      // this.isLoading = true;
      
      this.updatedCompanyAttributeData = this.createCompanyObject(
        this.companyAttributeForm.getRawValue()
      );

      if (
        this.editPostLaunchAttribute &&
        this.content.data.hasOwnProperty("id")
      ) {
        this.is_requestsent = true;
        let body = this.updatedCompanyAttributeData
          ? this.updatedCompanyAttributeData
          : this.createCompanyObject(this.surveyData);

        let isProject: boolean = !!this.content.data.project;

        let apiToCall;


        let multiraterProject = this.content.data?.surveyData?.project_type == 2;
        let hasProjectName = !!this.companyAttributeForm.getRawValue().project;
        if (multiraterProject && !hasProjectName) {
          this.modalService.open(ConfirmMultiraterModalComponent, {
            centered: true,
            keyboard: false,
            backdrop: "static",
            windowClass: "modal-holder",
          }).result.then(
            (_) => {

            },
            (_) => {
              if(!body.project){
                body.project = ""
              }
              this.assessmentData.setLoader({ bool: true });
              if (isProject) {
                apiToCall = this.assessmentData
                  .updatePostLaunchProjectAttribute(this.content.data.project.project_identifier, body)
              } else {
                apiToCall = this.assessmentData
                  .updatePostLaunchSurveyAttribute(this.content.data.id, body)
              }
              apiToCall
                .subscribe(
                  (data) => {
                    this.modalService.dismissAll("updated");
                    this.is_requestsent = false;
                    this.assessmentData.setLoader({ bool: false });
                    this.isSubmitted = false;
      
                    this.toastService.success(
                      `${isProject ? "Project" : "Survey"} attributes have been updated successfully.`
                    );
                  },
                  (err) => {
                    this.toastService.warning(
                      `Unable to update attributes of the ${isProject ? "project" : "survey"}. Please try again later.`
                    );
                    this.is_requestsent = false;
                    this.assessmentData.setLoader({ bool: false });
                  }
                );
            },
          )
        } else {
          this.assessmentData.setLoader({ bool: true });
          if (isProject) {
            apiToCall = this.assessmentData
              .updatePostLaunchProjectAttribute(this.content.data.project.project_identifier, body)
          } else {
            apiToCall = this.assessmentData
              .updatePostLaunchSurveyAttribute(this.content.data.id, body)
          }
          apiToCall
            .subscribe(
              (data) => {
                this.modalService.dismissAll("updated");
                this.is_requestsent = false;
                this.assessmentData.setLoader({ bool: false });
                this.isSubmitted = false;
  
                this.toastService.success(
                  `${isProject ? "Project" : "Survey"} attributes have been updated successfully.`
                );
              },
              (err) => {
                this.toastService.warning(
                  `Unable to update attributes of the ${isProject ? "project" : "survey"}. Please try again later.`
                );
                this.is_requestsent = false;
                this.assessmentData.setLoader({ bool: false });
              }
            );
        }

      } else {
        this.assessmentData.setLoader({ bool: false });
        let multiraterProject = this.content.data?.surveyData?.project_type == 2;
        let hasProjectName = !!this.companyAttributeForm.getRawValue().project;
        if (multiraterProject && !hasProjectName) {
          this.modalService.open(ConfirmMultiraterModalComponent, {
            centered: true,
            keyboard: false,
            backdrop: "static",
            windowClass: "modal-holder",
          }).result.then(
            (_) => {

            },
            (_) => {
              this.closeCompanyModal();

              this.isSubmitted = false;
            },
          )
        } else {
          this.closeCompanyModal();
          this.isSubmitted = false;
        }


      }
    }
  }

  showMultiraterConfirmationModal() {

  }

  createCompanyObject(obj: any) {
    let objToSend = {
      company: obj.company,
      cohort: obj["cohort"],
      division: obj["division"],
      department: obj["department"],
      level: obj["level"],
      team: obj["team"],
      project: obj["project"] || obj["project_name"],
      display_project: obj["display_project"] || false,
      location: obj["location"],
    };

    return objToSend;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
