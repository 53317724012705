<div class="modal-content">
  <div class="fsop-alert-modal text-left">
    <!-- <h4 [innerHTML]="getInnerHtml()"></h4> -->
    <h4>Logic Builder</h4>
  </div>
  <div class="fsop-alert-modal-body text-left">
    <form
      class="ce-form"
      novalidate
      [formGroup]="displayLogicForm"
      style="overflow: visible;"
    >
      <div class="text-right mb-2" *ngIf="currentQuestion._type !== 8">
        <button
          (click)="addLogicRow()"
          class="btn btn-md add_new_btn btn-primary"
        >
          <!-- [disabled]="getArrayControl().length>4" -->
          <i class="fas fa-plus me-1"></i> Add New
        </button>
      </div>
      <ng-container>
        <ng-container formArrayName="data">
          <ng-container
            *ngFor="
    let control of getArrayControl();
    let i = index
  "
          >
            <app-common-logic-row
              [i]="i"
              [control]="control"
              [currentQuestion]="currentQuestion"
              [allQuestions]="allQuestions"
              [parentForm]="displayLogicForm"
              [postQuesSet]="postQuesSet"
              [postSameSecQuesSet]="postSameSecQuesSet"
              [postSecSet]="postSecSet"
              [includeSecQuesSet]="includeSecQuesSet"

              (removeLogicEvent)="removeLogicEvent($event)"
              [totalParentRows]="getArrayControl()"
              [onCloseModalTriggered]="onCloseModalTriggered"
            >
            </app-common-logic-row>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      (click)="save()"
      type="submit"
      class="btn btn-md btn-primary"
   
    >
      Save
    </button>
    <button (click)="closeModal()" class="btn btn-md btn-secondary">
      Cancel
    </button>
  </div>
</div>
<!-- [disabled]="!displayLogicForm.valid" -->