<app-loader
  [bgColor]="'#D8D8D8'"
  [isRealTime]="isRealTime"
  *ngIf="loading"
></app-loader>
<app-loader
  [bgColor]="'#D8D8D8'"
  *ngIf="!isBuilder && transitionLoading"
></app-loader>

<app-loader
  [bgColor]="'#D8D8D8'"
  *ngIf="!isBuilder && isSurveyLoading"
></app-loader>

<ng-container *ngIf="timeNotComeYet">
  <div>
    <div class="link-expire-container">
      Survey will be launched at [time in UTC] on [Date in UTC]
    </div>
  </div>
</ng-container>
<!-- <ng-container *ngIf="!showEmailContainer; else fillEmail"> -->
<ng-container *ngIf="!showEmailContainer">
  <div
    *ngIf="!isRealTime || (isRealTime && !loading)"
    class="scroll-auto-hide"
    [ngStyle]="{
      'background-image': custom_theme.background_image
        ? 'linear-gradient(rgba(255,255,255,' +
          (custom_theme.opacity_of_background_image ? custom_theme.opacity_of_background_image / 100 : 0) +
          '), rgba(255,255,255,' +
          (custom_theme.opacity_of_background_image ? custom_theme.opacity_of_background_image / 100 : 0) +
          ')), url(' +
          custom_theme.background_image +
          ')'
        : '',
      'background-color': custom_theme.background_color ? (pdfUrl ? '#D8D8D8' : custom_theme.background_color) : '',
      'background-repeat': custom_theme.background_image ? 'no-repeat' : '',
      'background-size': custom_theme.background_image ? 'cover' : '',
      position: isRealTime ? 'relative' : '',
      paddingTop: isRealTime && !custom_theme.background_image ? '0px !important' : ''
    }"
    class="full-width"
    [ngClass]="{
      'grey-theme': preview?.design.design_theme === 'grey',
      'dark-theme': preview?.design.design_theme === 'dark',
      'default-theme': preview?.design.design_theme === 'default',
      'custom-theme': preview?.design.design_theme == 'custom',
      'wth-head-logo': surveyDesign?.design_header != undefined && surveyDesign?.design_header != null,
      'light-theme': preview?.design.design_theme === 'light',
      'wthout-header-img-container': !surveyDesign?.design_header,
      'hv-iframe': !av_platform,
      'no-progressbar': custom_theme.progress_bar_display === 'none',
      'progressbar-extremelyTop': custom_theme.progress_bar_position === 'highest',
      'no-progressbar-txt': custom_theme.progress_bar_display === 'without_text',
      'wth-progressbar-txt': custom_theme.progress_bar_display !== 'without_text',
      'progressbar-bottom': custom_theme.progress_bar_position !== 'top' && custom_theme.progress_bar_position,
      'smooth-transition': custom_theme.theme_layout == 'smooth_scroll',
      'slide-transition': custom_theme.theme_layout == 'slide',
      'no-bg-image': (!custom_theme.background_image),
      'survey-submitted': (isSurveySubmitted),
      'wthout-foot': !(custom_theme.appearance_footer || custom_theme.appearance_custom_copyright_text),
      'full-width':custom_theme.eliminate_the_container == true,
      'have-title-head': (surveySettings?.appearance)[getSettingIndex('appearance', 'appearance_survey_title')].value
        ? custom_theme['show_header_with_assessment_name']
        : (surveySettings?.appearance)[getSettingIndex('appearance', 'appearance_survey_title')].value
    }"
  >
    <!-- refer here -->
    <!-- [ngStyle]="{'display': !pdfLoaded?'none':'block'}" -->
    <div class="data-container" *ngIf="pdfUrl && showReportView">
      <ngx-doc-viewer
        [url]="pdfUrl"
        viewer="google"
        style="width: 100%; height: 95vh;"
        (loaded)="contentLoaded()"
      >
      </ngx-doc-viewer>
    </div>
    <!-- <header
      *ngIf="av_platform && !isRealTime && this.custom_theme.theme_layout != 'classic' && !isSurveySubmitted"
      [ngStyle]="{ 'background-color': ( custom_theme.background_color && !custom_theme.background_image)? custom_theme.background_color : ''}"
    >
      <div class="header-left-item">
        <figure (click)="navigateToHome()">
          <img
            *ngIf="
              preview?.design.design_theme == 'light' ||
              preview?.design.design_theme == 'default' ||
              !preview?.design.design_theme
            "
            src="assets/images/respondant/themes/light/white-bg-logo.png"
          />
          <img
            *ngIf="preview?.design.design_theme == 'grey' || preview?.design.design_theme == 'dark'"
            src="assets/images/respondant/themes/grey/gray-bg-logo.png"
          />
        </figure>
        <p *ngIf="!responseMode" class="preveiw-mode">Preview Mode</p>
      </div>
      <div class="header-right-item">
        <div class="nav-item dropdown">
          <div
            *ngIf="!responseMode"
            id="user-dropdown"
            class="nav-link dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img class="image-box" [src]="userProfileImage" />
            <div class="name-container">
              {{ userProfile?.first_name }}
            </div>

            <i class="fi fi-arrow-down arrow"></i>
          </div>
          <ul class="dropdown-menu" role="menu">
            <div class="arrow-up-border"></div>
            <div class="arrow-up"></div>

            <li
              ng-if="!user.get().is_superuser &amp;&amp; !user.roleType.is('end_user')"
              class=""
            >
              <div class="link-container" [routerLink]="['/my-profile']">
                <i class="icon-user user-fix"></i>
                <a [routerLink]="['/my-profile']">My Profile</a>
              </div>
            </li>

            <li (click)="logout()">
              <div class="link-container" ui-sref="logout">
                <i class="fi fi-SignOut-Icon"></i>
                <a>Log Out</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header> -->
    <app-ressource-not-found
      *ngIf="ressourceNotFound && !surveyLinkExpired"
    ></app-ressource-not-found>

    <div class="link-expire-container" *ngIf="surveyLinkExpired">
      Sorry, you can't fill the survey now. Survey link has been expired.
    </div>
    <div
      *ngIf="!pdfLoaded"
      [ngClass]="{ highestPosFsop: custom_theme.progress_bar_position === 'highest' }"
    >
      <div
        class="highestScrollBar"
        *ngIf="custom_theme.progress_bar_position == 'highest'"
        [ngStyle]="{
          'background-color': custom_theme.container_color ? custom_theme.container_color : ''
        }"
      >
        <div
          [ngClass]="{
            'container pb-1 pt-0': preview?.design.design_theme !== 'custom',
            'pt-2': custom_theme.progress_bar_display === 'without_text' && custom_theme.progress_bar_position !== 'highest',
            'pt-0': preview?.design.design_theme == 'custom' && custom_theme.progress_bar_position == 'highest',
            'pt-1': preview?.design.design_theme !== 'custom' && custom_theme.progress_bar_position == 'highest'
          }"
        >
          <div
            *ngIf="custom_theme.progress_bar_position == 'highest'; then progressBar"
          ></div>
        </div>
      </div>
      <!-- here -->
      <div
        *ngIf="!ressourceNotFound && !surveyLinkExpired"
        [ngClass]="{
          'have-header-img': surveyDesign?.design_header != undefined && surveyDesign?.design_header != null,
          iframe: !av_platform,
          'have-submit-tab ms-0 w-100': isSurveySubmitted,
          'error-box': surveyLoadingError !== undefined
        }"
        [ngStyle]="{
          'background-color': custom_theme.container_color ? custom_theme.container_color : '',
          overflow: custom_theme.theme_layout == 'smooth_scroll' ? 'hidden' : ''
        }"
        class="fsop-container launch-gutter"
        id="fsop{{ random }}"
        [style.marginLeft]="isRealTime ? '0' : ''"
      >
        <span class="container-overlay">
          <!-- <span
        class="container-overlay"
        [ngStyle]="{
        'background-color': (custom_theme.container_color)? custom_theme.container_color : '',
        'opacity': custom_theme.container_opacity ||custom_theme.container_opacity===0  ? custom_theme.container_opacity/100 : custom_theme.background_image ?  0.9 :1
      }"
      > -->
        </span>
        <div
          [ngClass]="preview?.design.design_theme === 'dark' ? 'white-text' : ''"
          class="container padding-0"
        >
          <div
            *ngIf="surveyDesign?.design_header != undefined && surveyDesign?.design_header != null && !isSurveySubmitted "
            class="container"
            [ngStyle]="{
              'text-align': custom_theme.logo_placement ? custom_theme.logo_placement : '',
              'justify-content': custom_theme.logo_placement
                ? custom_theme.logo_placement == 'right'
                  ? 'flex-end'
                  : custom_theme.logo_placement
                : ''
            }"
            [ngClass]="{
              'header-img-container': surveyDesign?.design_header != undefined && surveyDesign?.design_header != null
            }"
          >
            <img
              [ngStyle]="{
                'max-width': '340px',
                'max-height': '75px'
              }"
              class="header-img"
              [src]="getHeaderImage()"
            />
          </div>

          <div
            class="error-container text-center"
            *ngIf="surveyLoadingError !== undefined"
          >
            <p>{{ surveyLoadingError }}</p>
          </div>
          <div class="wizard-gutter" *ngIf="isSurveySubmitted">
            <div class="thanks-msg" style="font-size: 17px;">
              <!-- <pre>{{ surveyCloseMessage }}</pre> -->
              <!-- <figure *ngIf="!av_platform">
                <img src="assets/images/circle-check.svg" alt="Congratulations!">
              </figure> -->
              <h4 *ngIf="!av_platform && !surveyCloseMessage">
                Congratulations!
              </h4>
              <div
                [innerHTML]="surveyCloseMessage || 'You have already submitted the survey.' | safeHtml"
              ></div>

              <!-- <div *ngIf = "generate_individual_report">
                <button (click)="generateIndividualReport()" class="btn btn-primary">Generate Report</button>
              </div> -->
            </div>
          </div>
        </div>
        <div
          *ngIf="surveyLoadingError == undefined && !isSurveySubmitted"
          class="container ember"
          [ngStyle]="{
            position:
              this.answeredQuestions.length && this.custom_theme.theme_layout == 'smooth_scroll'
                ? 'relative'
                : 'relative'
          }"
        >
          <p
            *ngIf="
              (surveySettings?.appearance)[getSettingIndex('appearance', 'appearance_survey_title')].value
                ? custom_theme['show_header_with_assessment_name']
                : (surveySettings?.appearance)[getSettingIndex('appearance', 'appearance_survey_title')].value
            "
            class="title-heading"
            [ngStyle]="{
              'background-color': custom_theme.heading_background_color ? custom_theme.heading_background_color : '',
              'font-size.px': custom_theme.heading_font_size ? custom_theme.heading_font_size : '',
              'text-align': custom_theme.survey_title_alignment ? custom_theme.survey_title_alignment : '',
              color: custom_theme.survey_title_font_color
                ? custom_theme.survey_title_font_color
                : custom_theme
                ? '#a5a5a5'
                : ''
            }"
            [ngClass]="custom_theme.theme_layout == 'smooth_scroll' && currentPageIndex > 0 ? '' : ''"
          >
            <!-- Ambivista <span *ngIf="responseMode">View</span><span *ngIf="!responseMode">Preview</span> of  -->
            <b
              >{{ preview.is_template ? preview.template_name : preview.title
              }}</b
            >
          </p>

          <div
            [hidden]="!showRequiredQuestionErrorBox"
            class="required"
            [ngClass]="isBuilder ? 'survey-msg-side-preview' : 'survey-msg-sec'"
          >
            <p>Questions denoted with an asterisk are required.</p>
          </div>
          <div
            *ngIf="custom_theme.progress_bar_position == 'top' || !custom_theme.progress_bar_position; then progressBar"
          ></div>
          <!---->

          <!-- previously -->
          <!-- <ng-container *ngIf = "(!custom_theme.apply_theme ||  custom_theme.theme_layout == 'classic'), else slide_or_scroll"> -->
          <ng-container
            *ngIf="
              (custom_theme.theme_layout == 'classic' || isBuilder || custom_theme.theme_layout == 'focus') &&
                custom_theme.theme_layout !== 'slide';
              else slide_or_scroll
            "
          >
            <div class="question-section-container">
              <app-question-section
                class="ques-container activeQuesContainer"
                [ngClass]="{
                  'scroll-visible': custom_theme.scrollbar_visibility === 'always_visible',
                  'scroll-auto-hide': custom_theme.scrollbar_visibility === 'auto_hide',
                  'scroll-hide': custom_theme.scrollbar_visibility === 'none',
                  'focus_layout': isPreivewPage() && custom_theme.theme_layout == 'focus'
                }"
                [editable]="preventEditingResponse"
                [backClicked]="backClicked"
                [showQuestionNumber]="custom_theme.appearance_show_question_number"
                [isNextButtonClicked]="isNextButtonClicked"
                (resetNextButton)="doResetNextButton($event)"
                (onDoneQuestionsUpdated)="onDoneQuestionsUpdated($event)"
                [previewMode]="true"
                [customThemeSettings]="custom_theme"
                [theme]="preview?.design.design_theme"
                [questions]="currentQuestions"
                [answeredQuestions]="answeredQuestions"
                [showRequiredAsterisk]="custom_theme.appearance_required_astericks"
                [showSectionName]="custom_theme.appearance_show_section_name"
              >
              </app-question-section>
            </div>
          </ng-container>

          <ng-template #slide_or_scroll>
            <!-- hidden added for slide -->
            <div
              class="question-section-container"
              [ngClass]="{
                'display-inline-flex': custom_theme.theme_layout == 'slide',
                'display-grid': custom_theme.theme_layout == 'scroll',
                'display-grid transition-500': custom_theme.theme_layout == 'smooth_scroll'
              }"
              [hidden]="custom_theme.theme_layout == 'slide' && !slideloaded"
            >
              <ng-container *ngFor="let page of sections; let i = index">
                <!-- <span *ngIf="i != currentPageIndex">page?.questions</span> -->
                <app-question-section
                  *ngIf="i != currentPageIndex"
                  class="ques-container"
                  [ngClass]="{
                    'scroll-visible': custom_theme.scrollbar_visibility === 'always_visible',
                    'scroll-auto-hide': custom_theme.scrollbar_visibility === 'auto_hide',
                    'scroll-hide': custom_theme.scrollbar_visibility === 'none',
                    activeQuesContainer: i == currentPageIndex,
                    'min-width': custom_theme.theme_layout == 'slide' && isBuilder,
                    'visibility-hidden': i != currentPageIndex && custom_theme.theme_layout == 'slide' && isBuilder
                  }"
                  [sectionIndex]="i"
                  [customThemeSettings]="custom_theme"
                  [currentPageIndex]="currentPageIndex"
                  [showQuestionNumber]="custom_theme.appearance_show_question_number"
                  [previewMode]="true"
                  [theme]="preview?.design.design_theme"
                  [questions]="page?.questions"
                  [showRequiredAsterisk]="custom_theme.appearance_required_astericks"
                  [showSectionName]="custom_theme.appearance_show_section_name"
                >
                </app-question-section>
                <app-question-section
                  *ngIf="i == currentPageIndex"
                  class="ques-container qweqewqweqwe"
                  [sectionIndex]="i"
                  [currentPageIndex]="currentPageIndex"
                  [customThemeSettings]="custom_theme"
                  [ngClass]="{
                    'scroll-visible': custom_theme.scrollbar_visibility === 'always_visible',
                    'scroll-auto-hide': custom_theme.scrollbar_visibility === 'auto_hide',
                    'scroll-hide': custom_theme.scrollbar_visibility === 'none',
                    activeQuesContainer: i == currentPageIndex,
                    'min-width': custom_theme.theme_layout == 'slide' && isBuilder
                  }"
                  [editable]="preventEditingResponse"
                  [backClicked]="backClicked"
                  [showQuestionNumber]="custom_theme.appearance_show_question_number"
                  [isNextButtonClicked]="isNextButtonClicked"
                  (resetNextButton)="doResetNextButton($event)"
                  (onDoneQuestionsUpdated)="onDoneQuestionsUpdated($event)"
                  [previewMode]="true"
                  [theme]="preview?.design.design_theme"
                  [questions]="currentQuestions"
                  [answeredQuestions]="answeredQuestions"
                  [showRequiredAsterisk]="custom_theme.appearance_required_astericks"
                  [showSectionName]="custom_theme.appearance_show_section_name"
                >
                </app-question-section>
              </ng-container>
            </div>
          </ng-template>
          <div
            *ngIf="
              custom_theme.progress_bar_position != 'top' &&
                custom_theme.progress_bar_position != 'highest' &&
                custom_theme.progress_bar_position;
              then progressBar
            "
          ></div>
        </div>

        <!-- add div here -->
      </div>
    </div>

    <footer [style.position]="isRealTime ? 'unset' : ''">
      <div class="survey-btn-sec">
        <div
          class="btn-actions"
          [ngStyle]="{
            'background-color': custom_theme.container_color
              ? themeType && themeType == 'custom'
                ? custom_theme.container_color
                : surveyDesign && surveyDesign.design_theme && surveyDesign.design_theme == 'custom'
                ? custom_theme.container_color
                : 'transparent'
              : 'transparent',
            'flex-wrap': custom_theme.progress_bar_position != 'top' && custom_theme.progress_bar_position ? 'wrap' : ''
          }"
          *ngIf="!isSurveySubmitted && !surveyLoadingError && !ressourceNotFound && !surveyLinkExpired"
          [ngClass]="{
            'btn-placement-left': custom_theme.change_button_placement === 'left',
            'btn-placement-center': custom_theme.change_button_placement === 'center',
            'btn-placement-right': custom_theme.change_button_placement === 'right'
          }"
        >
          <!-- <span
            class="container-overlay"
            [ngStyle]="{
            'background-color': (custom_theme.container_color)? custom_theme.container_color : '',
            'opacity': custom_theme.container_opacity || custom_theme.container_opacity === 0 ? custom_theme.container_opacity/100 : custom_theme.background_image ?  0.9 :1
          }"
          > -->
          <span class="container-overlay"> </span>
          <div class="back-btns">
            <!-- <button [disabled]="surveyLoadingError !== undefined" [hidden]="isSurveySubmitted"
              *ngIf="currentPageIndex > 0 && !canSubmit && surveySettings.experience[1].value" (click)="back()"
              class="btn btn-success btn-md">
              Back
            </button> -->

            <button
              [disabled]="surveyLoadingError !== undefined"
              [hidden]="isSurveySubmitted"
              *ngIf="currentPageIndex > 0 && !canSubmit && custom_theme.experience_back_button"
              (click)="back()"
              class="btn btn-md rounded"
              [ngClass]="{ 'btn-blue rounded': applyTheme(), 'btn-success': !applyTheme() }"
              style="display: inline;"
              [ngStyle]="{
                'background-color': custom_theme.back_button_color ? custom_theme.back_button_color : '',
                border: '1px solid transparent',
                borderColor: custom_theme.back_button_border_color
                  ? custom_theme.back_button_border_color
                  : custom_theme.back_button_color,
                color: custom_theme.back_button_font_color ? custom_theme.back_button_font_color : ''
              }"
            >
              <i
                *ngIf="applyTheme()"
                [ngStyle]="{ color: custom_theme.back_button_font_color ? custom_theme.back_button_font_color : '' }"
                class="left-icon fa fa-angle-left"
              ></i>
              Back
            </button>
          </div>
          <div class="sub-nxt-btns">
            <!-- <button [disabled]="surveyLoadingError !== undefined" (click)="next()"
              *ngIf="!isSurveySubmitted && !surveyLoadingError && !ressourceNotFound && !surveyLinkExpired"
              class="btn btn-success btn-md">
              <span *ngIf="sections[currentPageIndex + 1] !== undefined">Next</span>
              <span *ngIf="sections[currentPageIndex + 1] === undefined">Submit</span>
            </button>
            <button style="width: 110px;" [disabled]="surveyLoadingError !== undefined"
              *ngIf="!isSurveySubmitted && !surveyLoadingError && !ressourceNotFound && !surveyLinkExpired"
              class="btn btn-danger btn-md" (click)="saveSurveyResponse()">
              <i *ngIf="!isSaving" class="far fa-save"></i>
              <span *ngIf="!isSaving"> Save</span>
              <i style="margin-left: -10px; float: left; margin-top: 2px;" class="fas fa-cog fa-spin" *ngIf="isSaving"></i>

              <span style="text-align: left;" *ngIf="isSaving"> Saving ...</span>
            </button> -->
            <button
              [disabled]="surveyLoadingError !== undefined"
              *ngIf="!isSurveySubmitted && !surveyLoadingError && !ressourceNotFound && !surveyLinkExpired"
              class="btn btn-md"
              style="display: inline;"
              (click)="saveSurveyResponse('btnclick')"
              [ngClass]="{ 'btn-green rounded': applyTheme(), 'btn-danger': !applyTheme() }"
              [ngStyle]="{
                'background-color': custom_theme.save_button_color ? custom_theme.save_button_color : '',
                'border-color': custom_theme.save_button_border_color
                  ? custom_theme.save_button_border_color
                  : custom_theme.save_button_color,
                border: custom_theme.save_button_border_color
                  ? 'solid 1px ' + custom_theme.save_button_border_color
                  : 'solid 1px transparent',
                color: custom_theme.save_button_font_color ? custom_theme.save_button_font_color : '#ffffff'
              }"
            >
              <i
                *ngIf="!isSaving && !applyTheme()"
                [ngStyle]="{
                  color: custom_theme.save_button_font_color ? custom_theme.save_button_font_color : '#ffffff'
                }"
                style="margin-right: 5px;"
                class="far fa-save"
              ></i>
              <ng-container *ngIf="!isSaving"> Save </ng-container>
              <i
                style="margin-right: 5px;"
                class="fas fa-cog fa-spin"
                *ngIf="isSaving"
              ></i>
              <ng-container style="text-align: left;" *ngIf="isSaving">
                Saving</ng-container
              >
            </button>
            <button
              [disabled]="surveyLoadingError !== undefined"
              (click)="next(null, true)"
              *ngIf="!isSurveySubmitted && !surveyLoadingError && !ressourceNotFound && !surveyLinkExpired"
              class="btn btn-md"
              style="display: inline;"
              [ngClass]="{ 'btn-blue rounded': applyTheme(), 'btn-success': !applyTheme() }"
              [ngStyle]="{
                'background-color': custom_theme.next_button_color ? custom_theme.next_button_color : '',
                'border-color': custom_theme.next_button_border_color
                  ? custom_theme.next_button_border_color
                  : custom_theme.next_button_color,
                border: custom_theme.next_button_border_color
                  ? 'solid 1px ' + custom_theme.next_button_border_color
                  : 'solid 1px transparent',
                color: custom_theme.next_button_font_color ? custom_theme.next_button_font_color : '#ffffff'
              }"
            >
              <ng-container *ngIf="sections[currentPageIndex + 1] !== undefined"
                >Next<i
                  class="right-icon fa fa-angle-right"
                  *ngIf="applyTheme()"
                ></i
              ></ng-container>
              <ng-container *ngIf="sections[currentPageIndex + 1] === undefined"
                >Submit</ng-container
              >
            </button>
          </div>
        </div>
        <div
          class="post-foot"
          [ngStyle]="{
            'background-color':
              custom_theme.background_color && !custom_theme.background_image ? custom_theme.background_color : ''
          }"
        >
          <ng-container
            *ngIf="
              custom_theme.appearance_footer ||
              custom_theme.appearance_custom_copyright_text ||
              surveyLoadingError !== undefined
            "
          >
            <!-- <div cla ss="foot-right">
              <span class="by"
                >POWERED BY &nbsp; <a href="http://www.finoit.com/"><b>Finoit Technologies Pvt. Ltd</b></a></span
              >
              <span class="line">|</span>
              <a href="http://www.finoit.com/account-request/" target="_blank">Create a Complimentary Account</a>
            </div> -->
            <div
              class="p-footer"
              [style.color]="
                custom_theme.custom_copyright_text_color ? custom_theme.custom_copyright_text_color : '#a5a5a5'
              "
              [ngClass]="{ 'text-center': !av_platform }"
            >
              <p
                [ngClass]="
                  !(custom_theme.appearance_footer && custom_theme.appearance_custom_copyright_text) || !av_platform
                    ? 'd-block'
                    : ''
                "
              >
                <span
                  class="copyright"
                  *ngIf="custom_theme.appearance_custom_copyright_text"
                >
                  {{ custom_theme.appearance_custom_copyright_text }}
                </span>
                <ng-container *ngIf="av_platform">
                  <span
                    *ngIf="custom_theme.appearance_footer && custom_theme.appearance_custom_copyright_text"
                    class="line"
                    >|</span
                  >
                  <ng-container *ngIf="custom_theme.appearance_footer">
                    <span class="copyright"
                      >© Copyright {{ getCurrentYear() }} Ambivista. All rights
                      reserved.</span
                    >
                    <span class="pull-right">
                      <a
                        href="https://www.ambivista.com/privacy-policy/"
                        target="_blank"
                        [style.color]="
                          custom_theme.custom_copyright_text_color
                            ? custom_theme.custom_copyright_text_color
                            : '#a5a5a5'
                        "
                        >Privacy</a
                      >
                      <!-- <a
                        href="javascript:void(0)"
                        target="_blank"
                        [style.color]="custom_theme.custom_copyright_text_color ? custom_theme.custom_copyright_text_color :'#a5a5a5'"
                      >
                        Security</a
                      > -->
                      <a
                        href="https://www.ambivista.com/terms-of-use/"
                        target="_blank"
                        [style.color]="
                          custom_theme.custom_copyright_text_color
                            ? custom_theme.custom_copyright_text_color
                            : '#a5a5a5'
                        "
                        >Terms of Service</a
                      >
                    </span>
                  </ng-container>
                </ng-container>
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </footer>

    <!--alert modal-->
    <ng-template #skipAnswerAlert let-modal class="no-border">
      <app-alert
        [content]="alertContent"
        (canSkipAnswerEvent)="canSkipAnswerEvent($event)"
      ></app-alert>
    </ng-template>
  </div>
</ng-container>

<ng-template #fillEmail>
  <div *ngIf="SaveAndResume">
    <div
      *ngIf="!email_sent; else email_sent_successfully"
      class="container emailpopup"
    >
      <div class="form-group">
        <p class="text-center d-block">
          Your survey has been successfully saved.
        </p>
        <p>
          <a
            href="{{ _environment.PLATFORM_BASE_URL }}survey/{{ currentSurveyId }}/responses/{{ currentResponseId }}"
            target="_blank"
            >{{ _environment.PLATFORM_BASE_URL }}survey/{{ currentSurveyId
            }}/responses/{{ currentResponseId }}</a
          >
        </p>
        <p class="text-center d-block">
          {{ contactFormEmail ? "The link will be send to the below email
          address." : "Enter your email address below to receive this link by
          email." }}
        </p>
        <input
          id="contact_form_email"
          class="form-control"
          type="text"
          [(ngModel)]="contactEmail"
          (ngModelChange)="emailChange($event)"
        />
        <p class="text-danger" *ngIf="emailInvalid">{{ emailInvalidMsg }}</p>
      </div>
      <div class="col-lg-12 text-center">
        <button class="btn btn-primary" (click)="sendResumeEmail()">
          Send Email
        </button>
      </div>
    </div>
    <ng-template #email_sent_successfully>
      <div class="container emailpopup">
        <p class="mb-0">
          Your survey link has been sent to the following email address: {{
          contactEmail }}. If after a few moments it has not been received,
          please check your spam folder.
        </p>
      </div>
    </ng-template>
  </div>
</ng-template>
<ng-template #progressBar>
  <div
    *ngIf="custom_theme.progress_bar_display !== 'none'"
    class="question-section-head"
    [style.width]="custom_theme.progress_bar_position != 'top' && custom_theme.progress_bar_position ? '100%' : ''"
    [ngClass]="{
      hide: custom_theme.progress_bar_display === 'none',
      'default-minmalist d-flex align-item-center flex-column':
        custom_theme.progress_bar_style == 'minimalist' && preview?.design.design_theme !== 'custom'
    }"
    [ngStyle]="{
      'margin-top': custom_theme.progress_bar_position != 'top' ? '17px' : '0px',
      'margin-bottom': custom_theme.progress_bar_position != 'top' ? '0px' : '21px'
    }"
  >
    <div
      *ngIf="(surveySettings?.appearance)[getSettingIndex('appearance', 'appearance_show_progress_bar')].value   && !isSurveySubmitted"
      [ngStyle]="{
        'background-color': custom_theme.progress_bgcolor ? custom_theme.progress_bgcolor : '',
        'margin-bottom': custom_theme.progress_bar_position != 'top' ? '0px' : '0px'
      }"
      [ngClass]="{
        round: custom_theme.progress_bar_shape == 'circular',
        rectangular: custom_theme.progress_bar_shape == 'rectangular',
        'gray-progress': preview?.design.design_theme === 'grey',
        'dark-progress': preview?.design.design_theme === 'dark',
        'light-progress': preview?.design.design_theme === 'light',
        'default-minmalist-progress':
          custom_theme.progress_bar_style == 'minimalist' && preview?.design.design_theme !== 'custom'
      }"
      [style.width]="custom_theme.progress_bar_style == 'minimalist' ? '30%' : ''"
      [style.height]="
        custom_theme.progress_bar_style == 'minimalist' && preview?.design.design_theme == 'custom' ? '6px' : ''
      "
      class="progress"
    >
      <div
        class="progressbar"
        role="progressbar"
        aria-valuenow="80"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{
          background: custom_theme.progress_bar_color ? custom_theme.progress_bar_color : custom_theme ? '#a5a5a5' : '',
          width: progressState + '%'
        }"
      ></div>

      <div
        [ngStyle]="{ left: progressState != 0 ? progressState - 1.5 + '%' : '0',
        background: custom_theme.progress_bar_color ? custom_theme.progress_bar_color : custom_theme ? '#a5a5a5' : ''
      }"
        class="counter"
      >
        <!-- {{ counterDoneQuestions }} -->
        <!-- {{ currentPageIndex }} -->
      </div>
    </div>
    <p
      class="nmbr"
      [ngClass]="{ 'd-none': custom_theme.progress_bar_display === 'without_text' }"
    >
      {{ counterDoneQuestions }} out of {{ totalQuestions }} Questions Answered
    </p>
  </div>
</ng-template>
<ng-template #saveAndResumeModal let-modal class="no-border">
  <div class="modal-content">
    <div *ngIf="SaveAndResume">
      <div
        *ngIf="!email_sent; else email_sent_successfully"
        class="container emailpopup"
      >
        <div class="form-group">
          <p class="text-center d-block">
            Your survey has been successfully saved.
          </p>
          <p>
            <a
              href="{{ _environment.PLATFORM_BASE_URL }}survey/{{ currentSurveyId }}/responses/{{ currentResponseId }}"
              target="_blank"
              >{{ _environment.PLATFORM_BASE_URL }}survey/{{ currentSurveyId
              }}/responses/{{ currentResponseId }}</a
            >
          </p>
          <p class="text-center d-block">
            {{ contactFormEmail ? "The link will be send to the below email
            address." : "Enter your email address below to receive this link by
            email." }}
          </p>
          <input
            id="contact_form_email"
            class="form-control"
            type="text"
            [(ngModel)]="contactEmail"
            (ngModelChange)="emailChange($event)"
          />
          <p class="text-danger" *ngIf="emailInvalid">{{ emailInvalidMsg }}</p>
        </div>
        <div class="col-lg-12 text-center">
          <button class="btn btn-primary" (click)="sendResumeEmail()">
            Send Email
          </button>
        </div>
      </div>
      <ng-template #email_sent_successfully>
        <div class="container emailpopup">
          <p class="mb-0">
            Your survey link has been sent to the following email address: {{
            contactEmail }}. If after a few moments it has not been received,
            please check your spam folder.
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
