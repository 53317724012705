<div class="info-box mb-2 blue" *ngIf="isQuestionhaveDisplayLogic">
  <span
    class="box-icon d-flex align-items-center justify-content-center"
    [ngStyle]="{'background-color': isInvalid() ? '#FF6961' : '' }"
  >
    <i class="svg displaylogic-icon"></i>
  </span>
  <div class="toggle d-flex align-items-center">
    <div class="d-flex w-100 align-items-center justify-content-between">
      <div class="d-flex flex-column">
        <span>Display origin</span>
        <ng-container *ngIf="isInvalid()">
            <small class="text-danger-pastel d-inline-block me-2"
              >Invalid Logic</small
            >
          </ng-container>
      </div>
    </div>
  </div>
  <div class="nav-item dropdown" style="position:absolute;right: 12px;top: 12px;" >
    <div class="nav-item dropdown">
      <div
        role="button"
        data-bs-toggle='dropdown'
        aria-haspopup="true"
        aria-expanded="false"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fa fa-ellipsis-h"></i>
      </div>
      <div
        class="dropdown-menu"
        role="menu"
        style="padding: 0; right: 0px; left: inherit;">
        <a class="dropdown-item" (click)="openDisplayLogicModal()">Edit</a>
            <!-- *ngIf="showDeleteOption()" -->
            <a class="dropdown-item" (click)="openConfirmModal(deleteModal)"
              >Remove</a
            >
      </div>
    </div>
</div>
</div>

<div class="info-box mb-2 blue" *ngIf="destQuesDisLogic && destQuesDisLogic.length">
  <span
    class="box-icon d-flex align-items-center justify-content-center"
  >
    <i class="svg displaylogic-icon"></i>
  </span>
  <div class="toggle d-flex align-items-center">
    <div class="t-switch" (click)="ShowDisplayBox=!ShowDisplayBox">
      <i
        class="fas"
        [ngClass]="ShowDisplayBox ? 'fa-angle-down' : 'fa-angle-right'"
      ></i>
    </div>
    <div class="d-flex w-100 align-items-center justify-content-between">
      <div>
        <span>Display this question</span>
      </div>
    </div>
  </div>
  <div class="nav-item dropdown" style="position:absolute;right: 12px;top: 12px;" >
    <div class="nav-item dropdown">
      <div
        role="button"
        data-bs-toggle='dropdown'
        aria-haspopup="true"
        aria-expanded="false"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fa fa-ellipsis-h"></i>
      </div>
      <div
        class="dropdown-menu"
        role="menu"
        style="padding: 0; right: 0px; left: inherit;">
        <a class="dropdown-item" (click)="goToOrigin($event)">Go to origin</a>
      </div>
    </div>
</div>
  <ng-container *ngIf="ShowDisplayBox">
    <div
    class="toggle-data"
    *ngFor="let QuesDisLogic of destQuesDisLogic;let a=index"
  >
    <ng-container *ngFor="let condition of QuesDisLogic.conditions;let i=index">
      <ng-container *ngIf="!condition.invalid">
        <span class="me-1" *ngIf="condition.conjuction"
          >{{condition.conjuction}}</span
        >

        <!-- <span style="max-width: 300px" class="me-1 text-truncate" placement="bottom" [ngbTooltip]="UploadCerttipContent" *ngIf="i==0"
          >{{condition.question_choosen}}</span
        > -->
        <!-- <ng-template #UploadCerttipContent>{{condition.question_choosen}}</ng-template> -->
                            
        <span class="me-1">If</span>
        <span class="me-1" *ngIf="condition.question_choice_text" [innerHtml]="condition.question_choice_text"></span>
        <span class="me-1" *ngIf="!condition.question_choice_text">value</span>
        <!-- <span class="me-1" *ngIf="condition.question_choice_text"
          >{{condition.question_choice_text}}</span
        >  -->
        <span class="me-1">of</span>
        <span class="me-1" *ngIf="QuesDisLogic.origin_question_detail"
          >{{getQuestionNumber(QuesDisLogic.origin_question_detail.id)}} <span [innerHtml]="QuesDisLogic.origin_question_detail.text"></span></span
        > 
        <span class="me-1" *ngIf="condition.operator"
          >{{condition.operator}}</span
        >
        <span class="me-1" *ngIf="condition.operator_value"
          >{{condition.operator_value}}</span
        >
      </ng-container>

      <!-- <ng-container *ngIf="condition.invalid && i==(conditionsArr.length-1)">
        <small class="text-danger-pastel d-inline-block me-2"
          >Invalid Logic</small
        >
      </ng-container> -->
      <!-- <ng-container *ngIf="condition.msg && i==(conditionsArr.length-1)">
        <small class="text-danger d-inline-block me-2">{{condition.msg}}</small>
      </ng-container> -->
    </ng-container>
    </div>
  </ng-container>

  
</div>
<ng-template #displayLogicModal let-modal class="no-border">
  <app-common-logic
    [currentQuestion]="currentQuestion"
    [allQuestions]="allQuestions"
    (onCloseModalTriggered)="closeDisplayLogicModal()"
    (submitTriggered)="submitLogic($event)"
    [surveyData]="surveyData"
  ></app-common-logic>
</ng-template>
<ng-template #deleteModal let-modal class="no-border">
  <app-confirm-modal
    [title]="'Alert'"
    [buttonName]="'Confirm'"
    [message]="'Are you sure you want to delete this logic?'"
    (onCloseModalTriggered)="closeConfirmModal()"
    (submitTriggered)="delete()"
  ></app-confirm-modal>
</ng-template>
<!-- <div class="info-box mb-2 purple">
  <div class="toggle d-flex align-items-center">
    <div class="t-switch" (click)="ShowSkipBox=!ShowSkipBox">
      <i class="fas" [ngClass]="ShowSkipBox ? 'fa-angle-down' : 'fa-angle-right'"></i>
    </div>
    <div>
      <i class="fas fa-plus-circle me-2"></i>
      <span>Skip to</span>
    </div>
  </div>
  <div class="toggle-data" *ngIf="ShowSkipBox">
    <span class="d-inline-block me-2">If</span>
    <span class="d-inline-block me-2">2 Click to write the question text</span>
    <span class="d-inline-block me-2">Click to write Choice 1</span>
    <span class="d-inline-block me-2">Is Selected</span>
  </div>
</div> -->
