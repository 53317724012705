import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
  OnInit,
} from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { AssessmentDataService } from "../../../app/core/assessment-data.service";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import $ from 'jquery';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { faPen } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: "app-question-section",
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateY(0)", opacity: 0 }),
        animate("500ms", style({ transform: "translateY(0)", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ transform: "translateY(0)", opacity: 1 }),
        animate("500ms", style({ transform: "translateY(0)", opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: "./question-section.component.html",
  styleUrls: ["./question-section.component.scss"],
})
export class QuestionSectionComponent implements AfterViewInit, AfterViewChecked, OnInit {
  public confirmationAlertRef: NgbModalRef;
  fapen = faPen;
  ShowBranchBox: boolean = true;
  ShowSkipBox: boolean = true;

  @Input("uiSelected")
  uiSelected: boolean;

  @Input()
  surveyData: any;

  @Input()
  sections: any;
  @Input("customThemeSettings") customThemeSettings: object;
  @Output("onDoneQuestionsUpdated")
  onDoneQuestionsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output("selectedQuestionChange")
  selectedQuestionChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() pagebreakSelection: EventEmitter<any> = new EventEmitter<any>();

  @Output() onQuestionsMove: EventEmitter<any> = new EventEmitter<any>();

  // answeredQuestions: any[] = [];

  @Output("onSectionDeleted")
  onSectionDeleted = new EventEmitter<any>();

  @Input("editable")
  editable: boolean;

  @Input("sectionsNumber")
  sectionsNumber: number;

  @Input("ques_update_random") set ques_update_random(val: any) {
    val ? (this.ques_random = val) : "";
  }
  ques_random: number;

  @Input("sectionIndex")
  sectionIndex: Number;

  @Input("showQuestionNumber")
  showQuestionNumber: boolean;

  @Input("questions")
  questions: any[];

  @Input("answeredQuestions") answeredQuestions: any[] = [];

  @Input("previewMode")
  previewMode: boolean;

  @Input("isNextButtonClicked")
  isNextButtonClicked: boolean;

  @Input("backClicked")
  backClicked: boolean;

  @Input() pipeTextError:any;
  
  @Output("resetNextButton")
  resetNextButton = new EventEmitter<boolean>();

  @Input("theme")
  theme: string;

  @Input("sectionUrl")
  sectionUrl: string;

  @Input("headerColor")
  headerColor: string;

  @Input("showRequiredAsterisk") showRequiredAsterisk: any;

  @Input("showSectionName") showSectionName: any;

  @Input("section") section: any;

  @Output("onCheckedQuestionsChanged")
  onCheckedQuestionsChanged = new EventEmitter<any>();

  @Output("onEditSectionNameTriggered")
  onEditSectionNameTriggered = new EventEmitter<any>();

  @Input("currentPageIndex") currentPageIndex: any;

  alertContent: any;
  showClose: boolean = false;
  public open: boolean = false;
  @ViewChild("list", { static: false }) listRef: ElementRef;
  @ViewChild("sec", { static: false }) sectionRef: ElementRef;
  questionsCounter: number;
  checkedQuestions: any[] = [];
  editCounter: number = 0;
  subscription = new Subscription();
  constructor(private assessmentDataService: AssessmentDataService, private modalService: NgbModal) {}

  ngOnInit() {
    this.questions
    this.subscription.add(
      this.assessmentDataService.positionUpdateSubject.subscribe((data) => {
        if (data["old_section"] == this.section.url) {
          // debugger
          this.updatePositions(data["old_index"], -1);
        } else if (data["new_section"] == this.section.url) {
          // debugger;
          this.updatePositions(data["new_index"] + 1, 1);
        }
      })
    );
    // if (this.previewMode) {
    //   this.listenToMcqOptionUpdate();
    //   this.startSetUpForDisplayLogic();
    // }
  }

  containsOnlyNumbers(str:string) {
    return /^\d+$/.test(str);
  }

  startSetUpForDisplayLogic() {
    this.questions.forEach((ques: any, index: any) => {
      let isHide: boolean = false;
      if (
        ques &&
        ques.logic &&
        ques.logic.length &&
        ques.logic[0]["conditions"] &&
        ques.logic[0]["conditions"].length
      ) {
        ques.logic.forEach((lgc: any, index2: any) => {
          if (lgc.logic_type == "display") {
            lgc.conditions.forEach((cond: any) => {
              this.getQuestionDestination(cond.question_destination);
            });
          }
        });
      }
    });
  }

  getQuestionDestination(question_destination: any) {
    let isHide: any = false;
    this.questions.forEach((ques: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];

      if (id == question_destination) {
        ques["hide"] = true;
      }
    });
    // if (!isHide) {
    //   ques["hide"] = false;
    // }
  }

  getDestination(question_destination: any) {
    let quesReqd: any;
    this.questions.forEach((ques: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];

      if (id == question_destination) {
        quesReqd = ques;
      }
    });
    return quesReqd;
  }

  listenToMcqOptionUpdate() {
    this.subscription.add(
      this.assessmentDataService.optionChoosen$.subscribe((data: any) => {
        if (data) {
          this.checkDisplayLogic(data);
          this.checkSkipLogic(data);
          this.checkBranchLogic(data);
        }
      })
    );
  }

  checkDisplayLogic(optionInfo: any) {
    let finArr: any = [];

    this.questions.forEach((ques: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];

      if (
        ques &&
        ques.logic &&
        ques.logic.length &&
        ques.logic[0]["conditions"] &&
        ques.logic[0]["conditions"].length
      ) {
        ques.logic.forEach((lgc: any, index2: any) => {
          if (lgc.logic_type == "display") {
            lgc.conditions.forEach((condition: any) => {
              let qArr = ques.url.split("/");
              let id = +qArr[qArr.length - 2];
              condition["question_id_from_locator"] = id;

              if (lgc.conditions.length == 1) {
                let q = this.getDestination(condition.question_destination);
                this.coverForOneConditionOnly(condition, optionInfo, q, false);
              } else {
                let q = this.getDestination(condition.question_destination);
                let ans = this.coverForOneConditionOnly(condition, optionInfo, q, true);
                condition["ans"] = ans;
              }
            });

            let mQ: any;
            if (lgc.conditions.length > 1) {
              let lastVal: any;
              lgc["conditions"].forEach((condition: any, cIndex: any) => {
                mQ = this.getDestination(condition.question_destination);
                if (cIndex > 0) {
                  lastVal =
                    condition["conjuction"] == "AND"
                      ? lastVal && lgc["conditions"][cIndex]["ans"]
                      : lastVal || lgc["conditions"][cIndex]["ans"];
                } else {
                  lastVal = lgc["conditions"][cIndex]["ans"];
                }
              });
              mQ["hide"] = !lastVal;
            }
          }
        });
      }

      // if (
      //   ques &&
      //   ques.logic &&
      //   ques.logic.display_logic &&
      //   ques.logic.display_logic.length &&
      //   ques.logic.display_logic[0]["conditions"] &&
      //   ques.logic.display_logic[0]["conditions"].length
      // ) {
      //   ques.logic.display_logic[0]["conditions"].forEach((condition: any) => {
      //     if (ques.logic.display_logic[0]["conditions"].length == 1)
      //       this.coverForOneConditionOnly(condition, optionInfo, ques, false);
      //     else if (
      //       condition["question_id_from_locator"] == optionInfo["question_id"]
      //     ) {
      //       let ans = this.coverForOneConditionOnly(
      //         condition,
      //         optionInfo,
      //         ques,
      //         true
      //       );
      //       condition["ans"] = ans;
      //     }
      //   });

      //   if (ques.logic.display_logic[0]["conditions"].length > 1) {
      //     let lastVal: any;
      //     ques.logic.display_logic[0]["conditions"].forEach(
      //       (condition: any, cIndex: any) => {
      //         if (cIndex > 0) {
      //           lastVal =
      //             condition["conjuction"] == "AND"
      //               ? lastVal &&
      //                 ques.logic.display_logic[0]["conditions"][cIndex]["ans"]
      //               : lastVal ||
      //                 ques.logic.display_logic[0]["conditions"][cIndex]["ans"];
      //         } else {
      //           lastVal =
      //             ques.logic.display_logic[0]["conditions"][cIndex]["ans"];
      //         }
      //       }
      //     );
      //     ques["hide"] = !lastVal;
      //   }
      // }
    });
  }

  coverForOneConditionOnly(condition: any, optionInfo: any, ques: any, isMultipleConditions: boolean) {
    let ans: boolean = false;
    if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) {
        ques["hide"] = false;
      }
      ans = true;
    } else if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"] &&
      condition["question_choice_locator"] !== optionInfo.id
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] !== optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }
    //equal to
    else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] != condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Is not equal to
    else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] !== condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // Is greater than
    else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] > +condition["operator_value"] : optionInfo["value"] > condition["operator_value"]) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] < +condition["operator_value"]  :  optionInfo["value"] < condition["operator_value"] ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // "Is greater than or equal to"
    else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] >= +condition["operator_value"]  :  optionInfo["value"] >= condition["operator_value"] ) &&
      // +optionInfo["value"] >= +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] < +condition["operator_value"]  :  optionInfo["value"] < condition["operator_value"] ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // "Is less than"
    else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] < +condition["operator_value"]  :  optionInfo["value"] < condition["operator_value"] ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] > +condition["operator_value"]  :  optionInfo["value"] > condition["operator_value"] ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // "Is less than or equal to"
    else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] <= +condition["operator_value"]  :  optionInfo["value"] <= condition["operator_value"] ) &&
      // +optionInfo["value"] <= +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] > +condition["operator_value"]   :  optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = true;
    }

    //Is empty
    else if (
      condition.operator == "Is empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Is not empty
    else if (
      condition.operator == "Is not empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is not empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Contains
    else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Does not contain
    else if (
      condition.operator == "Does not contain" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Does not contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    return ans;
  }
  checkQuesHide(ques: any) {
    if (!ques.hide) {
      return true;
    } else {
      return false;
    }
  }

  checkSkipQuesHide(ques: any) {
    if (ques.hide_via_skip) {
      return false;
    } else {
      return true;
    }
  }

  checkSkipLogic(optionInfo: any) {
    let finArr: any = [];

    this.questions.forEach((ques: any, qIndex: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];

      ques.logic.forEach((lgc: any) => {
        if (lgc.logic_type == "skip") {
          lgc.conditions.forEach((cond: any) => {
            cond["question"] = id;
            this.coverForSkipCondition(cond, optionInfo, ques, qIndex);
          });
        }
      });

      // if (
      //   ques &&
      //   ques.logic &&
      //   ques.logic.skip_logic &&
      //   ques.logic.skip_logic.length
      // ) {
      //   ques.logic.skip_logic.forEach((condition: any) => {
      //     this.coverForSkipCondition(condition, optionInfo, ques, qIndex);
      //   });
      // }
    });
  }

  hideAllQuestionsTillDestination(cQues: any, quesIndex: any, destQuesId: any, hideValue: boolean) {
    // destQuesId = 38429;
    let destFound: boolean = false;
    this.questions.forEach((ques: any, index: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];
      if (index > quesIndex && id !== destQuesId && !destFound) {
        ques["hide_via_skip"] = hideValue;
      } else if (index > quesIndex && id == destQuesId) {
        destFound = true;
      }
    });
  }

  getDestinationQues(condition: any) {
    if (!condition.question_destination && condition.question_destination_type == "end_of_survey") {
      let lastQues = this.questions[this.questions.length - 1];
      let qArr = lastQues.url.split("/");
      let id = +qArr[qArr.length - 2];
      condition.question_destination = id;
    } else if (!condition.question_destination && condition.question_destination_type == "end_of_block") {
      condition.question_destination = this.getSectionId(condition.question);
    }
  }

  getSectionId(currQuesId: any) {
    let currSectionId: any;
    let lastQuesOfSec: any;
    this.questions.forEach((ques: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];
      let secArr = ques.section.split("/");
      let secId = +secArr[secArr.length - 2];
      if (id == currQuesId) {
        currSectionId = secId;
        lastQuesOfSec = currQuesId;
      } else if (currSectionId && secId == currSectionId) {
        lastQuesOfSec = id;
      }
    });
    return lastQuesOfSec;
  }
  coverForSkipCondition(condition: any, optionInfo: any, ques: any, quesIndex: any) {
    let ans: boolean = false;
    this.getDestinationQues(condition);
    if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question"] == optionInfo["question_id"] &&
      condition["question_choice_locator"] !== optionInfo.id
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] !== optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }
    //equal to
    else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] != condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is not equal to
    else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] !== condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // Is greater than
    else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] > +condition["operator_value"]   :  optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] < +condition["operator_value"]   :  optionInfo["value"] < condition["operator_value"]  ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is greater than or equal to"
    else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] >= +condition["operator_value"]   :  optionInfo["value"] >= condition["operator_value"]  ) &&
      // +optionInfo["value"] >= +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] < +condition["operator_value"]   :  optionInfo["value"] < condition["operator_value"]  ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is less than"
    else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] < +condition["operator_value"]   :  optionInfo["value"] < condition["operator_value"]  ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] > +condition["operator_value"]   :  optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is less than or equal to"
    else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] <= +condition["operator_value"]    :  optionInfo["value"] <= condition["operator_value"]  ) &&
      // +optionInfo["value"] <= +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] > +condition["operator_value"]    :  optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    }

    //Is empty
    else if (
      condition.operator == "Is empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is not empty
    else if (
      condition.operator == "Is not empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is not empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Contains
    else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Does not contain
    else if (
      condition.operator == "Does not contain" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Does not contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    return ans;
  }

  checkBranchQuesHide(ques: any) {
    if (ques.hide_via_branch) {
      return false;
    } else {
      return true;
    }
  }

  showSectionDisplayCondition(ques: any){
    let secArr = ques.section.split("/");
    let secId = +secArr[secArr.length - 2];

    let allShow: boolean = false;
    this.questions.forEach((qsn: any, index: any) => {
      let scAr = qsn.section.split("/");
      let scId = +scAr[scAr.length - 2];
      if(scId==secId && !qsn.hide){
        allShow = true;
      }
    });

    return allShow;
  }

  showSection(ques: any) {
    let secArr = ques.section.split("/");
    let secId = +secArr[secArr.length - 2];

    let allShow: boolean = false;
    this.questions.forEach((qsn: any, index: any) => {
      let scAr = qsn.section.split("/");
      let scId = +scAr[scAr.length - 2];
      if (scId == secId && qsn["hide_via_branch"]) {
      } else if (scId == secId && !qsn["hide_via_branch"]) {
        allShow = true;
      }
    });

    return allShow;
  }

  checkBranchLogic(optionInfo: any) {
    this.questions.forEach((ques: any, qIndex: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];

      ques.logic.forEach((lgc: any) => {
        if (lgc.logic_type == "branching") {
          lgc.conditions.forEach((cond: any) => {
            cond["question"] = id;
            this.coverForBranchCondition(cond, optionInfo, ques, qIndex);
          });
        }
      });
    });
  }

  hideAllQuestionsTillDestinationForBranchLogic(cQues: any, quesIndex: any, destQuesId: any, hideValue: boolean) {
    // destQuesId = 38429;
    let destFound: boolean = false;
    this.questions.forEach((ques: any, index: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];
      if (index > quesIndex && id !== destQuesId && !destFound) {
        ques["hide_via_branch"] = hideValue;
      } else if (index > quesIndex && id == destQuesId) {
        destFound = true;
      }
    });
  }

  getDestinationQuesForBranch(condition: any) {
    if (!condition.question_destination && condition.question_destination_type == "end_of_survey") {
      let lastQues = this.questions[this.questions.length - 1];
      let qArr = lastQues.url.split("/");
      let id = +qArr[qArr.length - 2];
      condition.question_destination = id;
    } else if (condition.question_destination_type == "question") {
      condition.question_destination = this.getSectionIdForBranch(condition.section_destination);
    }
  }

  getSectionIdForBranch(secDestId: any) {
    let currSectionId: any;
    let firstQuesOfSec: any;
    this.questions.forEach((ques: any) => {
      let qArr = ques.url.split("/");
      let id = +qArr[qArr.length - 2];
      let secArr = ques.section.split("/");
      let secId = +secArr[qArr.length - 2];
      if (secId == secDestId) {
        currSectionId = secId;
        firstQuesOfSec = id;
      }
    });
    return firstQuesOfSec;
  }

  coverForBranchCondition(condition: any, optionInfo: any, ques: any, quesIndex: any) {
    let ans: boolean = false;
    this.getDestinationQuesForBranch(condition);
    if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question"] == optionInfo["question_id"] &&
      condition["question_choice_locator"] !== optionInfo.id
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] !== optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }
    //equal to
    else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] != condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is not equal to
    else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // Is greater than
    else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?   +optionInfo["value"] > +condition["operator_value"]   :  optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] < +condition["operator_value"]  :  optionInfo["value"] < condition["operator_value"]  ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is greater than or equal to"
    else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] >= +condition["operator_value"]  : optionInfo["value"] >= condition["operator_value"] ) &&
      // +optionInfo["value"] >= +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] < +condition["operator_value"]   : optionInfo["value"] < condition["operator_value"]  ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is less than"
    else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] < +condition["operator_value"]   : optionInfo["value"] < condition["operator_value"]  ) &&
      // +optionInfo["value"] < +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] > +condition["operator_value"]   : optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is less than or equal to"
    else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] <= +condition["operator_value"]   : optionInfo["value"] <= condition["operator_value"]  ) &&
      // +optionInfo["value"] <= +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"]) ?  +optionInfo["value"] > +condition["operator_value"]   : optionInfo["value"] > condition["operator_value"]  ) &&
      // +optionInfo["value"] > +condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    }

    //Is empty
    else if (
      condition.operator == "Is empty" &&
      !optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is empty" &&
      optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is not empty
    else if (
      condition.operator == "Is not empty" &&
      optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is not empty" &&
      !optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Contains
    else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Does not contain
    else if (
      condition.operator == "Does not contain" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Does not contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    return ans;
  }

  updatePositions(_index: any, type: any) {
    for (let index = _index; index < this.questions.length; index++) {
      const element = this.questions[index];
      element["position"] = element["position"] + type;
    }
  }

  ngAfterViewChecked() {
    if (this.sectionRef != undefined && this.listRef != undefined) {
      this._initSectionQuestions();
    }
  }

  doResetNextButton($event: boolean): void {
    this.resetNextButton.emit($event);
  }

  drop(event: CdkDragDrop<string[]>){
    if(event.previousContainer.id != event.container.id){
      transferArrayItem(
        this.sections[parseInt(event.previousContainer.id.split('section_')[1])].questions,
        this.sections[parseInt(event.container.id.split('section_')[1])].questions,
        event.previousIndex,
        event.currentIndex);
        var obj={
          out_section : {
            currentId : parseInt(event.container.id.split('section_')[1]),
            previousId : parseInt(event.previousContainer.id.split('section_')[1]),
            newIndex : event.currentIndex,
            previousIndex : event.previousIndex
          }
        }
        this.onQuestionsMove.emit(obj);
    }else{
      moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
      this.emitQuestionUpdate(event.currentIndex,event.previousIndex);
    }
  }

  // sortablejsOptions: SortablejsOptions;
  sortablejsOptions: any = {
    group: {
      name: "questions",
    },
    scroll: true,
    scrollFn: () => {
      debugger;
    },
    onStart: function () {
      // executes when the element drag starts.
    },
    onMove: function () {
      // executes when the element drag starts.

      // if (event.willInsertAfter)
      //   //down
      //   setTimeout(() => {
      //     $("html, body").animate(
      //       {
      //         scrollTop: event.relatedRect.top - 50,
      //       },
      //       400
      //     );
      //   });

      // //down
      // if (!event.willInsertAfter)
      //   setTimeout(() => {
      //     $("html, body").animate(
      //       {
      //         scrollTop: event.relatedRect.top + 50,
      //       },
      //       400
      //     );
      //   });
      return true;
    },
    onEnd: function () {
      // executes when the event ends.
      // if (event.oldIndex < event.NewIndex)
      //   //down
      //   setTimeout(() => {
      //     $("html, body").animate(
      //       {
      //         scrollTop: event.relatedRect.top + 50,
      //       },
      //       400
      //     );
      //   });
      // if (event.oldIndex > event.NewIndex)
      //   //up
      //   setTimeout(() => {
      //     $("html, body").animate(
      //       {
      //         scrollTop: event.relatedRect.top - 50,
      //       },
      //       400
      //     );
      //   });
    },
    onRemove: (evt) => {
      this.onQuestionsMove.emit({ out_section: evt });
      // debugger
      // executes when the element is been removed
    },
    onUpdate: (event: any) => {
      // executes when the element's position is updated within list
      // let position_arr: any = [];
      // let new_question_index = this.questions[event.newIndex];
      // let old_question_index = this.questions[event.oldIndex];

      // if (event.newIndex != event.oldIndex) {
      //   if (new_question_index.obj_type == "pagebrake") {
      //     if (event.newIndex != 0) {
      //       new_question_index.position = this.questions[event.newIndex - 1] && this.questions[event.newIndex - 1].position
      //     }
      //     this.emitQuestionUpdate(event);
      //   }else{
      // if (event) this.emitQuestionUpdate(event);
      // if (event.newIndex > event.oldIndex) { // question moved downwards
      //   for (let index = event.oldIndex; index <= event.newIndex; index++) {
      //     const element = this.questions[index];
      //     position_arr.push(element.position);
      //   }
      //   setTimeout(()=>{
      //     let arr = [position_arr[position_arr.length - 1]];
      //     position_arr.splice(position_arr.length-1, 1);
      //     let arr2 = [...arr,...position_arr];

      //     let arr2_index = 0;
      //     for (let index = event.oldIndex; index <= event.newIndex; index++) {
      //       const element = this.questions[index];
      //       if (element.obj_type == "pagebrake") {
      //         element.position = this.questions[index -1] && this.questions[index-1].position;
      //       }else{
      //         element.position = arr2[arr2_index];
      //       }
      //       arr2_index++;
      //     }

      //     setTimeout(() => {
      //       this.emitQuestionUpdate(event);
      //     })

      //     // this.questions.forEach((ques)=>{
      //       // position_arr.push(ques.position);

      //     // });
      //   })
      // } else { // question moved upward
      //   for (let index = event.newIndex; index <= event.oldIndex; index++) {
      //     const element = this.questions[index];
      //     position_arr.push(element.position);
      //   }

      //   setTimeout(()=>{
      //     let arr = [position_arr[0]];
      //     position_arr.splice(0, 1);
      //     let arr2 = [...position_arr,...arr];

      //     // debugger;

      //     let arr2_index = 0;
      //     let is_first = true;
      //     for (let index = event.newIndex; index <= event.oldIndex; index++) {
      //       const element = this.questions[index];
      //       if (element.obj_type == "pagebrake") {
      //         element.position = this.questions[index -1] && this.questions[index-1].position;
      //       }else{
      //         element.position = is_first? (this.questions[index -1] && this.questions[index-1].position == arr2[arr2_index]? this.questions[index-1].position + 1 : arr2[arr2_index] ) : arr2[arr2_index];
      //       }
      //       is_first = false;
      //       arr2_index++;
      //     }

      //     setTimeout(() => {
      //       this.emitQuestionUpdate(event);
      //     })

      //     // this.questions.forEach((ques)=>{
      //       // position_arr.push(ques.position);

      //     // });
      //   })

      // }
      //   }
      // }

      // let position_1 = this.questions[event.oldIndex]['position'];
      // let position_2 = this.questions[event.newIndex]['position'];

      // this.questions[event.oldIndex]['position'] = position_2;
      // this.questions[event.newIndex]['position'] = position_1;

      // let questionMoved = this.questions[event.newIndex];
      // let obj = {};

      // if (questionMoved['obj_type'] == "pagebrake") {
      //   obj = {
      //     data: {
      //       boxHeight: null,
      //       obj_type: "pagebrake",
      //       position: event.newIndex,
      //       section: questionMoved.section,
      //       section_pos: questionMoved.section_pos,
      //     },
      //     url: questionMoved.url
      //   }
      //   this.onQuestionsMove.emit(obj);
      // }else{
      //   obj = {
      //     data: {
      //       entity_type: 'question',
      //       entity_url: questionMoved.url,
      //       new_pos: questionMoved.position,
      //       new_section: questionMoved.section,
      //       new_section_position: questionMoved.section_pos,
      //       old_section: questionMoved.section,
      //       old_section_position: questionMoved.section_pos
      //     }
      //   }
      //   this.onQuestionsMove.emit(obj);
      // }
      // this.selectedQuestionChange.emit(this.questions[event.oldIndex]);
      // this.selectedQuestionChange.emit(this.questions[event.newIndex]);
      // for(var k = 0; k < this.checkedQuestions[0].question_options.length; k++){
      // this.checkedQuestions[0]['question_options'][k]['position'] = k+1;
      // }
    },
  };

  ngAfterViewInit() {
    this._initSectionQuestions();
    // this.LightenDarkenColor(25);
    // this.convertHex(25);
  }

  public editSectionName(): void {
    this.onEditSectionNameTriggered.emit(this.section);
  }

  public onCheckedQuestionChanged(ev: any, question: any, index: any): void {
    question["index"] = index;
    if (ev.target.checked == true) {
      this.questions[this.questions.indexOf(question)].uiSelected = true;
      this.onCheckedQuestionsChanged.emit({ action: "add", question });
    } else {
      this.questions[this.questions.indexOf(question)].uiSelected = false;
      this.onCheckedQuestionsChanged.emit({ action: "remove", question });
    }
  }

  public toggleSection(): void {
    this.open = !this.open;
    this.pagebreakSelection.emit(Math.random());
  }

  public onMouseEnter(): void {
    this.showClose = true;
  }

  public onMouseLeave(): void {
    this.showClose = false;
  }

  public selectedQuestionChanged(question: any,qtElementNative: any,
    section: any, index: any): void {
    if (!this.previewMode) {
      question["index"] = index;
      if (question.obj_type == "pagebrake") {
        this.pagebreakSelection.emit(Math.random());
      } else {
        this._initSectionQuestions();
        this.selectedQuestionChange.emit(question);
        this.assessmentDataService.sectionNameUpdated$.next(true);
      }
    }
  }

  public removePageBreak(index: number): void {
    this.assessmentDataService.setLoader({ bool: true });
    this.pagebreakSelection.emit({
      removed: Math.random(),
      url: this.questions[index].url,
      ques_index: index,
    });
  }

  public onQuestionDone(questionAnswered: any): void {
    if (this.previewMode) {
      // we have to check if the question is already answered
      let fetchedQuestion = this.answeredQuestions.filter((question) => {
        return question.url == questionAnswered.url;
      });
      if (fetchedQuestion.length > 0) {
        // answered question already exists so we have to update its value
        let fetchedQuestionIndex = this.answeredQuestions.indexOf(fetchedQuestion[0]);
        // if (questionAnswered['answered'] == true) {
        this.answeredQuestions[fetchedQuestionIndex] = questionAnswered;
        // }
        // else{
        // questionAnswered['answered'] = true;
        // this.answeredQuestions.splice(fetchedQuestionIndex,1);
        // }
      } else {
        if (questionAnswered["answered"] == true) {
          this.answeredQuestions.push(questionAnswered);
        }
      }
      let _obj = {
        answered_ques: this.answeredQuestions,
        _ques: questionAnswered,
      };
      // '_ques': questionAnswered['answered'] == true? questionAnswered : null
      this.onDoneQuestionsUpdated.emit(_obj);
    }
  }

  public openDeleteSectionAlert(alert: any): void {
    this.alertContent = {
      title: "Delete Section",
      message:
        "Are you sure you want to delete this section? Deleting this section will permanently delete all questions associated with it.",
      positiveAction: "deleteSection",
      data: { sectionUrl: this.sectionUrl },
    };

    this.confirmationAlertRef = this.modalService.open(alert, {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "lg",
      windowClass: "modal-holder",
    });

    this.confirmationAlertRef.result.then(
      () => {},
      (reason: string) => {
        if (reason === "sectionDeleted") {
          this.onSectionDeleted.emit(this.sectionUrl);
        }
      }
    );
  }

  public isPreivewPage() {
    return window.location.href.includes("preview") || window.location.href.includes("resp");
  }

  private _initSectionQuestions(): void {
    if (this.listRef && this.listRef.nativeElement) {
      let childs: Array<HTMLTableSectionElement> = Array.from(this.listRef.nativeElement.children);
      this.questions.forEach((q: any, index: number) => {
        q.pagePosition = {
          top:
            this.sectionRef.nativeElement.parentNode.offsetTop +
            (childs && childs[index] ? childs[index].offsetTop : 0),
        };
      });
    }

    if(this.isPreivewPage()){
      if(this.questions[0]){
        let firstQuestionNumbering = this.questions[0].numbering;
        $("#" + firstQuestionNumbering).css("opacity", 1);
      }
    }
  }

  emitQuestionUpdate(newIndex,oldIndex) {
    let questionMoved = this.questions[newIndex];
    let obj = {};

    obj = {
      data: {
        entity_type: questionMoved["obj_type"],
        entity_url: questionMoved.url,
        new_pos: this.questions[newIndex - 1] ? this.questions[newIndex - 1].position + 1 : 1,
        new_section: questionMoved.section,
        new_section_position: questionMoved.section_pos,
        old_section: questionMoved.section,
        old_section_position: questionMoved.section_pos,
      },
    };
    if (oldIndex != newIndex) {
      if (oldIndex < newIndex) {
        // top to bottom
        obj["data"]["new_pos"] = this.questions[newIndex - 1]
          ? this.questions[newIndex - 1].position
          : this.questions[newIndex + 1].position;
      } else {
        // bottom to top
        obj["data"]["new_pos"] = this.questions[newIndex + 1]
          ? this.questions[newIndex + 1].position
          : this.questions[newIndex - 1].position;
      }
      this.onQuestionsMove.emit(obj);
    }
    // }
  }

  public getQuestionNumbering(question: any): number {
    let onlyQuestionsArray = this.questions.filter((q) => {
      return q.obj_type !== "pagebrake";
    });
    return onlyQuestionsArray.indexOf(question) + 1;
  }

  convertHex() {
    let hex = JSON.parse(JSON.stringify(this.headerColor));
    let opacity = 25;
    hex = hex.replace("#", "");
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    let result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
    return result;
  }

  LightenDarkenColor(amt: any) {
    let col = JSON.parse(JSON.stringify(this.headerColor));
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    // return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  }

  // getEditableAccess(question: any) {

  //   setTimeout(()=>{
  //     if(!this.editable && this.backClicked) {
  //       return true;
  //     }else {
  //       return false;
  //     }
  //   })
  // }

  onOptionSelect() {
    this.questions.forEach((question: any, index: any) => {
      if (index == 1) {
        question["hide"] = true;
        let allQues = [...this.questions];
        this.questions = [];
        this.questions = allQues;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
