<div
  id="{{numbering}}"
  [attr.ques_id]="question._id"
  class="question-container print"
  [ngStyle]="{'border-color': validationError.length > 0 ? 'red' : 'black',
  'paddingTop':   previewMode ? customThemeSettings['content_padding_from_top'] ? customThemeSettings['content_padding_from_top'] +'px' :checkForZero(customThemeSettings['content_padding_from_top'],'0px',''): '' ,
  'paddingBottom': previewMode ? customThemeSettings['content_padding_from_bottom'] ? customThemeSettings['content_padding_from_bottom'] +'px' : checkForZero(customThemeSettings['content_padding_from_bottom'],'0px',''):'',
  'paddingLeft':  previewMode? customThemeSettings['content_padding_from_left'] ? customThemeSettings['content_padding_from_left'] +'px' :checkForZero(customThemeSettings['content_padding_from_left'],'0px',''): '',
  'paddingRight':  previewMode ? customThemeSettings['content_padding_from_right'] ? customThemeSettings['content_padding_from_right'] +'px' :checkForZero(customThemeSettings['content_padding_from_right'],'0px',''): '',
   'border' : from ? 'none' :'' }"
  [ngClass]="{ selected: question.uiSelected && !previewMode,'non-editable': (editable && question.answered && backClicked), 'previewMode' : previewMode ? true : false,'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode, 'invalid-answer': question.customValidError }"
>
  <div
    class="error_container"
    *ngIf="validationError !== ''"
    style="text-align: center;"
  >
    <p style="color: red;">{{ validationError }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && (question.error.errorType == 'required' || question.error.errorType == 'requested')"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        <ng-container>
          <app-skip-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitSkipLogic)="skipLogicUpdated($event)"
          ></app-skip-logic-view>
        </ng-container>
        <ng-container>
          <app-branch-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitBranchLogic)="branchLogicUpdated($event)"
          ></app-branch-logic-view>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!-- <div *ngIf="question.error !== undefined && question.error.errorType === 'requested'" style="text-align: center;">
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div> -->

  <div class="quest" [ngStyle]="{'display':from ? 'flex':''}">
    <h3
      *ngIf="!from  && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
      'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>
    <h3
      *ngIf="!from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [ngStyle]="{
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>
    <h6
      *ngIf="from && showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
      'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
      fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h6>

    <h3
      *ngIf="from  && !(showRequiredAsterisk && question.validation_option.is_validation && question.validation_option.validation == 1)"
      [ngStyle]="{'margin-right':'1%','margin-top':'0.11%',
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :''}"
      [ngClass]="{'wth-qnum' : !(showQuestionNumber && previewMode) && previewMode}"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>
    <h6
      [ngStyle]="{color:previewMode && customThemeSettings &&customThemeSettings['question_font_color'] ? customThemeSettings['question_font_color'] :previewMode && customThemeSettings?'#a5a5a5':'',
    paddingBottom :  previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']  ? customThemeSettings['padding_btw_question_n_options'] + 'px' :'',
    'fontSize': previewMode ? customThemeSettings['question_font_size'] ? customThemeSettings['question_font_size'] +'px' : checkForZero(customThemeSettings['question_font_size'],'0px','14px'):'',


    
    
    fontFamily :  previewMode && customThemeSettings && customThemeSettings['question_font_family'] ? customThemeSettings['question_font_family'] :'',
    fontWeight :  previewMode && customThemeSettings && customThemeSettings['question_font_weight'] ? customThemeSettings['question_font_weight'] :'',
    width:!question.question_tooltip?'100%':''}"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="!mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml "></div>
    </ng-template>
    <i
      (click)="openModal($event,tooltipModalQuestion);$event.stopPropagation();"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{marginTop: '0.2%'}"
      *ngIf="this.mobileView && question.question_tooltip"
    ></i>
  </div>
  <!-- <ng-container *ngIf="!previewMode">
    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      *ngIf="displayLogic.length"
    >
      <li
        class="nav-item"
        *ngIf="displayLogic.length"
        (click)="displayLogicClickedEvent(true)"
      >
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          ><i class="fas fa-question mr-1"></i> Response Logic</a
        >
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{'active': displayLogicClicked }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <app-display-logic-view
          [currentQuestion]="question"
          [allQuestions]="allQuestions"
          [surveyData]="surveyData"
          (emitDisplayLogic)="displayLogicUpdated($event)"
        ></app-display-logic-view>
      </div>
    </div>
  </ng-container> -->
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml "></div>
  </ng-template>
  <div class="list-item">
    <!-- {{question.text_type}} -->
    <!-- [ngStyle]="{ height: question.textareaHeight }" -->

    <ng-container
      *ngIf="!question.custom_validation?.is_custom_validation, else custom_validation"
    >
      <textarea
        (keyup)="onAnswerChange($event)"
        (input)="onAnswerChange($event)"
        [(ngModel)]="answer"
        style="height: auto !important;"
        [ngClass]="{'form-item': !question.custom_validation}"
        class="form-control"
        [rows]="question.text_type == 3? 4 : question.text_type"
      ></textarea>
    </ng-container>
    <ng-template #custom_validation>
      <input
        *ngIf="question.custom_validation.validation_type == 'email' "
        [(ngModel)]="answer"
        (keyup)="onAnswerChange($event)"
        (input)="onAnswerChange($event)"
        style="height: auto !important;"
        class="form-control"
        type="text"
      />
      <input
        *ngIf="question.custom_validation.validation_type == 'number' "
        (keydown)="checkValidKey($event)"
        [(ngModel)]="answer"
        (keyup)="onAnswerChange($event)"
        (input)="onAnswerChange($event)"
        style="height: auto !important;"
        class="form-control"
        type="text"
      />

      <div
        class="form-group"
        style="display: flex;"
        *ngIf="question.custom_validation.validation_type == 'phone' "
      >
        <select
          style="width: 50%;"
          (ngModelChange)="onOptionSelected($event)"
          [(ngModel)]="question['country_code']"
          class="form-control"
        >
          <option
            *ngFor="let item of countryList"
            [value]="item.country_code"
            [selected]="this.currentLocation === item.country_name"
            >{{item.country_name}} ({{item.country_code}})</option
          >
        </select>
        <input
          style="height: auto !important;"
          class="form-control inputForCode"
          type="text"
          readonly
          disabled="true"
          [(ngModel)]="dialCode"
          placeholder="dial-code"
        />
        <input
          (keydown)="checkValidKey($event)"
          [(ngModel)]="answer"
          #phoneText
          style="height: auto !important;"
          class="form-control"
          type="tel"
          placeholder="Enter phone number"
        />
      </div>
      <div
        *ngIf="question.custom_validation.validation_type == 'dob' "
        class="form-group enter-dropbox"
      >
        <div
          class="input-group"
          style="
            position: static;
            background-color: #eef2f5;
            margin-bottom: 0;
            margin-top: 0;
          "
        >
          <input
            [(ngModel)]="dobSelected"
            (ngModelChange)="onChangeDOB($event)"
            (click)="!previewMode ?'':d.toggle() "
            class="form-control"
            name="dp"
            ngbDatepicker
            [readonly]="true"
            #d="ngbDatepicker"
            [positionTarget]="calendar"
          
            [readonly]="true"
            [placement]="'bottom-end'"
            id="date_picker_format"
          />
          <span class="input-group-addon"
            ><button
            #calendar
              class="btn calendar fa fa-calendar"
              (click)="!previewMode ? '':d.toggle() "
              type="button"
            ></button
          ></span>
        </div>
      </div>
      <input
        *ngIf="question.custom_validation.validation_type == 'address' "
        (keyup)="question.answer = '';"
        (blur)="checkInputVal($event)"
        [(ngModel)]="answer"
        placeholder="search for location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        style="height: auto !important;"
        class="form-control"
        type="text"
        #search
      />
      <!-- <input
        *ngIf = "question.custom_validation.validation_type == 'dob' "
        [(ngModel)]="answer"
        (change)="getDateSelected($event)"
        style = "height: auto !important;"
        class="form-control"
        type="date"
        [max]="today"
      /> -->

      <!-- <small class="text-danger">This domain is not allowed to fill response for this survey.</small> -->
      <small
        *ngIf="question.customValidError"
        class="text-danger"
        style="word-break: break-word;"
        >{{ question['custom_validation']['msg']
        ?question['custom_validation']['msg']
        :question.customValidError}}</small
      >
    </ng-template>

    <div *ngIf="previewMode && question['answered'] && customThemeSettings['theme_layout'] == 'focus'">
      <button
      [id]="'okbt'+question.numbering"
              (click)="onOkClick()"
              class="btn btn-md"
              style="display: inline;margin-top: 20px; color: white; background: #54902b;"
            >OK
            </button>
    </div>    
  </div>
</div>
