<!-- <span>{{customThemeSettings | json}}</span> -->
<div
  [attr.ques_id]="question._id"
  class="question-container print"
  id="{{ numbering }}"
  [ngClass]="{
    selected: question.uiSelected && !previewMode,
    'invalid-question': customQuestionError != '',
    'non-editable': editable && question.answered && backClicked,
    previewMode: previewMode ? true : false,
    'wth-out-qnum' : !(showQuestionNumber && previewMode) && previewMode
  }"
  [ngStyle]="{
    paddingTop: previewMode
      ? customThemeSettings['content_padding_from_top']
        ? customThemeSettings['content_padding_from_top'] + 'px'
        : checkForZero(customThemeSettings['content_padding_from_top'], '0px', '')
      : '',
    paddingBottom: previewMode
      ? customThemeSettings['content_padding_from_bottom']
        ? customThemeSettings['content_padding_from_bottom'] + 'px'
        : checkForZero(customThemeSettings['content_padding_from_bottom'], '0px', '')
      : '',
    paddingLeft: previewMode
      ? customThemeSettings['content_padding_from_left']
        ? customThemeSettings['content_padding_from_left'] + 'px'
        : checkForZero(customThemeSettings['content_padding_from_left'], '0px', '')
      : '',
    paddingRight: previewMode
      ? customThemeSettings['content_padding_from_right']
        ? customThemeSettings['content_padding_from_right'] + 'px'
        : checkForZero(customThemeSettings['content_padding_from_right'], '0px', '')
      : '',
    border: from ? 'none' : ''
  }"
>
  <div
    class="error_container"
    *ngIf="customQuestionError != ''"
    style="text-align: center;"
  >
    <p style="color: red;">{{ customQuestionError }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'required'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>

  <div
    class="error_container"
    *ngIf="question.error !== undefined && question.error.errorType === 'requested'"
    style="text-align: center;"
  >
    <p style="color: red;">{{ question.error.errorMsg }}</p>
  </div>
  <div class="pl-4 ms-1">
    <ng-container *ngIf="!previewMode">
      <ng-container>
        <ng-container>
          <app-display-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitDisplayLogic)="displayLogicUpdated($event)"
          ></app-display-logic-view>
        </ng-container>
        <ng-container>
          <app-skip-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitSkipLogic)="skipLogicUpdated($event)"
          ></app-skip-logic-view>
        </ng-container>
        <ng-container>
          <app-branch-logic-view
            [currentQuestion]="question"
            [allQuestions]="allQuestions"
            [surveyData]="surveyData"
            (emitBranchLogic)="branchLogicUpdated($event)"
          ></app-branch-logic-view>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="quest" [ngStyle]="{ display: from ? 'flex' : '' }">
    <h3
      *ngIf="
        !from &&
        showRequiredAsterisk &&
        question.validation_option.is_validation &&
        question.validation_option.validation == 1
      "
      [ngStyle]="{
        fontSize: previewMode
          ? customThemeSettings['question_font_size']
            ? customThemeSettings['question_font_size'] + 'px'
            : checkForZero(customThemeSettings['question_font_size'], '0px', '14px')
          : '',
        fontWeight:
          previewMode && customThemeSettings && customThemeSettings['question_font_weight']
            ? customThemeSettings['question_font_weight']
            : ''
      }"
      [ngClass]="{ 'wth-qnum': !(showQuestionNumber && previewMode) && previewMode }"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h3>

    <h3
      *ngIf="
        !from &&
        !(
          showRequiredAsterisk &&
          question.validation_option.is_validation &&
          question.validation_option.validation == 1
        )
      "
      [ngStyle]="{
        fontSize: previewMode
          ? customThemeSettings['question_font_size']
            ? customThemeSettings['question_font_size'] + 'px'
            : checkForZero(customThemeSettings['question_font_size'], '0px', '14px')
          : '',
        fontWeight:
          previewMode && customThemeSettings && customThemeSettings['question_font_weight']
            ? customThemeSettings['question_font_weight']
            : ''
      }"
      [ngClass]="{ 'wth-qnum': !(showQuestionNumber && previewMode) && previewMode }"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>
    <h6
      *ngIf="
        from &&
        showRequiredAsterisk &&
        question.validation_option.is_validation &&
        question.validation_option.validation == 1
      "
      [ngStyle]="{
        'margin-right': '1%',
        'margin-top': '0.11%',
        fontSize: previewMode
          ? customThemeSettings['question_font_size']
            ? customThemeSettings['question_font_size'] + 'px'
            : checkForZero(customThemeSettings['question_font_size'], '0px', '14px')
          : '',
        fontWeight:
          previewMode && customThemeSettings && customThemeSettings['question_font_weight']
            ? customThemeSettings['question_font_weight']
            : ''
      }"
      [ngClass]="{ 'wth-qnum': !(showQuestionNumber && previewMode) && previewMode }"
      [innerHtml]="getQuestionNumbering(numbering)"
    ></h6>

    <h3
      *ngIf="
        from &&
        !(
          showRequiredAsterisk &&
          question.validation_option.is_validation &&
          question.validation_option.validation == 1
        )
      "
      [ngStyle]="{
        'margin-right': '1%',
        'margin-top': '0.11%',
        fontSize: previewMode
          ? customThemeSettings['question_font_size']
            ? customThemeSettings['question_font_size'] + 'px'
            : checkForZero(customThemeSettings['question_font_size'], '0px', '14px')
          : '',
        fontWeight:
          previewMode && customThemeSettings && customThemeSettings['question_font_weight']
            ? customThemeSettings['question_font_weight']
            : ''
      }"
      [ngClass]="{ 'wth-qnum': !(showQuestionNumber && previewMode) && previewMode }"
      [innerHtml]="getQuestionNumberingWithoutAsterick(numbering)"
    ></h3>
    <h6
      [ngStyle]="{
        color:
          previewMode && customThemeSettings && customThemeSettings['question_font_color']
            ? customThemeSettings['question_font_color']
            : previewMode && customThemeSettings
            ? '#a5a5a5'
            : '',
        paddingBottom:
          previewMode && customThemeSettings && customThemeSettings['padding_btw_question_n_options']
            ? customThemeSettings['padding_btw_question_n_options'] + 'px'
            : '',

        fontSize: previewMode
          ? customThemeSettings['question_font_size']
            ? customThemeSettings['question_font_size'] + 'px'
            : checkForZero(customThemeSettings['question_font_size'], '0px', '14px')
          : '',

        fontFamily:
          previewMode && customThemeSettings && customThemeSettings['question_font_family']
            ? customThemeSettings['question_font_family']
            : '',
        fontWeight:
          previewMode && customThemeSettings && customThemeSettings['question_font_weight']
            ? customThemeSettings['question_font_weight']
            : '',
        width: !question.question_tooltip ? '100%' : ''
      }"
      [innerHtml]="question.text | safeHtml"
    ></h6>
    <i
      (click)="tooltipClick($event)"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{ marginTop: '0.2%' }"
      *ngIf="!this.mobileView && question.question_tooltip"
      placement="auto"
      [ngbTooltip]="UploadQuestiontipContent"
    ></i>
    <i
      (click)="openModal($event, tooltipModalQuestion); $event.stopPropagation()"
      class="svg hint-icon"
      container="body"
      [ngStyle]="{ marginTop: '0.2%' }"
      *ngIf="this.mobileView && question.question_tooltip"
    ></i>
    <ng-template #UploadQuestiontipContent>
      <div [innerHTML]="question.question_tooltip | safeHtml"></div>
    </ng-template>
  </div>
  <!-- .............. -->
  <ng-template #tooltipModalQuestion let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="question.question_tooltip | safeHtml"></div>
  </ng-template>

  <!-- --------------------------- -->
  <ng-container [ngSwitch]="question.option_type">
    <ng-container *ngIf="question.autoscaling_settings.scale">
      <form
        class="multichoice-box"
        *ngSwitchCase="1"
        [ngClass]="{ 'form-label-above': question.option_position_type == 1 }"
      >
        <div
          class="list-item form-group overflow-auto"
          [ngClass]="{ forColumns: question.option_position_type == 3 }"
        >
          <!-- [ngStyle]="{'width':question.option_position_type == 3 ?  'calc('+100/question.total_columns+'% - 5px)' : '', 'margin-right': question.option_position_type == 3 ? '5px' : ''}" -->
          <div
            class="list-container"
            [ngStyle]="{
              width: question.option_position_type == 3 ? 'calc(' + 100 / question.total_columns + '% - 5px)' : '',
              'margin-right': question.option_position_type == 3 ? '5px' : ''
            }"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngFor="let option of question.question_options; let i = index; let first = first; let last = last"
            (click)="checkRelatedRadio(i, $event)"
            [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [style.color]="
              previewMode
                ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <div class="item radio-item">
              <input
                (change)="radioOptionChecked($event, option)"
                type="radio"
                [checked]="checkIfChecked(option['url'])"
                class="fsop-radio"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
                id="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
              />
              <label
                *ngIf="first || last"
                class="form-check-label"
                for="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
              >
                <span
                  class="opt-txt"
                  [ngStyle]="{
                    fontSize: previewMode
                      ? customThemeSettings['options_font_size']
                        ? customThemeSettings['options_font_size'] + 'px'
                        : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                      : '',
                    fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                    fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                  }"
                  [innerHTML]="option.text"
                ></span>
                <ng-container
                  [ngTemplateOutlet]="tooltipTemplate"
                  [ngTemplateOutletContext]="{ option: option }"
                >
                </ng-container>
              </label>
            </div>
          </div>
          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.is_comment_box && previewMode"
            (click)="checkRelatedRadio(question.question_options.length, $event)"
            [ngClass]="checkIfChecked('', 'is_other') ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfChecked('', 'is_other') && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                : ''
            "
            [style.background]="
              checkIfChecked('', 'is_other') && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
          >
            <div class="item no-empty">
              <input
                (change)="radioOptionChecked($event, 'other_text_box')"
                type="radio"
                [checked]="checkIfChecked('', 'is_other')"
                class="fsop-radio"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
                id="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
              />
              <p>
                <label
                  class="form-check-label"
                  for="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
                  [innerHTML]="'Other'"
                >
                  <span
                    class="opt-txt"
                    [ngStyle]="{
                      fontSize: previewMode
                        ? customThemeSettings['options_font_size']
                          ? customThemeSettings['options_font_size'] + 'px'
                          : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                        : '',
                      fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                      fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                    }"
                    [innerHTML]="'Other'"
                  ></span>
                </label>
                <ng-container
                  [ngTemplateOutlet]="tooltipTemplateOther"
                  [ngTemplateOutletContext]="{ option: question }"
                >
                </ng-container>
              </p>
              <i
                (click)="tooltipClick($event)"
                class="fa fa-question"
                *ngIf="option.option_tooltip"
                title="{{ option.option_tooltip }}"
              ></i>
            </div>
          </div>
          <div
            class="item no-empty"
            *ngIf="
              question.validation_option.is_comment_box &&
              previewMode &&
              showOtherTextBox &&
              question.answer.other != undefined
            "
          >
            <input
              style="min-height: auto !important;"
              class="form-control"
              [attr.id]="'other_' + question.id"
              [(ngModel)]="question.answer.other"
              (ngModelChange)="emitRadioOtherTextboxValue($event)"
              [ngModelOptions]="{ standalone: true }"
              type="text"
            />
          </div>

          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.to_observe && previewMode"
            (click)="changeRelatedChecked($event)"
            [ngClass]="checkIfNooSelected() ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfNooSelected() && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfNooSelected() && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <div class="item no-empty">
              <input
                (change)="radioOptionChecked($event, 'noo')"
                type="radio"
                [checked]="checkIfNooSelected()"
                class="fsop-radio"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_noo_{{ type_name }}"
                id="answer_{{ question.id }}_noo_{{ type_name }}"
              />
              <label
                for="answer_{{ question.id }}_noo_{{ type_name }}"
                class="form-check-label"
              >
                <span
                  class="opt-txt"
                  [ngStyle]="{
                    fontSize: previewMode
                      ? customThemeSettings['options_font_size']
                        ? customThemeSettings['options_font_size'] + 'px'
                        : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                      : '',
                    fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                    fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                  }"
                  [innerHTML]="previewMode ? 'No Opportunity to Observe' : 'No opportunity to observe'"
                ></span>
              </label>
              <i
                (click)="tooltipClick($event)"
                class="fa fa-question"
                *ngIf="option?.option_tooltip"
                title="{{ option?.option_tooltip }}"
              ></i>
            </div>
          </div>
        </div>
      </form>
      <!-- change here -->
      <form
        *ngSwitchCase="2"
        class="multichoice-box"
        [ngClass]="{ 'form-label-above': question.option_position_type == 2 }"
      >
        <div
          class="form-group list-item overflow-auto"
          [ngClass]="{ forColumns: question.option_position_type == 3 }"
        >
          <div
            [ngStyle]="{
              width: question.option_position_type == 3 ? 'calc(' + 100 / question.total_columns + '% - 5px)' : '',
              'margin-right': question.option_position_type == 3 ? '5px' : ''
            }"
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngFor="let option of question.question_options; let i = index; let first = first; let last = last"
            (click)="checkRelatedRadio(i, $event)"
            [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [style.color]="
              previewMode
                ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
          >
            <div class="form-check item">
              <input
                (change)="checkboxOptionChecked($event, option)"
                type="checkbox"
                [checked]="checkIfChecked(option['url'])"
                class="fsop-checkbox"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
                id="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
              />
              <p
                *ngIf="first || last"
                style="margin-left: 12px;"
                class="form-check-label"
                [ngStyle]="{
                  fontSize: previewMode
                    ? customThemeSettings['options_font_size']
                      ? customThemeSettings['options_font_size'] + 'px'
                      : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                    : '',
                  fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                  fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                }"
                [style.display]="option.option_tooltip ? 'inline' : ''"
                [innerHTML]="option.text"
              ></p>
              <ng-container
                [ngTemplateOutlet]="tooltipTemplate"
                [ngTemplateOutletContext]="{ option: option }"
              >
              </ng-container>
            </div>
          </div>

          <div
            class="list-container"
            (mouseover)="this.changeBack($event, 'add')"
            (mouseleave)="this.changeBack($event, 'remove')"
            *ngIf="question.validation_option.is_comment_box && previewMode"
            (click)="checkRelatedRadio(question.question_options.length, $event)"
            [ngClass]="checkIfChecked('', 'is_other') ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfChecked('', 'is_other') && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfChecked('', 'is_other') && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
          >
            <div class="form-check item no-empty">
              <input
                (change)="checkboxOptionChecked($event, 'other_text_box')"
                type="checkbox"
                [checked]="checkIfChecked('', 'is_other')"
                class="fsop-checkbox"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
                id="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
              />
              <p class="form-check-label">
                <label style="margin-left: 12px;">
                  <span
                    class="opt-txt"
                    [ngStyle]="{
                      fontSize: previewMode
                        ? customThemeSettings['options_font_size']
                          ? customThemeSettings['options_font_size'] + 'px'
                          : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                        : '',
                      fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                      fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                    }"
                    [innerHTML]="'Other'"
                  ></span>
                </label>
                <ng-container
                  [ngTemplateOutlet]="tooltipTemplateOther"
                  [ngTemplateOutletContext]="{ option: question }"
                >
                </ng-container>
              </p>
            </div>
          </div>

          <div
            class="item no-empty other-textbox"
            *ngIf="
              question.validation_option.is_comment_box &&
              previewMode &&
              showOtherTextBox &&
              question.answer.other != undefined
            "
          >
            <input
              style="min-height: auto !important;"
              class="form-control"
              [attr.id]="'other_' + question.id"
              [(ngModel)]="question.answer.other"
              (ngModelChange)="emitCheckBoxOtherTextboxValue($event)"
              [ngModelOptions]="{ standalone: true }"
              type="text"
            />
          </div>

          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.to_observe && previewMode"
            (click)="changeRelatedChecked($event)"
            [ngClass]="checkIfNooSelected() ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfNooSelected() && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfNooSelected() && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <div class="form-check item no-empty">
              <input
                (change)="radioOptionChecked($event, 'noo')"
                type="checkbox"
                [checked]="checkIfNooSelected()"
                class="fsop-checkbox"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_noo_{{ type_name }}"
                id="answer_{{ question.id }}_noo_{{ type_name }}"
              />
              <p
                style="margin-left: 12px;"
                class="form-check-label"
                [ngStyle]="{
                  fontSize: previewMode
                    ? customThemeSettings['options_font_size']
                      ? customThemeSettings['options_font_size'] + 'px'
                      : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                    : '',
                  fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                  fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                }"
                [innerHTML]="previewMode ? 'No Opportunity to Observe' : 'No opportunity to observe'"
              ></p>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="!question.autoscaling_settings.scale">
      <!-- change here -->
      <form
        *ngSwitchCase="1"
        class="multichoice-box"
        [ngClass]="{ 'form-label-above': question.option_position_type == 2 }"
      >
        <div
          class="list-item form-group ss1"
          [ngClass]="{ forColumns: question.option_position_type == 3 }"
        >
          <!-- [ngStyle]="{'width':question.option_position_type == 3 ?  'calc('+100/question.total_columns+'% - 5px)' : '', 'margin-right': question.option_position_type == 3 ? '5px' : ''}" -->

          <ng-container
            *ngIf="question.option_position_type != 3; else col_based"
          >
            <div
              class="list-container"
              (mouseover)="!from ? this.changeBack($event, 'add') : ''"
              (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
              *ngFor="let option of question.question_options; let i = index"
              (click)="checkRelatedRadio(i, $event)"
              [ngClass]="{
                mcqSelected: checkIfChecked(option['url']) ? true:false,
                no: question.option_position_type ==2 && checkIfEmptyOption(option) ? true:false
              }"
              [style.borderColor]="
                customThemeSettings && customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
              "
              [style.color]="
                previewMode
                  ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                    ? customThemeSettings['highlighted_container_text_color']
                    : customThemeSettings['options_font_color']
                    ? customThemeSettings['options_font_color']
                    : previewMode && customThemeSettings
                    ? '#a5a5a5'
                    : ''
                  : ''
              "
              [style.background]="
                checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                  ? customThemeSettings['container_highlight_color']
                  : previewMode && customThemeSettings['container_in_question_options']
                  ? customThemeSettings['option_container_bg_color']
                    ? customThemeSettings['option_container_bg_color']
                    : '#eef2f5'
                  : ''
              "
              [ngStyle]="{
                paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
                paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
              }"
            >
              <div class="item radio-item">
                <input
                  (change)="radioOptionChecked($event, option)"
                  type="radio"
                  [checked]="checkIfChecked(option['url'])"
                  class="fsop-radio"
                  [style.display]="
                    previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                  "
                  name="answer_{{ question.id }}_{{ type_name }}"
                  id="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
                />
                <label
                  class="form-check-label"
                  for="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
                >
                  <span
                    class="opt-txt"
                    [ngStyle]="{
                      fontSize: previewMode
                        ? customThemeSettings['options_font_size']
                          ? customThemeSettings['options_font_size'] + 'px'
                          : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                        : '',
                      fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                      fontWeight: previewMode
                        ? customThemeSettings['options_font_weight']
                          ? customThemeSettings['options_font_weight']
                          : ''
                        : ''
                    }"
                    [innerHTML]="option.text"
                  ></span>
                  <ng-container
                    [ngTemplateOutlet]="tooltipTemplate"
                    [ngTemplateOutletContext]="{ option: option }"
                  >
                  </ng-container>
                </label>
              </div>
            </div>
          </ng-container>

          <ng-template #col_based>
            <!-- [ngStyle]="{'width':question.option_position_type == 3 ?  'calc('+100/question.total_columns+'% - 5px)' : '', 'margin-right': question.option_position_type == 3 ? '5px' : ''}" -->
            <!--  | slice:0:3 -->
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td
                    class="happy_time"
                    *ngFor="let col of col_arr; let j = index"
                    [ngStyle]="{'padding-right': '5px', 'vertical-align': 'top' }"
                  >
                    <div
                      class="list-container"
                      (mouseover)="!from ? this.changeBack($event, 'add') : ''"
                      (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
                      *ngFor="
                    let option of question.question_options | slice: (j == 0 ? 0 : getIndex(j - 1)):getIndex(j);
                    let i = index
                  "
                      (click)="checkRelatedRadio(i, $event, option)"
                      [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
                      [style.borderColor]="
                    previewMode && this.customThemeSettings
                      ? customThemeSettings['option_container_border_color']
                        ? customThemeSettings['option_container_border_color']
                        : 'transparent'
                      : ''
                  "
                      [style.color]="
                    previewMode
                      ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                        ? customThemeSettings['highlighted_container_text_color']
                        : customThemeSettings['options_font_color']
                        ? customThemeSettings['options_font_color']
                        : previewMode && customThemeSettings
                        ? '#a5a5a5'
                        : ''
                      : ''
                  "
                      [style.background]="
                    checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                      ? customThemeSettings['container_highlight_color']
                      : previewMode && customThemeSettings['container_in_question_options']
                      ? customThemeSettings['option_container_bg_color']
                        ? customThemeSettings['option_container_bg_color']
                        : '#eef2f5'
                      : ''
                  "
                      [ngStyle]="{
                    paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
                    paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
                  }"
                    >
                      <div class="item radio-item">
                        <input
                          (change)="radioOptionChecked($event, option)"
                          type="radio"
                          [checked]="checkIfChecked(option['url'])"
                          class="fsop-radio"
                          [style.display]="
                        previewMode && !from
                          ? this.customThemeSettings['input_visibility']
                            ? 'inline-block'
                            : 'none'
                          : ''
                      "
                          name="answer_{{ question.id }}_{{ type_name }}"
                          id="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                        />
                        <label
                          class="form-check-label"
                          for="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                        >
                          <span
                            class="opt-txt"
                            [ngStyle]="{
                          fontSize: previewMode
                            ? customThemeSettings['options_font_size']
                              ? customThemeSettings['options_font_size'] + 'px'
                              : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                            : '',
                          fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                          fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                        }"
                            [innerHTML]="option['text']"
                          ></span>
                          <ng-container
                            [ngTemplateOutlet]="tooltipTemplate"
                            [ngTemplateOutletContext]="{ option: option }"
                          >
                          </ng-container>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <ng-container *ngFor="let col of col_arr; let j = index">
              <div
                class="happy_time"
                [ngStyle]="{ width: 'calc(' + 100 / question.total_columns + '% - 5px)', 'margin-right': '5px' }"
              >
                <div
                  class="list-container"
                  (mouseover)="!from ? this.changeBack($event, 'add') : ''"
                  (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
                  *ngFor="
                    let option of question.question_options | slice: (j == 0 ? 0 : getIndex(j - 1)):getIndex(j);
                    let i = index
                  "
                  (click)="checkRelatedRadio(i, $event, option)"
                  [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
                  [style.borderColor]="
                    previewMode && this.customThemeSettings
                      ? customThemeSettings['option_container_border_color']
                        ? customThemeSettings['option_container_border_color']
                        : 'transparent'
                      : ''
                  "
                  [style.color]="
                    previewMode
                      ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                        ? customThemeSettings['highlighted_container_text_color']
                        : customThemeSettings['options_font_color']
                        ? customThemeSettings['options_font_color']
                        : previewMode && customThemeSettings
                        ? '#a5a5a5'
                        : ''
                      : ''
                  "
                  [style.background]="
                    checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                      ? customThemeSettings['container_highlight_color']
                      : previewMode && customThemeSettings['container_in_question_options']
                      ? customThemeSettings['option_container_bg_color']
                        ? customThemeSettings['option_container_bg_color']
                        : '#eef2f5'
                      : ''
                  "
                  [ngStyle]="{
                    paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
                    paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
                  }"
                >
                  <div class="item radio-item">
                    <input
                      (change)="radioOptionChecked($event, option)"
                      type="radio"
                      [checked]="checkIfChecked(option['url'])"
                      class="fsop-radio"
                      [style.display]="
                        previewMode && !from
                          ? this.customThemeSettings['input_visibility']
                            ? 'inline-block'
                            : 'none'
                          : ''
                      "
                      name="answer_{{ question.id }}_{{ type_name }}"
                      id="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                    />
                    <label
                      class="form-check-label"
                      for="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                    >
                      <span
                        class="opt-txt"
                        [ngStyle]="{
                          fontSize: previewMode
                            ? customThemeSettings['options_font_size']
                              ? customThemeSettings['options_font_size'] + 'px'
                              : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                            : '',
                          fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                          fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                        }"
                        [innerHTML]="option.text"
                      ></span>
                      <ng-container
                        [ngTemplateOutlet]="tooltipTemplate"
                        [ngTemplateOutletContext]="{ option: option }"
                      >
                      </ng-container>
                    </label>
                  </div>
                </div>
              </div>
            </ng-container> -->
          </ng-template>

          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.is_comment_box && previewMode"
            (click)="checkRelatedRadio(question.question_options.length, $event)"
            [ngClass]="checkIfChecked('', 'is_other') ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfChecked('', 'is_other') && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfChecked('', 'is_other') && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <div class="item radio-item">
              <input
                (change)="radioOptionChecked($event, 'other_text_box')"
                type="radio"
                [checked]="checkIfChecked('', 'is_other')"
                class="fsop-radio"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_{{ type_name }}"
                id="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
              />
              <label
                class="form-check-label"
                for="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
              >
                <span
                  class="opt-txt"
                  [ngStyle]="{
                    fontSize: previewMode
                      ? customThemeSettings['options_font_size']
                        ? customThemeSettings['options_font_size'] + 'px'
                        : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                      : '',
                    fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                    fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                  }"
                  [innerHTML]="'Other'"
                ></span>
              </label>
              <ng-container
                [ngTemplateOutlet]="tooltipTemplateOther"
                [ngTemplateOutletContext]="{ option: question }"
              >
              </ng-container>
            </div>
          </div>
          <div
            class="item radio-item radio-item-other"
            *ngIf="
              question.validation_option.is_comment_box &&
              previewMode &&
              showOtherTextBox &&
              question.answer.other != undefined
            "
          >
            <input
              style="min-height: auto !important;"
              class="form-control"
              [attr.id]="'other_' + question.id"
              [(ngModel)]="question.answer.other"
              (ngModelChange)="emitRadioOtherTextboxValue($event)"
              [ngModelOptions]="{ standalone: true }"
              type="text"
            />
          </div>

          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.to_observe && previewMode"
            (click)="changeRelatedChecked($event)"
            [ngClass]="checkIfNooSelected() ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfNooSelected() && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfNooSelected() && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <!-- 3 -->
            <div class="item radio-item">
              <input
                (change)="radioOptionChecked($event, 'noo')"
                type="radio"
                [checked]="checkIfNooSelected()"
                class="fsop-radio"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_noo_{{ type_name }}"
                id="answer_{{ question.id }}_noo_{{ type_name }}"
              />
              <label class="form-check-label">
                <span
                  class="opt-txt"
                  [ngStyle]="{
                    fontSize: previewMode
                      ? customThemeSettings['options_font_size']
                        ? customThemeSettings['options_font_size'] + 'px'
                        : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                      : '',
                    fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                    fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                  }"
                  [innerHTML]="previewMode ? 'No Opportunity to Observe' : 'No opportunity to observe'"
                ></span>
              </label>
            </div>
          </div>
        </div>
      </form>
      <!-- change here -->
      <form
        *ngSwitchCase="2"
        class="multichoice-box"
        [ngClass]="{ 'form-label-above': question.option_position_type == 2 }"
      >
        <div
          class="form-group list-item ss2"
          [ngClass]="{ forColumns: question.option_position_type == 3 }"
        >
          <ng-container
            *ngIf="question.option_position_type != 3; else col_based_checkbox"
          >
            <div
              class="list-container"
              (mouseover)="!from ? this.changeBack($event, 'add') : ''"
              (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
              *ngFor="let option of question.question_options; let i = index"
              (click)="checkRelatedRadio(i, $event)"
              [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
              [style.borderColor]="
                previewMode && this.customThemeSettings
                  ? customThemeSettings['option_container_border_color']
                    ? customThemeSettings['option_container_border_color']
                    : 'transparent'
                  : ''
              "
              [style.color]="
                previewMode
                  ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                    ? customThemeSettings['highlighted_container_text_color']
                    : customThemeSettings['options_font_color']
                    ? customThemeSettings['options_font_color']
                    : previewMode && customThemeSettings
                    ? '#a5a5a5'
                    : ''
                  : ''
              "
              [style.background]="
                checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                  ? customThemeSettings['container_highlight_color']
                  : previewMode && customThemeSettings['container_in_question_options']
                  ? customThemeSettings['option_container_bg_color']
                    ? customThemeSettings['option_container_bg_color']
                    : '#eef2f5'
                  : ''
              "
              [ngStyle]="{
                paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
                paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
              }"
            >
              <div class="form-check item">
                <input
                  (change)="checkboxOptionChecked($event, option)"
                  type="checkbox"
                  [checked]="checkIfChecked(option['url'])"
                  class="fsop-checkbox"
                  [style.display]="
                    previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                  "
                  name="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
                  id="answer_{{ question.id }}_{{ i }}_{{ type_name }}"
                />
                <p
                  class="form-check-label"
                  [style.display]="option.option_tooltip ? 'inline' : ''"
                  [ngStyle]="{
                    fontSize: previewMode
                      ? customThemeSettings['options_font_size']
                        ? customThemeSettings['options_font_size'] + 'px'
                        : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                      : '',
                    fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                    fontWeight: previewMode
                      ? customThemeSettings['options_font_weight']
                        ? customThemeSettings['options_font_weight']
                        : ''
                      : ''
                  }"
                  [innerHTML]="option.text"
                ></p>
                <ng-container
                  [ngTemplateOutlet]="tooltipTemplate"
                  [ngTemplateOutletContext]="{ option: option }"
                >
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-template #col_based_checkbox>
            <!-- [ngStyle]="{'width':question.option_position_type == 3 ?  'calc('+100/question.total_columns+'% - 5px)' : '', 'margin-right': question.option_position_type == 3 ? '5px' : ''}" -->
            <!--  | slice:0:3 -->
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td
                    class="happy_time"
                    *ngFor="let col of col_arr; let j = index"
                    [ngStyle]="{'padding-right': '5px','vertical-align': 'top' }"
                  >
                    <div
                      class="list-container"
                      (mouseover)="!from ? this.changeBack($event, 'add') : ''"
                      (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
                      *ngFor="
                        let option of question.question_options | slice: (j == 0 ? 0 : getIndex(j - 1)):getIndex(j);
                        let i = index
                      "
                      (click)="checkRelatedRadio(i, $event, option)"
                      [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
                      [style.borderColor]="
                        previewMode && this.customThemeSettings
                          ? customThemeSettings['option_container_border_color']
                            ? customThemeSettings['option_container_border_color']
                            : 'transparent'
                          : ''
                      "
                      [style.color]="
                        previewMode
                          ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                            ? customThemeSettings['highlighted_container_text_color']
                            : customThemeSettings['options_font_color']
                            ? customThemeSettings['options_font_color']
                            : previewMode && customThemeSettings
                            ? '#a5a5a5'
                            : ''
                          : ''
                      "
                      [style.background]="
                        checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                          ? customThemeSettings['container_highlight_color']
                          : previewMode && customThemeSettings['container_in_question_options']
                          ? customThemeSettings['option_container_bg_color']
                            ? customThemeSettings['option_container_bg_color']
                            : '#eef2f5'
                          : ''
                      "
                    >
                      <div class="form-check item">
                        <input
                          (change)="checkboxOptionChecked($event, option)"
                          type="checkbox"
                          [checked]="checkIfChecked(option['url'])"
                          class="fsop-checkbox"
                          [style.display]="
                            previewMode && !from
                              ? this.customThemeSettings['input_visibility']
                                ? 'inline-block'
                                : 'none'
                              : ''
                          "
                          name="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                          id="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                        />
                        <p
                          class="form-check-label"
                          [style.display]="option['option_tooltip'] ? 'inline' : ''"
                          [ngStyle]="{
                            fontSize: previewMode
                              ? customThemeSettings['options_font_size']
                                ? customThemeSettings['options_font_size'] + 'px'
                                : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                              : '',
                            fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                            fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                          }"
                          [innerHTML]="option['text']"
                        ></p>
                        <ng-container
                          [ngTemplateOutlet]="tooltipTemplate"
                          [ngTemplateOutletContext]="{ option: option }"
                        >
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <ng-container *ngFor="let col of col_arr; let j = index">
              <div
                class="happy_time"
                [ngStyle]="{ width: 'calc(' + 100 / question.total_columns + '% - 5px)', 'margin-right': '5px' }"
              >
                <div
                  class="list-container"
                  (mouseover)="!from ? this.changeBack($event, 'add') : ''"
                  (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
                  *ngFor="
                    let option of question.question_options | slice: (j == 0 ? 0 : getIndex(j - 1)):getIndex(j);
                    let i = index
                  "
                  (click)="checkRelatedRadio(i, $event, option)"
                  [ngClass]="checkIfChecked(option['url']) ? 'mcqSelected' : ''"
                  [style.borderColor]="
                    previewMode && this.customThemeSettings
                      ? customThemeSettings['option_container_border_color']
                        ? customThemeSettings['option_container_border_color']
                        : 'transparent'
                      : ''
                  "
                  [style.color]="
                    previewMode
                      ? checkIfChecked(option['url']) && customThemeSettings['highlighted_container_text_color']
                        ? customThemeSettings['highlighted_container_text_color']
                        : customThemeSettings['options_font_color']
                        ? customThemeSettings['options_font_color']
                        : previewMode && customThemeSettings
                        ? '#a5a5a5'
                        : ''
                      : ''
                  "
                  [style.background]="
                    checkIfChecked(option['url']) && previewMode && customThemeSettings['container_highlight_color']
                      ? customThemeSettings['container_highlight_color']
                      : previewMode && customThemeSettings['container_in_question_options']
                      ? customThemeSettings['option_container_bg_color']
                        ? customThemeSettings['option_container_bg_color']
                        : '#eef2f5'
                      : ''
                  "
                >
                  <div class="form-check item">
                    <input
                      (change)="checkboxOptionChecked($event, option)"
                      type="checkbox"
                      [checked]="checkIfChecked(option['url'])"
                      class="fsop-checkbox"
                      [style.display]="
                        previewMode && !from
                          ? this.customThemeSettings['input_visibility']
                            ? 'inline-block'
                            : 'none'
                          : ''
                      "
                      name="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                      id="answer_{{ question.id }}_{{ getIndexof(option) }}_{{ type_name }}"
                    />
                    <p
                      class="form-check-label"
                      [style.display]="option.option_tooltip ? 'inline' : ''"
                      [innerHTML]="option.text"
                    ></p>
                    <ng-container
                      [ngTemplateOutlet]="tooltipTemplate"
                      [ngTemplateOutletContext]="{ option: option }"
                    >
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container> -->
          </ng-template>
          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.is_comment_box && previewMode"
            (click)="checkRelatedRadio(question.question_options.length, $event)"
            [ngClass]="checkIfChecked('', 'is_other') ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfChecked('', 'is_other') && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfChecked('', 'is_other') && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <div class="form-check item no-empty">
              <input
                (change)="checkboxOptionChecked($event, 'other_text_box')"
                type="checkbox"
                [checked]="checkIfChecked('', 'is_other')"
                class="fsop-checkbox"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer{{ question.question_options.length }}_{{ type_name }}"
                id="answer_{{ question.id }}_{{ question.question_options.length }}_{{ type_name }}"
              />
              <p class="form-check-label">
                <label
                  style="margin-left: 12px;"
                  [innerHTML]="'Other'"
                  class="m-0 ms-0"
                  [ngStyle]="{
                    fontSize: previewMode
                      ? customThemeSettings['options_font_size']
                        ? customThemeSettings['options_font_size'] + 'px'
                        : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                      : '',
                    fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                    fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                  }"
                >
                  <span
                    class="opt-txt"
                    [ngStyle]="{
                      fontSize: previewMode
                        ? customThemeSettings['options_font_size']
                          ? customThemeSettings['options_font_size'] + 'px'
                          : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                        : '',
                      fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                      fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                    }"
                    [innerHTML]="'Other'"
                  ></span>
                </label>
                <ng-container
                  [ngTemplateOutlet]="tooltipTemplateOther"
                  [ngTemplateOutletContext]="{ option: question }"
                >
                </ng-container>
              </p>
            </div>
          </div>

          <div
            class="item no-empty other-textbox"
            *ngIf="
              question.validation_option.is_comment_box &&
              previewMode &&
              showOtherTextBox &&
              question.answer.other != undefined
            "
          >
            <input
              style="min-height: auto !important;"
              class="form-control"
              [attr.id]="'other_' + question.id"
              [(ngModel)]="question.answer.other"
              (ngModelChange)="emitCheckBoxOtherTextboxValue($event)"
              [ngModelOptions]="{ standalone: true }"
              type="text"
            />
          </div>

          <div
            class="list-container"
            (mouseover)="!from ? this.changeBack($event, 'add') : ''"
            (mouseleave)="!from ? this.changeBack($event, 'remove') : ''"
            *ngIf="question.validation_option.to_observe && previewMode"
            (click)="changeRelatedChecked($event)"
            [ngClass]="checkIfNooSelected() ? 'mcqSelected' : ''"
            [style.color]="
              previewMode
                ? checkIfNooSelected() && customThemeSettings['highlighted_container_text_color']
                  ? customThemeSettings['highlighted_container_text_color']
                  : customThemeSettings['options_font_color']
                  ? customThemeSettings['options_font_color']
                  : previewMode && customThemeSettings
                  ? '#a5a5a5'
                  : ''
                : ''
            "
            [style.background]="
              checkIfNooSelected() && previewMode && customThemeSettings['container_highlight_color']
                ? customThemeSettings['container_highlight_color']
                : previewMode && customThemeSettings['container_in_question_options']
                ? customThemeSettings['option_container_bg_color']
                  ? customThemeSettings['option_container_bg_color']
                  : '#eef2f5'
                : ''
            "
            [style.borderColor]="
              previewMode && this.customThemeSettings
                ? customThemeSettings['option_container_border_color']
                  ? customThemeSettings['option_container_border_color']
                  : 'transparent'
                : ''
            "
            [ngStyle]="{
              paddingTop: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px',
              paddingBottom: previewMode ? customThemeSettings['option_container_height'] / 2 + 'px' : '8px'
            }"
          >
            <div class="form-check item no-empty">
              <input
                (change)="radioOptionChecked($event, 'noo')"
                type="checkbox"
                [checked]="checkIfNooSelected()"
                class="fsop-checkbox"
                [style.display]="
                  previewMode && !from ? (this.customThemeSettings['input_visibility'] ? 'inline-block' : 'none') : ''
                "
                name="answer_{{ question.id }}_noo_{{ type_name }}"
                id="answer_{{ question.id }}_noo_{{ type_name }}"
              />
              <p
                style="margin-left: 12px;"
                class="form-check-label"
                [ngStyle]="{
                  fontSize: previewMode
                    ? customThemeSettings['options_font_size']
                      ? customThemeSettings['options_font_size'] + 'px'
                      : checkForZero(customThemeSettings['options_font_size'], '0px', '14px')
                    : '',
                  fontFamily: previewMode ? customThemeSettings['options_font_family'] : '',
                  fontWeight: previewMode ? customThemeSettings['options_font_weight'] : ''
                }"
                [innerHTML]="previewMode ? 'No Opportunity to Observe' : 'No opportunity to observe'"
              ></p>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <div *ngSwitchCase="3">
      <div class="form-group list-item">
        <select
          class="form-control"
          (click)="onSelectBoxClick($event)"
          [ngStyle]="{paddingTop : previewMode  ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
                    paddingBottom : previewMode ?  customThemeSettings['option_container_height']/2 + 'px' : '10px',
            minHeight : previewMode  ?  '24px' : ''}"
          (change)="onSelectedOptionChanged($event)"
        >
          <option value="" selected disabled>Select an answer</option>
          <option
            [selected]="isSelected(option)"
            [value]="option['url']"
            *ngFor="let option of question.question_options; let i = index"
          >
            {{ option.text }}
          </option>
          <option
            *ngIf="question.validation_option.is_comment_box && previewMode"
            [value]="'other_text_box'"
          >
            Other
          </option>

          <option
            *ngIf="question.validation_option.to_observe && previewMode"
            [value]="'noo'"
          >
            No Opportunity to Observe
          </option>
        </select>
      </div>
      <div
        class="form-group list-item mt-2"
        *ngIf="
          question.validation_option.is_comment_box &&
          previewMode &&
          showOtherTextBox &&
          question.answer.other != undefined
        "
      >
        <input
          style="min-height: auto !important;"
          class="form-control"
          [attr.id]="'other_' + question.id"
          [(ngModel)]="question.answer.other"
          (ngModelChange)="emitRadioOtherTextboxValue($event)"
          [ngModelOptions]="{ standalone: true }"
          type="text"
        />
      </div>
    </div>
    <div *ngIf="previewMode && okButtonShow && question['option_type']=='2' && customThemeSettings['theme_layout'] == 'focus'">
      <button
              [id]="'okbt'+question.numbering"
              (click)="onOkClick()"
              class="btn btn-md"
              style="display: inline;margin-left: 45px;color: white; background: #54902b;"
            >OK
            </button>
    </div>
  </ng-container>
</div>
<ng-template #tooltipTemplate let-option="option">
  <i
    (click)="tooltipClick($event)"
    class="svg hint-icon"
    container="body"
    *ngIf="!this.mobileView && option.option_tooltip"
    placement="auto"
    [ngbTooltip]="UploadCerttipContent"
  ></i>
  <i
    (click)="openModal($event, tooltipModalAnswer); $event.stopPropagation()"
    class="svg hint-icon"
    container="body"
    [ngStyle]="{ marginTop: '0.2%' }"
    *ngIf="this.mobileView && option.option_tooltip"
  ></i>
  <ng-template #tooltipModalAnswer let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div [innerHTML]="option.option_tooltip | safeHtml"></div>
  </ng-template>
  <ng-template #UploadCerttipContent>
    <div [innerHTML]="option.option_tooltip | safeHtml"></div>
  </ng-template>
</ng-template>
<ng-template #tooltipTemplateOther let-option="question">
  <i
    (click)="tooltipClick($event)"
    class="svg hint-icon"
    container="body"
    *ngIf="!this.mobileView && question.validation_option.comment_box_tooltip"
    placement="auto"
    [ngbTooltip]="CommenttipContent"
  ></i>
  <i
    (click)="openModal($event, tooltipModalOther); $event.stopPropagation()"
    class="svg hint-icon"
    container="body"
    [ngStyle]="{ marginTop: '0.2%' }"
    *ngIf="this.mobileView && question.validation_option.comment_box_tooltip"
  ></i>
  <ng-template #tooltipModalOther let-modal>
    <a (click)="modal.close()" href="javascript:void(0)" class="close-modal"
      ><i class="fa fa-times"></i
    ></a>
    <div
      [innerHTML]="question.validation_option.comment_box_tooltip | safeHtml"
    ></div>
  </ng-template>
  <ng-template #CommenttipContent>
    <div
      [innerHTML]="question.validation_option.comment_box_tooltip | safeHtml"
    ></div>
  </ng-template>
</ng-template>
