import { Component, OnInit, ViewChild, HostListener, Input, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { AssessmentDataService } from "../../../app/core/assessment-data.service";
import { UserDataService } from "../../../app/core/user-data";
import { ActivatedRoute } from "@angular/router";
import { IUserProfile } from "../../../app/models/user.model";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../../app/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { DataShareService } from "../../../app/data-share.service";
import { Observable, Subscription } from "rxjs";
import $ from 'jquery';
@Component({
  selector: "app-survey-preview",
  templateUrl: "./survey-preview.component.html",
  styleUrls: ["./survey-preview.component.scss"],
})
export class SurveyPreviewComponent implements OnInit, OnDestroy {
  // to check if survey is filling in realtime
  isSurveyLoading:boolean = false;
  @Input("isRealTime") isRealTime: boolean = false;
  // contains all survey data like sections, question, configuration settings
  @Input("surveyData") surveyData = {};
  // Ref to a modal which used to occur when usr skip a required question
  @ViewChild("skipAnswerAlert", { static: false }) skipAnswerAlertRef: NgbModalRef;
  // @ViewChild("saveResponseModal", { static: false }) saveResponseModal: NgbModalRef;
  // toggle for when next button of survey is clicked
  isNextButtonClicked: boolean = false;
  // To show loader till survey data is not get loaded
  loading: boolean = true;
  // local varible that contains design configuration of survey
  surveyDesign: any;
  // local varible that contains setting configuration of survey
  surveySettings: any;
  // used to set and show error msg while filling survey
  surveyLoadingError: string;
  // set it to true to show msg if survey is expired
  surveyLinkExpired: boolean = false;
  // contains list of question whose users has done with answer at least one time
  answeredQuestions: any[] = [];
  // local variable contains section data
  sections: any[] = [];
  // previews holds data coming from preview API
  preview: any;
  // dynamic close message when user finish the survey
  surveyCloseMessage: string = "";
  // sumbit button validation check variable
  canSubmit: boolean = false;
  // check if survey is submitted or not
  isSurveySubmitted: boolean = false;
  // check to show required question error message box
  showRequiredQuestionErrorBox: boolean = false;
  // maitain count of number of questions answered by user
  counterDoneQuestions: number = 0;
  // survey ID
  currentSurveyId: string;
  // for multiple page survey to maintain current page index
  currentPageIndex: number = 0;
  // contains current page index section data
  currentPage: any;

  currentQuestions:any;
  // total question count
  totalQuestions: number = 0;
  // progress step bar width
  progressStep: number;
  // check if survey is opened in resp mode or not
  responseMode: boolean = false;
  // set var to true if survey is opened using link
  viaLink: boolean = false;
  // progress width
  progressState: number = 0;
  // current survey response
  currentSurveyResponse: any;
  // contains user data
  userProfile: IUserProfile;
  // url of user profile image
  userProfileImage: string;
  // token attached in token type survey url
  emailLinkToken: string;
  // default user image
  profileImagePlaceHolder: string = "assets/images/default-user-photo.svg";
  // input to alert modal
  alertContent: any;
  // it is number of required question which user didn't answer while submitting the survey
  numberOfRequestedQuestions: number = 0;
  // total number of pages
  maxPages: number;
  timeNotComeYet: boolean = false;
  // set to show msg if survey response url gives 404 means not exist or not found
  ressourceNotFound: boolean = false;
  // to show saving on save button till API is in progress for save and resume feature
  isSaving: boolean = false;
  // set it to true if setting for autoprogress comes as true
  autoProgressMade: any = false;
  // local variable to get theme setting
  public selectedTheme: any;
  // hold access_prevent_response_editing setting from survey configuration
  preventEditingResponse: any = false;
  // subscription variable to unsubscribe datashare observable
  public dataShareSub: Subscription;
  // hold surveyresponse data from API
  public tempdata: any;
  // hold timeout to clear timeout after it
  removeClassTimeout: any;
  generate_individual_report: any = false;
  // check if report sent or not for template
  report_sent: any = false;
  // hold timeout to clear timeout after it
  reportTimeout: any;
  // parent window reference
  av_platform: any = window.parent == window;
  // check if save and resume feature is on or not form survey configuration
  SaveAndResume: any = true;


  contactData:any = {};
  // Default custom theme variable
  custom_theme: any = {
    apply_theme: true,
    back_button_color: "#efa9a9",
    background_color: "",
    background_image: "",
    change_button_placement: "center",
    heading_background_color: "#ffb41b",
    heading_font_color: "#ff3e3e",
    heading_font_size: "30",
    logo_placement: "center",
    next_button_color: "#35d43c",
    opacity_of_background_image: "49",
    progress_bar_color: "#5e74e1",
    progress_bar_display: "with_text",
    question_font_color: "#9ed2eb",
    radio_button_color_change: "#212529",
    save_button_color: "",
    scrollbar_visibility: "always_visible",
    survey_title_alignment: "center",
    survey_title_font_color: "",
    theme_layout: "scroll",
    section_name_font_color: "",
  };
  // ref to current response Id
  currentResponseId: any;
  // ref of application environment variable
  _environment: any = environment;
  // random number
  public random = Math.floor(Math.random() * 10000);
  dynamicRedirectURLFromLogic: any;
  // update customtheme variable at the run time
  @Input("customtheme") set customthemeSettingUpdate(val: any) {
    if (val.length) {
      val.forEach((ele: any) => {
        this.custom_theme[ele.code] = ele.value;
      });
    }
  }
  // ref to theme type
  themeType: any;
  // current selected theme ref
  @Input("selectedTheme") set themSelected(val: any) {
    if (val) {
      this.themeType = val;
      this.preview ? (this.preview["design"]["design_theme"] = val) : "";
      if (
        this.preview &&
        this.preview.design &&
        (this.preview["design"]["design_theme"] == "dark" ||
          this.preview["design"]["design_theme"] == "grey" ||
          this.preview["design"]["design_theme"] == "light" ||
          this.preview["design"]["design_theme"] == "default")
      ) {
        let d: any = document.getElementById("fsop" + this.random);
        d
          ? (d.style.width = "100%")
          : "";
      }
      this.updateRadioBoxColor();
    }
  }
  // check if the preview is showing in builder or preview/res page ( we can preview a survey in a modal when we click on design theme side menu)
  isBuilder = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private assessmentData: AssessmentDataService,
    private userDataService: UserDataService,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private dataShare: DataShareService,
    private cd: ChangeDetectorRef
  ) {
    this.contactData = {
      firstName: "",
      lastName: "",
      email: "",
      fullName: ""
    };
    $("body").css("overflow", "hidden");
    this.responseMode = this.router.url.includes("resp");
    if(this.responseMode){
      $("body").addClass("preview-page");
    }

    if (this.router.url.includes("builder")) {
      this.isBuilder = true;
    }
    if (this.router.url.includes("builder") && !this.router.url.includes("report")) {
      this.currentSurveyId = this.router.url.split("survey/")[1].split("/")[0];
      this.getSurveyPreview(this.currentSurveyId, true);
    } else {
      // this.getSurveyPreview(surveyId)
      this.activatedRoute.params.subscribe((params) => {
        this.currentSurveyId = params["id"];
        let response = localStorage.getItem("response");
        // check if it is an email link
        if (params["survey_email_id"] != undefined && params["token"] != undefined) {
          this.viaLink = false;
          this.emailLinkToken = params["token"];

          let survey_email_link_id = params["survey_email_id"];
          this.assessmentData.checkEmailLink(survey_email_link_id).subscribe(
            (daata: any) => {
              if (!daata.has_expired) {
                this.surveyLinkExpired = false;
                this.fetchOtherDetails();
              } else {
                this.surveyLinkExpired = true;
                if (response && JSON.parse(response)["survey"].includes(this.currentSurveyId)) {
                  localStorage.removeItem("response");
                }
                this.loading = false;
              }
            },
            (error: any) => {
              this.ressourceNotFound = true;
              this.surveyLinkExpired = false;
              if (response && JSON.parse(response)["survey"].includes(this.currentSurveyId)) {
                localStorage.removeItem("response");
              }
              this.loading = false;
            }
          );
        } else {
          this.viaLink = true;
          this.isRealTime ? this.getPreviewforRealTime(this.currentSurveyId) : this.fetchOtherDetails();
        }
        // check if response stands for the same survey or not
        if (response != undefined && !JSON.parse(response)["survey"].includes(this.currentSurveyId)) {
          localStorage.removeItem("response");
        }
      });
    }
  }
  // to unsubscribe all the subscription while destroying of component
  subscription: Subscription = new Subscription();
  // to unsubscribe focusAnswerOkClicked subscription while destroying of component
  focusAnswerOkClickedSub: Subscription;
  noCookies:boolean;
  private getPreviewforRealTime(surveyId: any) { }

  ngOnInit() {
    this.shouldUseCookie()
    if (this.isRealTime) {
      this.assessmentData.setLoader({ bool: true });
    }

    this.dataShareSub = this.dataShare.updateData.subscribe((data) => {
      if (data) {
        this.custom_theme[data.key] = data.val;
        if (data.val == "slide") {
          //updated via transitions dropdown
          setTimeout(() => {
            this.setQuestionSectionTransition(null, null);
          });
        } else if (data.val == "smooth_scroll") {
          //updated via transitions dropdown
          //set height code
          setTimeout(() => {
            let _height = $(".activeQuesContainer .section").height();
            $(".question-section-container").css({
              height: `${_height ? _height + 24 + "px" : "auto"}`,
            });
          });
        } else if (data.key == "theme_layout") {
          $(".question-section-container").css({
            height: "auto",
          });
        }
        this.cd.detectChanges();
        this.updateRadioBoxColor();
      }
    });
    //change via theme type selection update(modern/classic)
    this.subscription = this.dataShare.updateTransition.subscribe((data) => {
      if (data == "slide") {
        setTimeout(() => {
          // this.setHeight();
          //set height code
          let _height = $(".activeQuesContainer .section").height();
          $(".question-section-container").css({
            height: `${_height ? _height + 24 + "px" : "auto"}`,
          });
          this.setQuestionSectionTransition(null, null);
        });
      }
    });
    this.listenToHeaderUpdates();
    this.listenToMcqOptionUpdate();

    this.focusAnswerOkClickedSub = this.assessmentData.focusAnswerOkClicked$.subscribe(() => {
      this.nextQues(this.onDoneQuestionUpdatedArg["_ques"].numbering + 1);
    });
  }


  shouldUseCookie(){
    this.noCookies = this.route.snapshot.params.noCookies;
  }

  containsOnlyNumbers(str: string) {
    return /^\d+$/.test(str);
  }

  /**
   * hide those question which are the part of conditions in any of the logic of questions
   */

  startSetUpForDisplayLogic() {
    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any, index: any) => {
        let isHide: boolean = false;
        if (
          ques &&
          ques.logic &&
          ques.logic.length &&
          ques.logic[0]["conditions"] &&
          ques.logic[0]["conditions"].length
        ) {
          ques.logic.forEach((lgc: any, index2: any) => {
            if (lgc.logic_type == "display") {
              lgc.conditions.forEach((cond: any) => {
                this.getQuestionDestination(cond.question_destination);
              });
            }
          });
        }
      });
    });
  }

  /**
   * for save and submit if user start survey from middle then logic will run for those 
    questions which are already passed and on the current page
   */

  runTillQuestionLogic() {
    this.sections.forEach((section: any, secIndex: number) => {
      if (secIndex <= this.currentPageIndex) {
        section.questions.forEach((ques: any, index: any) => {
          if (ques && ques.logic && ques.logic.length) {
            let dataForLogicRun = {};
            let quesArr = ques.url.split("/");
            let quesId = +quesArr[quesArr.length - 2];
            if (ques.answer && ques.answer.options && ques.answer.options.length) {
              ques.answer.options.forEach((op: any) => {
                let opt = op.split("/");
                let opId = +opt[opt.length - 2];
                if (ques.option_type === 1) {
                  dataForLogicRun = {
                    id: opId,
                    option_tooltip: "",
                    position: ques.position,
                    q_id: quesId,
                    ques_pos: ques.position,
                    question_id: quesId,
                    text: ques.text,
                    url: op,
                  };
                  this.assessmentData.optionChoosen$.next(dataForLogicRun);
                } else if (ques.option_type === 2) {
                  dataForLogicRun = {
                    checked: true,
                    option_type: 2,
                    id: opId,
                    option_tooltip: "",
                    position: ques.position,
                    q_id: quesId,
                    ques_pos: ques.position,
                    question_id: quesId,
                    text: ques.text,
                    url: op,
                  };
                  this.assessmentData.optionChoosen$.next(dataForLogicRun);
                }
              });
            }
          }
        });
      }
    });
  }

  /**
   * Hide those question which are the part of any conditions in any of the logic of questions
   * @param question_destination 

   */

  getQuestionDestination(question_destination: any) {
    this.sections.forEach((section: any) => {
      let isHide: any = false;
      section.questions.forEach((ques: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];
        if (id == question_destination) {
          ques["hide"] = true;
        }
      });
      // if (!isHide) {question-container
      //   ques["hide"] = false;
      // }
    });
  }

  /**
   * header updates observable
   */

  listenToHeaderUpdates() {
    this.subscription.add(
      this.assessmentData.headerUpdates$.subscribe((data) => {
        console.log(data);
        this.surveyDesign.design_header = data;
        this.cd.detectChanges();
      })
    );
  }

  /**
   * getter fn for header image url
   */
  getHeaderImage() {
    return this.surveyDesign ? this.surveyDesign.design_header : null;
  }

  /**
   * radio box color changes according to custom theme settings
   */
  updateRadioBoxColor() {
    if (this.custom_theme["radio_button_color_change"]) {
      let style_css = `
      .previewMode .fsop-radio:checked:before {
        border: 1px solid ${this.custom_theme["radio_button_color_change"]};
      }
      @keyframes radio-animation {
        0% {
          box-shadow: 0 0 0px 1px #fff;
        }
        50% {
          box-shadow: 0 0 4px 2px ${this.custom_theme["radio_button_color_change"]};
        }
        100% {
          box-shadow: 0 0 0px 0px #fff;
        }
      }
      .previewMode .fsop-radio:checked:before {
        background: ${this.custom_theme["radio_button_color_change"]};
      }
      `;

      this.appendStyle(style_css);
    } else {
      let style_css = `
      .previewMode .fsop-radio:checked:before {
        border: 1px solid ;
      }
      @keyframes radio-animation {
        0% {
          box-shadow: 0 0 0px 1px #fff;
        }
        50% {
          box-shadow: 0 0 4px 2px ;
        }
        100% {
          box-shadow: 0 0 0px 0px #fff;
        }
      }
      .previewMode .fsop-radio:checked:before {
        background: #313131;
      }
      `;
      this.appendStyle(style_css);
    }
  }

  /**
   * show popup if user leave or refresh browser button
   */
  @HostListener("window:beforeunload", ["$event"])
  public beforeunloadHandler(e: any) {
    if (
      this.responseMode &&
      this.answeredQuestions.length &&
      !this.isSurveySubmitted &&
      !this.surveyLoadingError &&
      !this.ressourceNotFound
    ) {
      var dialogText = "Dialog text here";
      e.returnValue = dialogText;
      return dialogText;
    }
  }

  getEmbedDataFromURL() {
    let params = this.route.snapshot.queryParams;
    let keys = Object.keys(params);
    let rData: any = [];
    if (keys.length) {
      keys.forEach((k) => {
        if (params[k]) {
          rData.push({
            name: k,
            value: params[k]
          })
        }
      })
    }
    return rData;
  }

  /**
   * fetching user profile information
     getting IP address of user
   */
  fetchOtherDetails() {
    // this.assessmentData.setLoader({bool:true, bgColor:'x'});
    let embed_data = this.getEmbedDataFromURL();
    this.getUserProfile();
    let newResponse: any = {
      ip_address: null,
      respondent: null,
      status: 1,
      token: this.emailLinkToken != undefined ? this.emailLinkToken : "",
      survey: environment.BASE_URL + `surveys/${this.currentSurveyId}/`,
    };

    newResponse = { ...newResponse, embed_data };

    this.userDataService
      .getMyIPAddress()
      .toPromise()
      .then((response: any) => {
        this.userDataService.ipAddress = response.ip;
        newResponse.ip_address = response.ip;
      })
      .then(() => {
        if (!this.responseMode) {
          // preview mode
          if (this.authenticationService.isAuthenticated()) {
            this.userDataService.me().subscribe((me: IUserProfile) => {
              //let token = JSON.parse(localStorage.getItem('credentials'))['token'];
              newResponse.respondent = me.url;
              this.userDataService.userInfo = me;
            });
          }
        }
      })
      .then(() => {
        if (this.responseMode) {
          if (localStorage.getItem("response") !== null) {
            this.currentSurveyResponse = JSON.parse(localStorage.getItem("response") as string);
            let split_arr = this.currentSurveyResponse.url.split("/");
            this.currentResponseId = split_arr[split_arr.length - 2];
            this.getSurveyResponse(this.currentSurveyResponse.url);
          } else {
            if (this.viaLink && document.cookie.includes(this.currentSurveyId)) {
              //here
              this.isSurveySubmitted = true;
              this.loading = false;
              this.assessmentData.setLoader({ bool: false });
            } else {
              if (this.router.url.includes("responses")) {
                this.currentResponseId = this.route.snapshot.params["response_id"];
                this.getSurveyResponse(`${environment.BASE_URL}responses/${this.currentResponseId}/`, newResponse);
              } else {
                this.assessmentData
                  .createAssessmentResponse({ ...newResponse, status: 0 })
                  .toPromise()
                  .then(
                    (response) => {
                      this.currentSurveyResponse = response;
                      let split_arr = this.currentSurveyResponse.url.split("/");
                      this.currentResponseId = split_arr[split_arr.length - 2];
                      localStorage.setItem("response", JSON.stringify(response));
                      this.getSurveyResponse(this.currentSurveyResponse.url);
                    },
                    (error) => {
                      this.loading = false;
                      // this.assessmentData.setLoader({bool:false, bgColor:'x'});
                      this.surveyLoadingError = error.error.error_msg;
                    }
                  );
              }
            }
          }
        } else {
          this.getSurveyPreview(this.currentSurveyId);
        }
      });
  }

  /**
   * user will logout and redirect to login screen
   */

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(["/login"], { replaceUrl: true }));
  }

  /**
    username get function
  */
  get username(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.username : null;
  }

  /**
   * getting user profile information from local service if he/she is authenticated
   */

  getUserProfile() {
    if (this.authenticationService.isAuthenticated()) {
      this.userDataService.me().subscribe((userProfile: IUserProfile) => {
        this.userDataService.userInfo = userProfile;
        this.userProfile = userProfile;
        this.userProfileImage =
          this.userProfile.profile_picture != null ? this.userProfile.profile_picture : this.profileImagePlaceHolder;
      });
    }
  }

  /**
   * check if theme is custom or dark
   */
  checkTheme() {
    if (this.surveyDesign.design_theme == "dark") return "#46474c";
    if (this.surveyDesign.design_theme == "custom") return "transparent";
    return "#fff";
  }

  /**
   * generic function to get setting data for specific setting
   */
  getSettingIndex(type: any, _code: any) {
    return this.surveySettings[type].findIndex((setting: any) => {
      return setting.code == _code;
    });
  }

  /**
   * API call to get survey preview data 
     Once data comes respective variable set to load questions, progress bar, header and other css changes according to 
     setting are assigned
   */

  transitionLoading = false;

  private lastHeightValue: string = ''; // To store the last applied height value

  ngAfterViewChecked(): void {
    this.setSectionHeight();
  }

  private setSectionHeight(): void {
    // Safely calculate heights with existence checks
    const headImgContainer = ($('.header-img-container').outerHeight(true) as number) || 0;
    const footerHeight = ($('footer').outerHeight(true) as number) || 0;
    const questionSectionHead = ($('.question-section-head').outerHeight(true) as number) || 0;
    const titleHeading = ($('.title-heading').outerHeight(true) as number) || 0;
    const postFoot = ($('.post-foot').outerHeight(true) as number) || 0;
    // Calculate the new height value
    const heightValue = `calc(100vh - ${headImgContainer + footerHeight + questionSectionHead + titleHeading + 45 - (!(this.custom_theme.appearance_footer || this.custom_theme.appearance_custom_copyright_text) ? 50:0)}px)`;

    // Only apply the styles if the height has changed to avoid unnecessary DOM manipulation
    if ((this.custom_theme.eliminate_the_container !== true) && !this.isBuilder) {
      $('.section-content').css({
        'min-height': heightValue,
        'max-height': heightValue
      });
      // this.lastHeightValue = heightValue; // Update the last height value
    }
    else{
      $("body").removeClass("preview-page");
    }
  }

  private getSurveyPreview(surveyId: string, isBuilder?: boolean): void {
    this.assessmentData.getSurveyPreview(surveyId).subscribe(
      (surveyPreview: any) => {

        if (this.isRealTime) {
          setTimeout(() => {
            this.assessmentData.setLoader({ bool: false });
          }, 500);
        }
        this.preview = surveyPreview;
        !isBuilder
          ? this.titleService.setTitle(
            `Ambivista Preview of ${this.preview.is_template ? this.preview.template_name : this.preview.title}`
          )
          : "";
        // this.assessmentData.setLoader({bool:false, bgColor:'x'});
        this.surveyDesign = surveyPreview.design;
        this.surveySettings = surveyPreview.setting;

        if (surveyPreview.custom_design) {
          if (this.surveyDesign.design_theme) {
            surveyPreview.custom_design.forEach((obj: any) => {
              this.custom_theme[obj.code] = obj.value;
            });
            this.surveyDesign.design_theme == "custom"
              ? (this.custom_theme["apply_theme"] = true)
              : (this.custom_theme["apply_theme"] = false);
            if (this.custom_theme["radio_button_color_change"]) {
              let style_css = `
              .previewMode .fsop-radio {
                -webkit-appearance: none;
                width: 16px;\
                min-width: 16px;
                height: 16px;
                min-height: 16px;
                position: relative;
                display: inline-block;
                border-radius: 50%;
                margin: 2px 0px;
                flex-shrink: 0;
              }

              .previewMode .fsop-radio:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                margin: auto;
                width: 100%;
                min-width: 100%;
                height: 100%;
                min-height: 100%;
                border-radius: 50%;
                border: 1px solid gray;
                transition: transform ease 280ms,background-color ease 280ms;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
                transform-origin: center;
              }

              .previewMode .fsop-radio:checked{
                animation-name: radio-animation;
                animation-duration: 0.14s;
              }
              .previewMode .fsop-radio:checked:before {
                border: 1px solid ${this.custom_theme["radio_button_color_change"]};
              }
              @keyframes radio-animation {
                0% {
                  box-shadow: 0 0 0px 1px #fff;
                }
                50% {
                  box-shadow: 0 0 4px 2px ${this.custom_theme["radio_button_color_change"]};
                }
                100% {
                  box-shadow: 0 0 0px 0px #fff;
                }
              }
              .previewMode .fsop-radio:checked:before {
                background: ${this.custom_theme["radio_button_color_change"]};
              }
              .previewMode .fsop-checkbox[type="checkbox"]:checked:after {
                color: ${this.custom_theme["radio_button_color_change"]} !important;
                border-color: ${this.custom_theme["radio_button_color_change"]};
              }
              `;
              let common_style = `
              app-survey-preview fsop-multichoice .list-container.mcqSelected {
                background: ${this.custom_theme["container_highlight_color"]
                  ? this.custom_theme["container_highlight_color"]
                  : "inherit"
                };
              }


              `;
              this.appendStyle(style_css);
              this.appendStyle(common_style);
            }
          } else {
            this.custom_theme["apply_theme"] = false;
            if (window.innerWidth < 768) {
              $("body").css("overflow", "auto");
            }
          }
        }
        let index = this.getSettingIndex("appearance", "appereance_custom_close_message");
        if (this.surveySettings.appearance[index].value && !isBuilder) {
          this.surveyCloseMessage =
            this.surveySettings.appearance[index].value == true
              ? "Thanks for participating! <br> <br> Your survey was successfully submitted."
              : this.surveySettings.appearance[index].value;
        } else {
          this.surveyCloseMessage = "Thanks for participating! <br> <br> Your survey was successfully submitted.";
        }
        this.totalQuestions = surveyPreview.question_count;
        !isBuilder ? localStorage.removeItem("previewState") : "";
        // if(localStorage.getItem("previewState") !== null &&  localStorage.getItem("previewState") !== undefined){
        //   if(this.currentSurveyId === JSON.parse(localStorage.getItem("previewState"))['surveyId']){
        //     this.sections = JSON.parse(localStorage.getItem("previewState"))['sections'];
        //   }else{
        //     this.sections = this.segmentSectionsIntoPages(surveyPreview["sections"]);
        //   }

        // }else{
        this.sections = this.segmentSectionsIntoPages(surveyPreview["sections"]);
        // Call function to clean placeholders and empty spans
        this.cleanUnrequiredPlaceholders(this.sections);
        // }
        this.startSetUpForDisplayLogic();

        if (this.currentPageIndex > 0) {
          // run logic for all question before and on the current page
          this.runTillQuestionLogic();
        }

        this.currentPage = this.sections[this.currentPageIndex];
        this.replaceQuestioTextContactPlaceholders(this.currentPage?.questions);

        this.preventEditingResponse = this.surveySettings.access.filter((setting: any) => {
          return setting["code"] == "access_prevent_response_editing";
        })[0].value;

        // if (this.surveySettings.access[1].value) {
        //   this.currentPage["editable"] = false;
        // } else {
        //   this.currentPage["editable"] = true;
        // }
        // this.sections.forEach((section: any) => {
        //   this.progressStep = 100 / this.totalQuestions;
        // });

        this.progressStep = 100 / this.sections.length;

        if (this.currentPageIndex > 0) {
          this.setProgressBarWidth("initial");
        } else {
          this.progressState = 0;
        }

        this.countDoneQuestion();

        this.setQuestionContainerStyle();
        this.setQuestionSectionTransition("next");

        setTimeout(() => {
          this.setQuestionSectionTransition("next", "isInitial");
          // this.startObservingScreen()
          // if (this.custom_theme["scrollbar_visibility"] == "auto_hide") {
          this.setIntervalForScroll();
          // }
          isBuilder ? this.cd.detectChanges() : "";
          this.transitionLoading = true;
          setTimeout(() => {
            this.transitionLoading = false;
            this.cd.detectChanges();
          }, 800);

          this.loading = false;
          this.cd.detectChanges();
        }, 500); //earlier 2000
      },
      (error) => {
        this.transitionLoading = false;
        this.loading = false;
        // this.assessmentData.setLoader({bool:false, bgColor:'x'});
        this.surveyLoadingError = error.error.error_msg;
      }
    );
  }


  // Function to clean placeholders based on the requirements
  cleanUnrequiredPlaceholders = (sections) => {
    sections.forEach((section, sectionIndex) => {
        section.questions.forEach(question => {
              // text.replace(/{{Q\d+(\.ID)?\.(\d+)\.QuestionText}}/g,
            question.text = question.text.replace(/\{{Q\d+\.ID(\d+)\.([^}]+)\}}/g, (match, qUrl, placeholderType) => {
                if (["QuestionText", "AllChoices"].includes(placeholderType)) {
                    return match;
                }

                const extractId = (url) => {
                    if (!url) return null;
                    const match = url.match(/https?:\/\/.*?\/(\d+)\/?$/);
                    return match ? match[1] : null;
                };

                const existsInSameSection = section.questions.some(q => q.url && extractId(q.url) === qUrl);

                // Check if qUrl exists in **any** of the next sections
                let existsInNextSections = false;
                for (let i = sectionIndex; i < sections.length; i++) {
                    if (sections[i].questions.some(q => q.url && extractId(q.url) === qUrl)) {
                        existsInNextSections = true;
                        break; // No need to check further
                    }
                }

                // Remove placeholders that exist in the same or next section
                if (existsInSameSection || existsInNextSections) return '';

                // ✅ Keep the placeholder if it does NOT exist in the same or next sections
                return match;
            });

            question.text = question.text.replace(/<span class="piped-text">\s*<\/span>/g, '');
        });
    });
    console.log(this.sections);
};














  isScrollSet: boolean = false;
  isScrollSet2: boolean = false;
  /**
   * fn will hide or show the scroll of question section content
     on the basis of configuration coming from survey settings
   */
  setScroll() {
    if (!this.isScrollSet && this.custom_theme["scrollbar_visibility"] == "auto_hide") {
      let scrollTimeout: any;
      if (document.querySelector(".ques-container .section-content")) {
        this.isScrollSet = true;
        clearInterval(this.intervalForScroll);
        document.querySelector(".ques-container .section-content")?.addEventListener("scroll", () => {
          if (
            this.custom_theme["scrollbar_visibility"] == "auto_hide" &&
            !this.custom_theme["eliminate_the_container"]
          ) {
            document.querySelector(".ques-container")?.classList.remove("scroll-auto-hide");
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
              document.querySelector(".ques-container")?.classList.add("scroll-auto-hide");
            }, 500);
          }
        });
      }
    }
    if (!this.isScrollSet2 && this.custom_theme["scrollbar_visibility"] == "auto_hide") {
      this.setScrollForEliminateTheContainer();
    }
  }

  /**
   * scroll show/hide setting in case of eliminate_the_container condition comes true from survey setting
   */
  setScrollForEliminateTheContainer() {
    if (!this.isScrollSet2 && this.custom_theme["scrollbar_visibility"] == "auto_hide") {
      let scrollTimeout: any;
      this.isScrollSet2 = true;
      clearInterval(this.intervalForScroll);
      document.querySelector(".survey-preview")?.addEventListener("scroll", () => {
        if (this.custom_theme["scrollbar_visibility"] == "auto_hide" && this.custom_theme["eliminate_the_container"]) {
          document.querySelector(".full-width")?.classList.remove("scroll-auto-hide");
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(() => {
            document.querySelector(".full-width")?.classList.add("scroll-auto-hide");
          }, 500);
        }
      });
    }
  }
  intervalForScroll: any;
  setIntervalForScroll() {
    this.intervalForScroll = setInterval(() => {
      this.setScroll();
    }, 2000);
  }

  questionContainerStyleTimeout: any;

  /**
   * set width, min-width and visibility hidden class for mordern type theme on question container
   */
  setQuestionContainerStyle() {
    clearTimeout(this.questionContainerStyleTimeout);
    if (this.custom_theme.theme_layout != "classic") {
      this.questionContainerStyleTimeout = setTimeout(() => {
        if (document.querySelector(".container.ember")) {
          let d: any = document.querySelector(".container.ember");
          let _width = +d["offsetWidth"];
          $(".question-section-container app-question-section").css("width", `${_width}px`);
          $(".question-section-container app-question-section").css("min-width", `${_width}px`);
          $(".ques-container:not(.activeQuesContainer)").addClass("visibility-hidden");
        }
      }, 300);
    }
  }

  /**
   * set height of section container if the theme_layout set to slide or smooth_scroll
   */
  setHeight() {
    if (this.custom_theme.theme_layout == "slide" || this.custom_theme.theme_layout == "smooth_scroll") {
      setTimeout(() => {
        let _height = $(".activeQuesContainer .section").height();
        $(".question-section-container").css({
          height: `${_height ? _height + 24 + "px" : "auto"}`,
        });
      }, 500);
    }
    if (this.custom_theme.theme_layout == "slide") {
      setTimeout(() => {
        let _height = $(".activeCurrQuesContainer .section").height();
        $(".question-section-slide-container").css({
          height: `${_height ? _height + 24 + "px" : "auto"}`,
        });
      }, 500);
    }
  }

  slideloaded = false;

  /**
   * if theme_layout set to slide then setting transform of container and setting height of question
     scrikk event register and setting configuration like top,left, smooth etc.
   */
  workForSlideTransition() {
    if (document.querySelector(".container.ember")) {
      let d: any = document.querySelector(".container.ember");
      let _width = +d["offsetWidth"];
      $(".question-section-container").css({
        transform: `translateX(-${_width * (this.currentPageIndex > 0 ? this.currentPageIndex : 0)}px)`,
      });
      this.setQuestionContainerStyle();
      this.setHeight();
      //recently updated
      $(".ques-container:not(.activeQuesContainer)").addClass("visibility-hidden");
      document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.querySelector(".section-content") &&
        document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
    this.slideloaded = true;
    this.cd.detectChanges();
  }

  /**
   * fn will set style for question container for slide type theme_layout for preview case
   */
  setQuestionContainerStyleForSlidePreview() {
    clearTimeout(this.questionContainerStyleTimeout);
    if (this.custom_theme.theme_layout != "classic") {
      this.questionContainerStyleTimeout = setTimeout(() => {
        if (document.querySelector(".container.ember")) {
          let d: any = document.querySelector(".container.ember");
          let _width = d["offsetWidth"];
          $(".question-section-container app-question-section").css("width", `${_width}px`);
          $(".ques-container:not(.activeQuesContainer)").addClass("visibility-hidden-no-opacity");
        }
      }, 0);
    }
  }

  /**
    class append to question container and section container for slide transition type
  */
  workForSlideTransitionPreview() {
    if (document.querySelector(".container.ember")) {
      this.setHeight();

      $(".ques-container:not(.activeQuesContainer)").css({
        opacity: 0,
      });
      $(".ques-container:not(.activeQuesContainer)").addClass("visibility-hidden-no-opacity");
      this.setQuestionContainerStyleForSlidePreview();
      let d: any = document.querySelector(".container.ember");
      let _width = d["offsetWidth"];
      $(".question-section-container").css({
        transform: `translateX(-${_width * (this.currentPageIndex > 0 ? this.currentPageIndex : 0)}px)`,
      });
      $(".ques-container:not(.activeQuesContainer)").css({
        opacity: 1,
      });
      //recently updated

      document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.querySelector(".section-content") &&
        document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => {
        this.setSectionHeight();
        this.setQuestionContainerStyleForSlidePreview();
      }, 500);

      setTimeout(() => {
        let dd: any = document.querySelector(".container.ember");
        let _widthd = dd["offsetWidth"];
        $(".question-section-container").css({
          transform: `translateX(-${_widthd * (this.currentPageIndex > 0 ? this.currentPageIndex : 0)}px)`,
        });
      }, 900);
      
      setTimeout(() => {
        this.isSurveyLoading = false;
      }, 1700);
        
    }
    
    this.slideloaded = true;
  }

  /**
   * getting translate Y height for questions in case of focus and smooth transition type
   */
  getTranslateYHeight() {
    let h: any = 0;
    let allSections = $(".question-section-container.display-grid.transition-500").children();
    for (let i = 0; i < allSections.length; i++) {
      const element = allSections[i];
      if (!$(element).hasClass("activeQuesContainer")) {
        h = h + $(element).height();
      } else break;
    }
    return h;
  }

  /**
   * Class append for smooth and slide and focus transition type
   * @param type button type ( next or submit)
   * @param isInitial first time load or not
   */

  setQuestionSectionTransition(type?: any, isInitial?: any) {
    if (this.custom_theme.theme_layout != "classic") {
      if (this.custom_theme.theme_layout == "focus") {
        setTimeout(() => {
          this.registerScroll();
        }, 1000);
      }
      if (this.custom_theme.theme_layout == "slide") {
        if (this.isBuilder) {
          setTimeout(() => {
            this.workForSlideTransition();
          });
        } else {
          if(isInitial == "true"){
            this.isSurveyLoading = true;
          }
          this.workForSlideTransitionPreview();
        }
      } else if (this.custom_theme.theme_layout == "smooth_scroll") {
        this.setHeight();
        if (document.querySelector("app-question-section.activeQuesContainer")) {
          setTimeout(() => {
            // let z = document.querySelector(
            //   "app-question-section.activeQuesContainer"
            // )["offsetHeight"];

            let tH = this.getTranslateYHeight();

            $(".question-section-container").css({
              transform: `translateY(-${tH}px)`,
            });
            this.setQuestionContainerStyle();

            document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
            document.querySelector(".section-content") &&
              document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });
          }, 300);

          if (window.innerWidth < 768 && isInitial) {
            setTimeout(() => {
              // let z = document.querySelector(
              //   "app-question-section.activeQuesContainer"
              // )["offsetHeight"];
              let tH = this.getTranslateYHeight();
              $(".question-section-container").css({
                transform: `translateY(-${tH}px)`,
              });
              this.setQuestionContainerStyle();
              this.setHeight();
              document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
              document.querySelector(".section-content") &&
                document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });
            }, 1000);
          }
        }
      } else if (this.custom_theme.theme_layout == "focus" && this.clicked) {
        if ($(".activeQuesContainer")) {
          if (type == "next") {
            $(".activeQuesContainer").prev().addClass("slidingUp");
            $(".activeQuesContainer").addClass("isNext");
            setTimeout(() => {
              // make opacity of first question to 1
              if (this.isPreivewPage() && this.custom_theme.theme_layout == "focus") {
                let numbering = this.currentPage.questions[0].numbering;
                if ($("#" + numbering)[0]) {
                  $("#" + numbering).css("opacity", 1);
                }
                if ($("#okbt" + numbering)) {
                  $("#okbt" + numbering).css("opacity", 1);
                }
              }

              $(".ques-container:not(.activeQuesContainer)").addClass("hide");
            }, 100);
          } else {
            setTimeout(() => {
              $(".activeQuesContainer").addClass("isBack");
              $(".ques-container:not(.activeQuesContainer)").addClass("hide");
            });
          }
        }
        document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
        document.querySelector(".section-content") &&
          document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });
        this.setQuestionContainerStyle();

        clearTimeout(this.removeClassTimeout);
        this.removeClassTimeout = setTimeout(() => {
          $(".isNext").removeClass("isNext");
          $(".isBack").removeClass("isBack");
          $(".slidingUp").removeClass("slidingUp");
          $(".slidingDown").removeClass("slidingDown");
        }, 1000);
      }
    } else if (this.custom_theme.theme_layout == "_focus") {
      this.setQuestionContainerStyle();
      document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.querySelector(".section-content") &&
        document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });

      $(".activeQuesContainer").addClass("isBack");
      $(".ques-container:not(.activeQuesContainer)").addClass("hide");
    } else {
      this.setQuestionContainerStyle();
      document.querySelector("app-survey-preview div")?.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.querySelector(".section-content") &&
        document.querySelector(".section-content")?.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.querySelector("body")?.scrollIntoView()
    }
  }

  registerScroll() {
    let containerHeight: any = $('.fsop-container.launch-gutter').height();
    let middleOfContainerInOffset = containerHeight / 2 - 50;
    console.log(middleOfContainerInOffset);
    // $("#" + id)[0] ? $("#" + id)[0].scrollIntoView({ behavior: "smooth", block: "center" }) : "";

    $('.question-section-container .section .section-content').scroll(() => {
      $('.section-content ul li.content-background').toArray().forEach((el: any) => {
        let d: any = $(el).offset();
        if (d.top > 80 && d.top < middleOfContainerInOffset) {

          $(el).find('.previewMode').css('opacity', 1);
        } else {
          $(el).find('.previewMode').css('opacity', 0.3);
        }
      })
    });
  }

  /**
   * return true if the custom theme is applied
   */
  applyTheme() {
    try {
      return this.preview.design.design_theme == "custom" ? true : false;
    } catch (e) {
      return false;
    }
  }

  showReportView: boolean = false;
  individualReportCode: any;

  /**
   * API call for survey response
   * @param responseUrl survey response url
   * @param response previous response reference
   */
  private getSurveyResponse(responseUrl: string, response?: any): void {
    if (this.viaLink && document.cookie.includes(this.currentSurveyId)) {
      //here
      this.isSurveySubmitted = true;
      this.loading = false;
      this.assessmentData.setLoader({ bool: false });
    } else {
      let rtToken = this.route.snapshot.params.rtToken
      let apiToCall;
      if(rtToken) {
        let url = `${environment.BASE_URL}survey/${this.currentSurveyId}/responses/${this.currentResponseId}/${rtToken}/`
        apiToCall = this.assessmentData.getSurveyResponseAgainstRtToken(url);
      }else{
        apiToCall = this.assessmentData.getSurveyResponse(responseUrl);
      }
      apiToCall.subscribe(
        (surveyResponse: any) => {
          this.tempdata = surveyResponse;
          this.loading = false;
          if (response) {
            response["survey"] = surveyResponse["url"];
            response["url"] = responseUrl;
            this.currentSurveyId = surveyResponse["url"].split("/")[4];
            this.currentSurveyResponse = JSON.parse(JSON.stringify(response));
            localStorage.setItem("response", JSON.stringify(this.currentSurveyResponse));
          }
          // this.assessmentData.setLoader({bool:false, bgColor:'x'});
          this.preview = surveyResponse;
          this.currentPageIndex = this.preview.current_page;
          this.surveyDesign = surveyResponse.design;
          this.surveySettings = surveyResponse.setting;
          this.individualReportCode = surveyResponse.individual_report_code;
          this.showReportView = this.surveySettings.report.filter((setting: any) => {
            return setting["code"] == "show_report_completing_survey";
          })[0].value;

          if (surveyResponse.custom_design) {
            if (this.surveyDesign.design_theme) {
              surveyResponse.custom_design.forEach((obj: any) => {
                this.custom_theme[obj.code] = obj.value;
              });
              this.surveyDesign.design_theme == "custom" ? (this.custom_theme["apply_theme"] = true) : "";
              if (this.custom_theme["radio_button_color_change"]) {
                let style_css = `.previewMode .fsop-radio {
                -webkit-appearance: none;
                width: 16px;\
                min-width: 16px;
                height: 16px;
                min-height: 16px;
                position: relative;
                display: inline-block;
                border-radius: 50%;
                margin: 2px 0px;
                flex-shrink: 0;
              }

              .previewMode .fsop-radio:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                margin: auto;
                width: 100%;
                min-width: 100%;
                height: 100%;
                min-height: 100%;
                border-radius: 50%;
                border: 1px solid gray;
                transition: transform ease 280ms,background-color ease 280ms;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
                transform-origin: center;
              }

              .previewMode .fsop-radio:checked{
                animation-name: radio-animation;
                animation-duration: 0.14s;
              }
              .previewMode .fsop-radio:checked:before {
                border: 1px solid ${this.custom_theme["radio_button_color_change"]};
              }
              @keyframes radio-animation {
                0% {
                  box-shadow: 0 0 0px 1px #fff;
                }
                50% {
                  box-shadow: 0 0 4px 2px ${this.custom_theme["radio_button_color_change"]};
                }
                100% {
                  box-shadow: 0 0 0px 0px #fff;
                }
              }
              .previewMode .fsop-radio:checked:before {
                background: ${this.custom_theme["radio_button_color_change"]};
              }
                `;
                let common_style = `
                app-survey-preview fsop-multichoice .list-container input {
                  display: ${this.custom_theme["input_visibility"] ? "inline-block" : "none"} !important;
                }
                app-survey-preview fsop-multichoice .list-container.mcqSelected {
                  background: ${this.custom_theme["container_highlight_color"]
                    ? this.custom_theme["container_highlight_color"]
                    : "inherit"
                  } ;
                }


              `;
                this.appendStyle(style_css);
                this.appendStyle(common_style);
              }
            } else {
              this.custom_theme["apply_theme"] = false;
              if (window.innerWidth < 768) {
                $("body").css("overflow", "auto");
              }
            }
          }

          let index = this.getSettingIndex("appearance", "appereance_custom_close_message");
          if (this.surveySettings.appearance[index].value) {
            this.surveyCloseMessage =
              this.surveySettings.appearance[index].value == true
                ? "Thanks for participating! <br> <br> Your survey was successfully submitted."
                : this.surveySettings.appearance[index].value;
          } else {
            this.surveyCloseMessage = "Thanks for participating! <br> <br> Your survey was successfully submitted.";
          }

          this.totalQuestions = surveyResponse.question_count;
          this.sections = this.segmentSectionsIntoPages(surveyResponse["sections"]);
          this.cleanUnrequiredPlaceholders(this.sections);
          this.startSetUpForDisplayLogic();

          if (this.currentPageIndex > 0) {
            // run logic for all question before and on the current page
            this.runTillQuestionLogic();
          }

          this.currentPage = this.sections[this.currentPageIndex];
          this.replaceQuestioTextContactPlaceholders(this.currentPage?.questions);


          this.preventEditingResponse = this.surveySettings.access.filter((setting: any) => {
            return setting["code"] == "access_prevent_response_editing";
          })[0].value;

          // if (this.surveySettings.access[1].value) {
          //   this.currentPage["editable"] = false;
          // } else {
          //   this.currentPage["editable"] = true;
          // }

          // this.getDescriptiveQuestions();
          this.getAlreadyDoneQuestions();
          // this.progressStep = 100 / this.totalQuestions;
          this.progressStep = 100 / this.sections.length;

          if (this.currentPageIndex > 0) {
            this.setProgressBarWidth("initial");
          } else {
            this.progressState = 0;
          }
          this.countDoneQuestion();

          this.setQuestionContainerStyle();
          this.setQuestionSectionTransition("next","true");
          this.updatePlaceholders(this.currentQuestions, this.answeredQuestions);
          setTimeout(() => {
            this.setQuestionSectionTransition("next", "isInitial");
            // this.startObservingScreen()
            if (this.custom_theme["scrollbar_visibility"] == "auto_hide") {
              let scrollTimeout: any;
              document.querySelector("app-question-section .section-content")?.addEventListener("scroll", () => {
                document.querySelector("app-question-section")?.classList.remove("scroll-auto-hide");
                clearTimeout(scrollTimeout);
                scrollTimeout = setTimeout(() => {
                  document.querySelector("app-question-section")?.classList.add("scroll-auto-hide");
                }, 500);
              });
            }
          }, 1500);
        },
        (error) => {
          // this.assessmentData.setLoader({bool:false, bgColor:'x'});
          this.loading = false;
          this.ressourceNotFound = error.status === 404;
          if((error.status === 400) && (error.error.error == "You Have Already Submitted the Survey")){
            this.surveyLoadingError = error.error.error;
          }
          else if((error.status === 400) && (error.error.error == "Invalid Url")){
            this.surveyLoadingError = error.error.error;
          }
          else if((error.status === 400) && (error.error.detail)){
            this.surveyLoadingError = error.error.detail;
          }
          else{
            this.surveyLoadingError = error.status === 400 ? error.error.error : "";
          }
        }
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    // this.setQuestionContainerStyle();
    // this.setHeight();
    if(this.custom_theme.theme_layout == "slide"){
      // this.setQuestionContainerStyleForSlidePreview();
      // this.workForSlideTransitionPreview();
    }
  }

  /**
   * append css attributes to link tag
   */
  appendStyle(style_css: any) {
    let linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", "data:text/css;charset=UTF-8," + encodeURIComponent(style_css));
    document.head.appendChild(linkElement);
  }

  /**
   * fn will put answered question in answeredQuestions array
   */
  getAlreadyDoneQuestions() {
    this.answeredQuestions = [];
    this.sections.forEach((sec: any, _index: any) => {
      sec.questions.forEach((ques: any) => {
        if (ques._type == 3 && ques["answer"] && ques["answer"].length) {
          this.eliminateNullValues(ques);
        }
        if (ques.answer) {
          if (this.checkIfAnswered(ques)) {
            ques["answered"] = true;
            this.answeredQuestions.push(ques);
          }
        }
      });
    });
  }

  /**
   * filter question's answer for which the value is null
   */
  eliminateNullValues(question: any) {
    for (let i = 0; i < question["answer"].length; i++) {
      let ele = question["answer"][i];
      ele.column = ele.column.filter((val: any) => {
        return val != null;
      });
    }
  }

  /**
   * fn will check if the question is answered or not for all type of questions
   */

  private checkIfAnswered(ques: any) {
    switch (ques._type) {
      case 1:
        if (
          ques.answer.options.length > 0 ||
          (ques.answer.options.length == 0 && (ques.answer.other || ques.answer.not_observed))
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 2:
        if (ques.answer) {
          return true;
        } else {
          return false;
        }
        break;
      case 3:
        if (ques.answer.length > 0 && this.ansFilled(ques.answer)) {
          return true;
        } else {
          return false;
        }
        break;
      case 4:
        if (
          ques.answer.length > 0 &&
          ques.answer.filter((obj: any) => {
            return !obj["order"] || !obj["option"];
          }).length == 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 5:
        if (
          ques.answer.length > 0 &&
          ques.answer.filter((obj: any) => {
            return (obj["option"] && !obj["answer"] && obj["not_observed"]) || (obj["answer"] && !obj["not_observed"]);
          }).length == ques["question_options"].length
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 6:
        if (
          ques.answer.length > 0 &&
          ques.answer.filter((obj: any) => {
            return !obj["answer"] || !obj["option"];
          }).length == 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 7:
        if (
          ques.answer.length > 0 &&
          ques.answer.filter((obj: any) => {
            return !obj["answer"].length;
          }).length == 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 9:
        if (ques.answer) {
          return true;
        } else {
          return false;
        }
        break;
      default:
        return false;
    }

    // return true;
  }

  /**
   * check if answered for side by side question type
   */
  ansFilled(answer: any) {
    let no_ans_count = answer.filter((obj: any) => {
      return obj.column.length == 0;
    });
    return no_ans_count == 0 ? true : false;
  }

  /**
   * break section into pages on the basis of question pagebrake
   * @param sections section array
   */
  private segmentSectionsIntoPages(sections: any[]): any[] {
    let lastPageBreakIndex: number;
    let pagesPerSection: any[][] = [];
    let allSectionsQuestions: any[] = [];
    lastPageBreakIndex = 0;
    sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        question["section_name"] = section["name"];
        allSectionsQuestions.push(this.randomizeQuestionOptions(question));
      });
    });

    allSectionsQuestions.forEach((question: any) => {
      let page: any = {
        questions: [],
      };

      if (question.obj_type == "pagebrake") {
        page.questions = allSectionsQuestions.slice(lastPageBreakIndex, allSectionsQuestions.indexOf(question));
        lastPageBreakIndex = allSectionsQuestions.indexOf(question);
      } else if (allSectionsQuestions.indexOf(question) == allSectionsQuestions.length - 1) {
        page.questions = allSectionsQuestions.slice(lastPageBreakIndex, allSectionsQuestions.length);
      }

      if (page.questions.length > 0) {
        page.questions = page.questions.filter((question: any) => {
          return question.obj_type !== "pagebrake";
        });
        if (page.questions.length > 0){
          pagesPerSection.push(page);
        }
      }
    });

    let lastQNo = 0;
    pagesPerSection.forEach((page: any) => {
      let count = 0;
      page.questions.forEach((q: any, index: number) => {
        if (q._type == 8) {
          q["numbering"] = 0;
          if (index === page.questions.length - 1) {
            lastQNo += count;
          }
        } else {
          q["numbering"] = count + 1 + lastQNo;
          count++;
          if (index === page.questions.length - 1) {
            lastQNo += count;
          }
        }
      });
    });

    this.maxPages = pagesPerSection.length;
    return pagesPerSection;
  }

  /**
   * randomize question options for all type of question type
     if randomize_{question type}_question setting comes in survey settings
   */
  randomizeQuestionOptions(question: any) {
    let index;

    switch (question._type) {
      case 1:
        index = this.getSettingIndex("appearance", "randomize_multichoice_question");

        if (this.surveySettings.appearance[index].value) {
          if (question.question_options && question.question_options.length) {
            this.shuffle(question.question_options);
          }
        }
        break;
      case 3:
        index = this.getSettingIndex("appearance", "randomize_matrix_table_question");

        if (this.surveySettings.appearance[index].value) {
          if (
            question.question_options &&
            question.question_options.rows.length &&
            question.question_options.cols.length
          ) {
            this.shuffle(question.question_options.rows);
            this.shuffle(question.question_options.cols);
          }
        }
        break;
      case 4:
        index = this.getSettingIndex("appearance", "randomize_rank_order_question");

        if (this.surveySettings.appearance[index].value) {
          if (question.question_options && question.question_options.length) {
            this.shuffle(question.question_options);
          }
        }
        break;
      case 5:
        index = this.getSettingIndex("appearance", "randomize_dynamic_sum_question");
        // question.validation_option['to_observe'] = true;
        if (this.surveySettings.appearance[index].value) {
          if (question.question_options && question.question_options.length) {
            this.shuffle(question.question_options);
          }
        }
        break;
      case 6:
        // index = this.getSettingIndex('appearance','randomize_drill_down_question');
        // if (this.surveySettings.appearance[index].value) {
        //   if(question.question_options && question.question_options.length){
        //     this.shuffle(question.question_options)
        //   }
        // }
        break;
      case 7:
        index = this.getSettingIndex("appearance", "randomize_side_by_side_question");
        if (this.surveySettings.appearance[index].value) {
          if (
            question.question_options &&
            question.question_options.rows.length &&
            question.question_options.cols.length
          ) {
            this.shuffle(question.question_options.rows);
            // this.shuffle(question.question_options.cols);
          }
        }
        break;
    }
    return question;
  }

  /**
   * shuffle values of an array
   */
  shuffle(array: any) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  }

  /**
   * fn will set count of done questions, It will check if the question is done for all type of questions
     and it will take question as done only if the question passes the validations
   */
  public countDoneQuestion(): void {
    this.counterDoneQuestions = 0;

    let incrementQuestionsDoneCounter = () => {
      ++this.counterDoneQuestions;
      // setProgressBarWidth();
    };

    // let setProgressBarWidth = () => {
    //   this.progressState = this.progressStep * this.counterDoneQuestions;
    // }

    new Promise(() => {
      this.sections.forEach((section: any, index: number) => {
        section.questions.forEach((question: any) => {
          // if (question._type == 8){
          //   let _class = `ques_${question.section_pos}_${question.position}`
          //   if(this.descriptiveQuestions.indexOf(_class) > -1 ) {
          //     incrementQuestionsDoneCounter();
          //   }
          // }

          if (question.answer) {
            switch (question._type) {
              case 1:
                if (question.hasOwnProperty("answer")) {
                  // if(question.answer !== null){
                  if (
                    question.answer.hasOwnProperty("options") ||
                    question.answer.hasOwnProperty("other") ||
                    question.answer.hasOwnProperty("not_observed")
                  ) {
                    if (question.option_type == 2) {
                      if (
                        (question.answer.options.length > 0 &&
                          (typeof question.answer.other == "undefined" ||
                            (question.answer.other && question.answer.other.trim()))) ||
                        (question.answer.options.length == 0 && question.answer.not_observed)
                      ) {
                        incrementQuestionsDoneCounter();
                      }
                    } else {
                      if (
                        question.answer.options.length > 0 ||
                        (question.answer.options.length == 0 && (question.answer.other || question.answer.not_observed))
                      ) {
                        incrementQuestionsDoneCounter();
                      }
                    }
                  }
                  // }
                }
                break;

              case 2:
                if (question.hasOwnProperty("answer")) {
                  // if(question.answer !== null){
                  if (question.answer.length > 0) {
                    incrementQuestionsDoneCounter();
                  }
                  // }
                }
                break;

              case 3:
                let filledRows = question.answer.filter((a: any) => {
                  return a.column.length > 0 && a.row !== null;
                });

                if (question.question_options.rows.length === filledRows.length) {
                  incrementQuestionsDoneCounter();
                }
                break;

              case 4:
                let fetchNullAnswer = question.answer.filter((a: any) => {
                  return !a.option || !a.order;
                });

                if (fetchNullAnswer.length === 0 && question.answer.length === question.question_options.length) {
                  incrementQuestionsDoneCounter();
                }
                break;

              case 5:
                let nullAnswers = question.answer.filter((a: any) => {
                  return a.answer === null || a.answer === "" || a.option === null;
                });

                if (
                  (nullAnswers.length === 0 && question.question_options.length === question.answer.length) ||
                  (nullAnswers.length == question.question_options.length &&
                    question.validation_option.to_observe &&
                    question.answer[0]["not_observed"])
                ) {
                  incrementQuestionsDoneCounter();
                }
                break;

              case 6:
                let nulls = question.answer.filter((a: any) => {
                  return !a.option || !a.answer;
                });

                if (nulls.length === 0 && question.answer.length === question.question_options.length) {
                  incrementQuestionsDoneCounter();
                }

                break;

              case 7:
                if (
                  question.answer.filter((ans: any) => {
                    return !ans.answer.length;
                  }).length == 0 &&
                  question.answer.length >=
                  question.question_options.rows.length * question.question_options.cols.length
                ) {
                  incrementQuestionsDoneCounter();
                }
                break;

              case 9:
                if (question.answer) {
                  incrementQuestionsDoneCounter();
                }
                break;

              default:
                break;
            }
            // setProgressBarWidth();
          }
        });
      });
    });
  }

  /**
   * fn will replace contact data placeholders and all choices placeholders
   */

  replaceQuestioTextContactPlaceholders(quests) {

    let response = localStorage.getItem("response");
    const contactData = response ? JSON.parse(response).contact_detail : null;

    this.contactData = {
      firstName: contactData?.first_name || '',
      lastName: contactData?.last_name || '',
      fullName: `${contactData?.first_name || ''} ${contactData?.last_name || ''}`,
      email: contactData?.email || ''
    };
    // Function to resolve placeholders correctly
    const resolveText = (text: string): string => {
        // Only replace contact placeholders in text that are not inside `{{}}` (e.g., in regular question text)
        text = text.replace(/\{{ParticipantFirstName}}/g, this.contactData.firstName)
                   .replace(/\{{ParticipantLastName}}/g, this.contactData.lastName)
                   .replace(/\{{ParticipantEmail}}/g, this.contactData.email)
                   .replace(/\{{ParticipantFullName}}/g, this.contactData.fullName);

        // Flatten the questions array while keeping track of index within section
        const allQuestions = this.sections.flatMap(section => 
            section.questions.map((qItem, index) => ({ ...qItem, computedIndex: index + 1 })) // 1-based index
        );  

        // text = text.replace(/{{(\d+)\.QuestionText}}/g, (_, tag) => {
        //   const referencedQ = allQuestions.find(qItem => `${qItem.numbering}` === tag);
      
        //   if (referencedQ) {
        //       let cleanText = referencedQ.text.replace(/<[^>]*>/g, "").trim(); // Remove HTML tags
        //       return cleanText.replace(/{{.*?}}/g, ""); // Ensure nested placeholders are removed
        //   }
      
        //   return "____"; // Placeholder not found
        // });
        // question.text.replace(/\{{Q\d+\.ID(\d+)\.([^}]+)\}}/g
        text = text.replace(/{{Q\d+\.ID(\d+)\.QuestionText}}/g, (_, tag) => {
          const referencedQ = allQuestions.find(qItem => {
              const match = qItem.url.match(/(\d+)\/?$/); // Extract last number from URL
              return match[1] === tag;
          });
      
          if (referencedQ) {
              let cleanText = referencedQ.text.replace(/<[^>]*>/g, "").trim(); // Remove HTML tags
              return cleanText.replace(/{{.*?}}/g, ""); // Ensure nested placeholders are removed
          }
      
          return "____"; // Placeholder not found
        });

        text = text.replace(/{{Q\d+\.ID(\d+)\.AllChoices}}/g, (_, tag) => {
          const referencedQ = allQuestions.find(qItem => {
              const match = qItem.url.match(/(\d+)\/?$/); // Extract last number from URL
              return match[1] === tag;
          });
      
          if (referencedQ && referencedQ.question_options) {
            return referencedQ.question_options
                .map(option => option.text)  // Extract 'text' from each option
                .join(" ");                  // Join as a space-separated string
          }
      
          return "____"; // Placeholder not found or no choices available
        });

        return text;
    };

    // Apply placeholders text to all questions
    this.currentQuestions = quests.map(q => ({
        ...q,
        text: resolveText(q.text) // Ensure full replacement for multiple instances
    }));
  }


  /**
   * fn will replace placeholder text after page break
   */

  updatePlaceholders(currentPage: any, answeredQuestions: any[]) {
    const allQuestions = this.sections.flatMap(section => section.questions);

    currentPage.forEach((page) => {
        if (typeof page.text === "string" && page.text.includes("{{Q")) {
            
            // If there are answered questions, process them
            if (answeredQuestions.length > 0) {
                answeredQuestions.forEach((answered) => {
                    const numbering = answered.url.match(/\/surveyquestions\/(\d+)\//)[1];
                    const selectedTag = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.SelectedChoices}}`, 'g');
                    
                    // const unselectedTag = new RegExp(`\\{{Q${numbering}\.UnselectedChoices\\}}`, 'g');
                    // const questionTextValueTag = new RegExp(`\\{{Q${numbering}\.QuestionTextValue\\}}`, 'g');
                    // const otherTextEntry = new RegExp(`\\{{Q${numbering}\.ChoiceTextEntryValue\\}}`, 'g');

                    const unselectedTag = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.UnselectedChoices}}`, 'g');
                    const questionTextValueTag = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.QuestionTextValue}}`, 'g');
                    const otherTextEntry = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.ChoiceTextEntryValue}}`, 'g');
                
                    const selectedOptionUrls = answered.answer?.options || [];
                    const allOptions = Array.isArray(answered.question_options) ? answered.question_options : [];
                    const selectedOptions = allOptions.filter(opt => selectedOptionUrls.includes(opt.url));
                    const unselectedOptions = allOptions.filter(opt => !selectedOptionUrls.includes(opt.url));

                    // Generate selected and unselected text
                    const selectedText = selectedOptions.map(opt => opt.text).join(" ") 
                        + (answered?.answered && answered?.validation_option?.is_comment_box && answered?.answer?.other ? ' other' : '')
                        + (answered?.validation_option?.to_observe && answered?.answer?.not_observed ? ' No opportunity to observe' : '');

                    const unselectedText = unselectedOptions.map(opt => opt.text).join(" ")
                        + (answered?.validation_option?.is_comment_box && !answered?.answer?.other ? ' other' : '')
                        + (answered?.validation_option?.to_observe && !answered?.answer?.hasOwnProperty('not_observed') ? ' No opportunity to observe' : '');

                    const questionTextValue = typeof answered.answer === "string" ? answered.answer : "";
                    const otherTextEntryValue = answered?.answer?.other || "";

                    // Replace placeholders
                    page.text = page.text
                        .replace(selectedTag, selectedText)
                        .replace(unselectedTag, unselectedText)
                        .replace(questionTextValueTag, questionTextValue)
                        .replace(otherTextEntry, otherTextEntryValue);
                });
               

                // const unmatchedTags = [...page.text.matchAll(/{{Q\d+\.(\d+)\.UnselectedChoices}}/g)];
                // unmatchedTags.forEach((match) => {
                //     const questionNumber = match[1];
                //     const unselectedTag = new RegExp(`{{Q\\d+\\.${questionNumber}\\.UnselectedChoices}}`, 'g');

                //     allQuestions.forEach((question) => {
                //         const matchedObj = this.currentPage.questions.find(item => {
                //             const itemIdMatch = item.url.match(/(\d+)\/$/); // Extracting ID from URL
                //             return itemIdMatch && itemIdMatch[1] === questionNumber;
                //         });

                //         const questionIdMatch = question.url.match(/(\d+)\/$/); // Extracting ID from question URL

                //         if (questionIdMatch && questionIdMatch[1] === questionNumber && !(matchedObj && matchedObj.url.endsWith(`/${questionNumber}/`))) {
                //             const allOptions = Array.isArray(question.question_options) ? question.question_options : [];
                //             let allChoicesText = allOptions.map(opt => opt.text).join(" ");
                //             allChoicesText += question?.validation_option?.is_comment_box ? ' other' : '';
                //             allChoicesText += question?.validation_option?.to_observe ? ' No opportunity to observe' : '';
                //             page.text = page.text.replace(unselectedTag, allChoicesText);
                //         }
                //     });
                // });


            } else {
                // No answered questions, process all questions
                allQuestions.forEach((question) => {
                    const numbering = question.url.match(/\/surveyquestions\/(\d+)\//)[1];

                    const selectedTag = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.SelectedChoices}}`, 'g');
                    const unselectedTag = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.UnselectedChoices}}`, 'g');
                    const questionTextValueTag = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.QuestionTextValue}}`, 'g');
                    const otherTextEntry = new RegExp(`\\{{Q\\d+\\.ID${numbering}\\.ChoiceTextEntryValue}}`, 'g');

                    const allOptions = Array.isArray(question.question_options) ? question.question_options : [];
                    let allChoicesText = allOptions.map(opt => opt.text).join(" ");
                    allChoicesText += question?.validation_option?.is_comment_box ? ' other' : '';
                    allChoicesText += question?.validation_option?.to_observe ? ' No opportunity to observe' : '';

                    // const matchedObj = this.currentPage.questions.find(item => item.numbering == numbering);
                    // if (question.numbering == numbering && matchedObj?.numbering != numbering) {
                    //     page.text = page.text.replace(unselectedTag, allChoicesText);
                    // }

                    // Clear other placeholders
                    page.text = page.text
                        .replace(selectedTag, "")
                        .replace(questionTextValueTag, "")
                        .replace(otherTextEntry, "");
                });
            }

            // Remove any remaining placeholders
            // page.text = page.text.replace(/{{Q\d+\.\w+\}}/g, "");
            page.text = page.text.replace(/{{Q\d+\.ID\d+\.\w+}}/g, "");
        }
    });
    
    this.currentQuestions = currentPage;
  }

  /**
   * fn will submit the survey data whenever user switches the pages
   */

  
  public doSubmitSurvey(): void {
    ++this.currentPageIndex;
    let nextPage: any = this.sections[this.currentPageIndex];
    this.isNextButtonClicked = false;
    this.autoProgressMade = false;
    // let _completedSurveyAnswer: any[] = [];
    // this.answeredQuestions.forEach(a => {
      //   _completedSurveyAnswer.push({
        //     answer: a.answer,
        //     question: a.url
        //   });
        // });
        
        if (nextPage !== undefined) {
          this.currentPage = nextPage;          
          this.replaceQuestioTextContactPlaceholders(this.currentPage?.questions);
          this.updatePlaceholders(this.currentQuestions, this.answeredQuestions);
          this.saveSurveyResponse();
      // this.assessmentData.setLoader({ bool: false });

      // setTimeout(() => {
      // this.startObservingScreen()
      // })
    } else if (this.responseMode) {
      // we can send answers
      this.submitSurvey();
    } else {
      localStorage.removeItem("response");
      //here
      this.isSurveySubmitted = true;
      this.assessmentData.setLoader({ bool: false });
      this.updateResponseStatus(2);
      // localStorage.removeItem("previewState");
      let index = this.getSettingIndex("experience", "experience_redirect_url");

      if (index > -1) {
        let redirectURL: string = this.dynamicRedirectURLFromLogic || this.surveySettings.experience[index].value;
        if (redirectURL != null && redirectURL != 'none') {
          redirectURL = this.appendVariableIfAny(redirectURL);
          setTimeout(() => {
            window.open(redirectURL, "_self");
          }, 3000);
        }
      }
    }
    setTimeout(() => {
      // this.startObservingScreen()

      this.setQuestionSectionTransition("next");
      // window.scrollTo(0,0);
      // document.getElementsByClassName('progressbar')[0].scrollIntoView();
    });
    if (nextPage == undefined && this.responseMode) {
      this.setProgressBarWidth(null, true);
    } else {
      this.setProgressBarWidth();
    }
  }

  /**
   * fn will call when user click on sumit button of survey
     fn will call submit API and redirect or show message according to configuration set on survey builder
   */
  submitSurvey() {
    this.canSubmit = true;

    let completedSurveyAnswer: any[] = [];
    this.answeredQuestions.forEach((a) => {
      completedSurveyAnswer.push({
        answer: a.answer,
        question: a.url,
      });
    });
    //incomplete
    this.assessmentData.submitSurveyAnswers(this.currentSurveyResponse.url, 0, 1, completedSurveyAnswer).subscribe(
      (response) => {
        
        this.dispatchCompletetionToParentofIframe();

        this.assessmentData.setLoader({ bool: false });
        localStorage.removeItem("response");
        //here
        this.updateResponseStatus(2);
        this.isSurveySubmitted = true;
        let index = this.getSettingIndex("experience", "experience_redirect_url");
        if (index > -1) {
          let redirectURL: string = this.dynamicRedirectURLFromLogic || this.surveySettings.experience[index].value;
          if (redirectURL != null && redirectURL != 'none') {
            redirectURL = this.appendVariableIfAny(redirectURL);
            setTimeout(() => {
              window.open(redirectURL, "_self");
            }, 3000);
          }
        }
      },
      (error) => {
        this.loading = false;
        this.assessmentData.setLoader({ bool: false });
        this.ressourceNotFound = error.status === 404;
        // this.surveyLoadingError = error.status === 400 ?  error.error.detail : ''
        // this.assessmentData.setLoader({bool:false, bgColor:'x'});
        // this.surveyLoadingError = error.error.error_msg;
      }
    );
  }


  dispatchCompletetionToParentofIframe(){
      var myCustomData = { foo: 'bar' }
      var event = new CustomEvent('completed', { detail: myCustomData })
      window.parent.document.dispatchEvent(event)
  }

  /**
   * fn will update progress bar width with next button click
   */
  setProgressBarWidth(type?: any, preventSubmit?: boolean) {
    if (this.progressStep * this.currentPageIndex < 100) {
      this.progressState = this.progressStep * this.currentPageIndex;
    } else {
      // if (!this.isSurveySubmitted) {
      // this.submitSurvey();

      // here

      if (!preventSubmit) {
        this.canSubmit = true;
        localStorage.removeItem("response");
        this.isSurveySubmitted = true;
        if (type) {
          this.updateResponseStatus(2);
        }
        let index = this.getSettingIndex("experience", "experience_redirect_url");

        if (index > -1) {
          let redirectURL: string = this.dynamicRedirectURLFromLogic || this.surveySettings.experience[index].value;
          if (redirectURL != null && redirectURL != 'none') {
            redirectURL = this.appendVariableIfAny(redirectURL);
            setTimeout(() => {
              window.open(redirectURL, "_self");
            }, 3000);
          }
        }
      }
    }
  }

  /**
   * fn will call everytime when user answer a question to check if user need to move to next page 
     when autoprogress has been set in survey config settings
   */
  checkNextSecQuestions(action?: any) {
    let ans = false;
    let isNoSkipHide: boolean = false;
    let isNoBranchHide: boolean = false;
    let isNoDisplayHide: boolean = false;
    let cpIndex = action == "plusOne" ? this.currentPageIndex + 1 : this.currentPageIndex - 1;
    if (this.sections[cpIndex] && this.sections[cpIndex].questions && this.sections[cpIndex].questions.length) {
      let sec = this.sections[cpIndex];
      let qsn = sec["questions"];
      qsn.forEach((ques: any) => {
        if (!ques.hide_via_skip) {
          isNoSkipHide = true;
        }
        if (!ques.hide_via_branch) {
          isNoBranchHide = true;
        }
        if (!ques.hide) {
          isNoDisplayHide = true;
        }
      });
      if (!isNoSkipHide || !isNoBranchHide || !isNoDisplayHide) {
        this.currentPageIndex = action == "plusOne" ? this.currentPageIndex + 1 : this.currentPageIndex - 1;
        if (action == "plusOne") this.next();
        else {
          this.back();
        }
        ans = true;
      }
    }
    return ans;
  }

  clicked: boolean = false;
  /**
   * fn called on click of next button
     it will check if the autoprogress is set or not if set then after checking the valdiaton of filled question
     it will redirect user to next page
   */
  public next(isAutoProgress?: any, clicked?: any): void {
    if (this.checkNextSecQuestions("plusOne")) {
      return;
    }
    if (clicked) this.clicked = true;

    delete this.backClicked;
    this.countDoneQuestion();
    this.assessmentData.setLoader({ bool: true });
    this.assessmentData.getSurveyStatus(this.currentSurveyId).subscribe(
      (survey: any) => {
        if (survey.survey_status !== "launched" && this.responseMode) {
          this.surveyLoadingError = "Sorry, this survey is not currently active.";
          this.assessmentData.setLoader({ bool: false });
        } else {
          this.isNextButtonClicked = true;
          setTimeout(() => {
            if (isAutoProgress) {
              this.doSubmitSurvey();
              this.setHeight();
            } else {
              if (this.checkQuestionsValidation()) {
                this.doSubmitSurvey();
                this.setHeight();
              } else {
                this.setHeight();
                this.autoProgressMade = false;
                this.assessmentData.setLoader({ bool: false });
                this.scrollToError();
              }
            }
          }, 900);
          // document.getElementsByClassName('section-content')[0].scrollTo(0,0);
        }

        if (this.isRealTime) {
          setTimeout(() => {
            this.cd.detectChanges();
          }, 1000);
        }
      },
      (error: HttpErrorResponse) => {
        this.autoProgressMade = false;
        this.setHeight();
        this.scrollToError();
        this.assessmentData.setLoader({ bool: false });
      }
    );
  }

  scrollErrorTimeout: any;

  /**
   * fn will redirect user to that question which has some validation error when user tries to go to next page or
  try to sumbit the survey
   */
  scrollToError() {
    clearTimeout(this.scrollErrorTimeout);
    this.scrollErrorTimeout = setTimeout(() => {
      let ele:any;
      if(document.getElementsByClassName("invalid-answer")[0] && !document.getElementsByClassName("error_container")[0]){
        ele = document.getElementsByClassName("invalid-answer")[0];
      }
      else{
        ele = document.getElementsByClassName("error_container")[0];
      }
      if (ele) {
        let d: any = $(ele).offset();
        let s: any = $(".activeQuesContainer .section-content").offset();
        let a = d["top"];
        let b = s["top"];
        let c: any = $(".activeQuesContainer .section-content").scrollTop();

        if (document.body.style.overflow != "hidden") {
          window.scroll({ top: a - 100, left: 0, behavior: "smooth" });
          return;
        } else if (window.innerWidth < 768) {
          ele.scrollIntoView({ behavior: "smooth" });
          return;
        } else {
          if(this.custom_theme["eliminate_the_container"]){
            window.scroll({ top: a - b + c - 30, left: 0, behavior: "smooth" });
          }
          else{
            $(".section-content").animate(
              {
                scrollTop: a - b + c - 30,
              },
              1000
            );
          }
          return;
        }
      }
    }, 800);
  }

  /**
   * reset next button click valdiation
   */
  public doResetNextButton($event: boolean): void {
    this.isNextButtonClicked = false;
  }

  backClicked: any;

  /**
   * fn will call on back button click
     it will redirect to previous page if available
   */
  public back(): void {
    // this.assessmentData.setLoader({bool: true});
    if (this.checkNextSecQuestions()) {
      return;
    }
    --this.currentPageIndex;
    let prevPage: any = this.sections[this.currentPageIndex];
    if (prevPage !== undefined) {
      this.backClicked = true;
      this.currentPage = prevPage;
      this.replaceQuestioTextContactPlaceholders(this.currentPage?.questions);
      this.updatePlaceholders(this.currentQuestions, this.answeredQuestions);
      // this.saveSurveyResponse();
      // this.assessmentData.setLoader({bool: false});

      // setTimeout(() => {
      //   this.startObservingScreen()
      // })

      // we have to fetch done question and set them to non-editable
      // if (this.surveySettings.access[1].value) {

      //   this.currentPage["editable"] = false;
      // } else {
      //   this.currentPage["editable"] = true;
      // }
    } else {
      // this.assessmentData.setLoader({bool: false});
    }
    if (this.currentPageIndex > 0) {
      this.setProgressBarWidth();
    } else {
      this.progressState = 0;
    }

    this.setQuestionSectionTransition("back");
    this.cd.detectChanges();
    if (this.isPreivewPage() && this.custom_theme.theme_layout == "focus") {
      let numbering = this.currentPage.questions[0].numbering;
      if ($("#" + numbering)[0]) {
        $("#" + numbering).css("opacity", 1);
      }
      if ($("#okbt" + numbering)) {
        $("#okbt" + numbering).css("opacity", 1);
      }
    }
    // document.getElementsByClassName('section-content')[0].scrollTo(0,0);
  }

  /**
   * fn return current year
   */
  getCurrentYear() {
    return new Date().getFullYear();
  }

  onDoneQuestionUpdatedArg: any = null;

  /**
   * fn will call when a aleady done question has been updated with another answer
   */
  public onDoneQuestionsUpdated(answeredQuestions: any[]): void {
    delete this.backClicked;
    this.onDoneQuestionUpdatedArg = answeredQuestions;
    this.answeredQuestions = answeredQuestions["answered_ques"];
    if (this.answeredQuestions.length && this.sections[this.currentPageIndex]["questions"].length) {
      this.answeredQuestions.forEach((ans) => {
        let index = this.sections[this.currentPageIndex]["questions"].findIndex((ques: any) => {
          return ques.url == ans.url;
        });
        if (index > -1) {
          this.sections[this.currentPageIndex]["questions"][index]["answer"] = ans["answer"];
        }
      });
    }
    if (this.answeredQuestions.length == 1 && this.responseMode) {
      this.updateResponseStatus(1);
    }
    this.countDoneQuestion();

    // we test if the auto next page setting is activated

    if (this.custom_theme["experience_auto_next_page"]) {
      this.clicked = true;
      if (
        ((this.getParticularQuestionCount(1) == 1 &&
          this.getParticularQuestionCount(1) + this.getParticularQuestionCount(8) ==
          this.currentPage.questions.length) ||
          (this.getParticularQuestionCount(3) == 1 &&
            this.getParticularQuestionCount(3) + this.getParticularQuestionCount(8) ==
            this.currentPage.questions.length) ||
          (this.getParticularQuestionCount(7) == 1 &&
            this.getParticularQuestionCount(7) + this.getParticularQuestionCount(8) ==
            this.currentPage.questions.length)) &&
        answeredQuestions["_ques"] &&
        answeredQuestions["_ques"]["_type"] &&
        ((answeredQuestions["_ques"]["_type"] == 1 && answeredQuestions["_ques"]["option_type"] != 2) ||
          answeredQuestions["_ques"]["_type"] == 3 ||
          answeredQuestions["_ques"]["_type"] == 7) &&
        this.checkQuestionsValidation("auto_progress") &&
        !this.autoProgressMade
      ) {
        this.autoProgressMade = true;
        this.next("auto_progress");
      }

      // done to add multiple questions scrolling effect
      if (
        (this.getParticularQuestionCount(1) >= 1 ||
          this.getParticularQuestionCount(3) >= 1 ||
          this.getParticularQuestionCount(7) >= 1) &&
        answeredQuestions["_ques"] &&
        answeredQuestions["_ques"]["_type"] &&
        ((answeredQuestions["_ques"]["_type"] == 1 && answeredQuestions["_ques"]["option_type"] != 2) ||
          answeredQuestions["_ques"]["_type"] == 3 ||
          answeredQuestions["_ques"]["_type"] == 7) &&
        !this.autoProgressMade
      ) {
        //change here
        if (
          this.currentPage.questions[this.currentPage.questions.length - 1].numbering ==
          answeredQuestions["_ques"].numbering &&
          this.checkQuestionsValidation("auto_progress")
        ) {
          this.autoProgressMade = true;
          this.next("auto_progress");
        } else {
          if (answeredQuestions["_ques"]["answered"]) {
            if (
              this.currentPage.questions[this.currentPage.questions.length - 2].numbering ===
              answeredQuestions["_ques"].numbering &&
              answeredQuestions["_ques"].option_position_type == 2 &&
              (this.custom_theme.theme_layout == "smooth_scroll" || this.custom_theme.theme_layout == "focus")
            ) {
              this.nextQues(answeredQuestions["_ques"].numbering + 1);
            } else {
              // if(this.custom_theme.theme_layout != "focus"){
              //   this.nextQues(answeredQuestions["_ques"].numbering + 1)
              // }
              this.nextQues(answeredQuestions["_ques"].numbering + 1);
            }
          }
        }
      }
    }
    // **code added for smooth scroll type**
    // else if (this.custom_theme.theme_layout == "smooth_scroll") {
    //   // done to add multiple questions scrolling effect
    //   if (
    //     (this.getParticularQuestionCount(1) > 1 ||
    //       this.getParticularQuestionCount(3) > 1) &&
    //     answeredQuestions["_ques"] &&
    //     answeredQuestions["_ques"]["_type"] &&
    //     ((answeredQuestions["_ques"]["_type"] == 1 &&
    //       answeredQuestions["_ques"]["option_type"] != 2) ||
    //       answeredQuestions["_ques"]["_type"] == 3) &&
    //     !this.autoProgressMade
    //   ) {
    //     if (
    //       this.currentPage.questions[this.currentPage.questions.length - 1]
    //         .numbering == answeredQuestions["_ques"].numbering &&
    //       this.checkQuestionsValidation("auto_progress")
    //     ) {
    //       // this.autoProgressMade = true;
    //       // this.next();
    //     } else {
    //       if (answeredQuestions["_ques"]["answered"]) {
    //         if (
    //           this.currentPage.questions[this.currentPage.questions.length - 2]
    //             .numbering === answeredQuestions["_ques"].numbering &&
    //           answeredQuestions["_ques"].option_position_type == 2 &&
    //           this.custom_theme.theme_layout == "smooth_scroll"
    //         ) {
    //         } else {
    //           this.nextQues(answeredQuestions["_ques"].numbering + 1);
    //         }
    //       }
    //     }
    //   }
    // }
  }

  /**
   * check if page is with preview or resp url
   */
  public isPreivewPage() {
    return window.location.href.includes("preview") || window.location.href.includes("resp");
  }

  /**
   * when transistion type focus has been select, to focus user with next question and other question opacity low
   */
  nextQues(id: any) {
    setTimeout(() => {
      // focus next question opacity to 1
      if (this.isPreivewPage() && this.custom_theme.theme_layout == "focus") {
        for (let i = 1; i < this.totalQuestions; i++) {
          if ($("#" + i)[0]) {
            $("#" + i).css("opacity", 0.3);
            if ($("#okbt" + i)) {
              $("#okbt" + i).css("opacity", 0);
            }
          }
        }
        if ($("#" + id)[0]) {
          $("#" + id).css("opacity", 1);
          if ($("#okbt" + id)) {
            $("#okbt" + id).css("opacity", 1);
          }
        }
      }

      $("#" + id)[0] ? $("#" + id)[0].scrollIntoView({ behavior: "smooth", block: "center" }) : "";
    }, 600);
  }

  /**
   * fn return number of particular questions in survey by passing type of question
   */
  getParticularQuestionCount(type: any) {
    return this.currentPage.questions.filter((ques: any) => {
      return ques._type == type;
    }).length;
  }

  /**
   * save data of survey till survey filled
   */
  saveSurveyResponse(ques?: any): void {
    if (!this.checkQuestionsValidation()) {
      this.setHeight();
      this.scrollToError();
    } 
    this.assessmentData.setLoader({ bool: true });
    this.isSaving = true;
    let answers: any[] = [];

    if (ques && ques != "btnclick") {
      let ans = ques.answer;
      if (ques.answer.options && !ques.answer.options.length && ques.answer.other) {
        // ans['options'] = null;
      }
      // answers.push({
      //   answer: ans,
      //   question: ques.url
      // });
      let obj = {
        answer: ans,
        question: ques.url,
      };
      let updatedObj: any;
      ques["country_code"] ? (updatedObj = { ...obj, country_code: ques["country_code"] }) : "";
      answers.push(updatedObj ? updatedObj : obj);
    } else {
      // if (ques == 'btnclick') {
      //   let x = this.checkQuestionsValidation();
      // }
      this.answeredQuestions.forEach((q: any) => {
        let ans = q.answer;
        if (q.answer.options && !q.answer.options.length && q.answer.other) {
          // ans['options'] = null;
        }
        let obj = {
          answer: ans,
          question: q.url,
        };
        let updatedObj: any;
        q["country_code"] ? (updatedObj = { ...obj, country_code: q["country_code"] }) : "";
        answers.push(updatedObj ? updatedObj : obj);
      });
    }
    // let temparr = this.tempdata.setting.experience;
    // this.SaveAndResume = temparr[0].value;
    // if(!this.SaveAndResume){
    // this.toastrService.success("Your survey has been successfully saved!.")
    // }

    if (this.responseMode) {
      // this.scrollToError();
      //incomplete
      this.assessmentData
        .submitSurveyAnswers(this.currentSurveyResponse.url, this.currentPageIndex, this.maxPages, answers)
        .subscribe({
          next: (response) =>{
            debugger;
            this.assessmentData.setLoader({ bool: false });
            this.isSaving = false;
            let temparr = this.tempdata.setting.experience;
            this.SaveAndResume = temparr[0].value;
            if (this.SaveAndResume && ques == "btnclick") {
              this.openAlertForSaveAndResumeNotification();
            }
            if (!this.SaveAndResume) {
              if (ques !== undefined) {
                this.toastrService.success("Your survey has been successfully saved.");
              }
            } else {
              this.getEmailValue(ques);
            }
          },
          error: (err) =>{
            // this.scrollToError();
            // this.toastrService.error(`${err.error.error_msg}`);
            this.assessmentData.setLoader({ bool: false });
            this.isSaving = false;
            this.getEmailValue(ques);
          }
        })
    } else {
      // setTimeout(() => {
      // this.scrollToError();
      this.assessmentData.setLoader({ bool: false });
      this.isSaving = false;
      this.getEmailValue(ques);
      // }, 700);
    }
  }

  @ViewChild("saveAndResumeModal", { static: false }) saveAndResumeModal: NgbModalRef;
  modalref1: any;

  /**
   * fn will open a modal for user to enter email address while using save and resume feature
   */
  openAlertForSaveAndResumeNotification() {
    this.modalref1 = this.modalService.open(this.saveAndResumeModal, {
      centered: true,
      keyboard: false,
      backdrop: true,
      windowClass: "xlModal",
      size: "lg",
    });
  }

  contactFormEmail: any;
  contactEmail: any;
  emailInvalid: any = "";
  showEmailContainer: any;

  /**
   * fn will return the email address filled in first text entry question in first section 
     and will use to enter in save and resume modal 
   */
  getEmailValue(type: any) {
    if (this.responseMode) {
      let contactFormEmails = this.answeredQuestions.filter((ques: any) => {
        return (
          ques.answer !== "" && 
          ques._type == 2 &&
          ques.custom_validation.is_custom_validation &&
          ques.custom_validation.validation_type == "email"
        );
      });
      this.contactFormEmail = contactFormEmails[contactFormEmails.length - 1];

      let response = localStorage.getItem("response");
      const rateeEmail = response ? JSON.parse(response).ratee_contact_details?.email : null;

      // if (this.contactFormEmail) {
      if(this.contactFormEmail){
        this.contactEmail = this.contactFormEmail?.answer !== "" ? this.contactFormEmail.answer : rateeEmail;
      }
      else{
        this.contactEmail = rateeEmail;
      }
      if (type == "btnclick" && !this.SaveAndResume) {
        this.showEmailContainer = true;
        setTimeout(() => {
          if (
            this.contactEmail &&
            !this.contactFormEmail.customValidError &&
            !this.contactFormEmail.validationError &&
            !this.contactFormEmail.error
          ) {
            // $("#contact_form_email").prop("disabled", false);
          }
        });
      }
      // }
    }
  }
  emailInvalidMsg: string = "";

  /**
   * fn will on email changes and test the validation of email entered
   */
  emailChange(event: any) {
    let emailPattern = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.contactEmail = this.contactEmail.trim();
    let isEmailValidByRegex = emailPattern.test(this.contactEmail);
    this.emailInvalid = !isEmailValidByRegex;
    this.emailInvalidMsg = this.emailInvalid ? "Please enter valid email" : "";
    // if (!emailPattern.test(this.contactEmail)) {
    //   this.emailInvalid = this.contactEmail.trim()
    //     ? "Please enter valid email"
    //     : "";
    // } else {
    //   this.assessmentData
    //     .checkIfEmailBlackListed(this.route.snapshot.params.id, {
    //       email: this.contactEmail,
    //     })
    //     .subscribe(
    //       (data: any) => {
    //         this.emailInvalid = "";
    //       },
    //       (Err: any) => {
    //         this.emailInvalid = Err.error.message
    //           ? Err.error.message
    //           : "Their is some issue with this email id. Please change email id.";
    //       }
    //     );
    // }
  }

  email_sent: any = false;

  /**
   * fn will send email to user what ever the email Id entered in save and resume modal
   */
  sendResumeEmail() {
    let _obj = {
      email: this.contactEmail,
      // response_url: this.currentSurveyResponse.url,
      response_url: `${environment.PLATFORM_BASE_URL}survey/${this.currentSurveyId}/responses/${this.currentResponseId}`,
      survey_identifier: this.currentSurveyId,
    };

    if (!this.emailInvalid && this.responseMode) {
      if (this.contactEmail) {
        this.assessmentData.setLoader({ bool: true });
        this.assessmentData.sendSurveyResumeLink(_obj).subscribe(
          (data: any) => {
            this.email_sent = true;
            this.assessmentData.setLoader({ bool: false });
            // this.toastrService.success('Link has been sent on the email. Please continue survey using that link.');
          },
          (Err: any) => {
            this.assessmentData.setLoader({ bool: false });
            this.toastrService.error(
              `Unable to sent link on this(${this.contactEmail}) email id. Please try again later.`
            );
          }
        );
      } else {
        this.emailInvalidMsg = "This field is required.";
      }
    }
  }

  /**
   * fn will update the status of responses on backend every time user answer a question
   */
  public updateResponseStatus(status: number): void {
    if (this.responseMode) {
      this.currentSurveyResponse.status = status;
      this.currentSurveyResponse["token"] = this.emailLinkToken !== undefined ? this.emailLinkToken : "";
      this.assessmentData
        .updateAssessmentResponseStatus(this.currentSurveyResponse.url, this.currentSurveyResponse)
        .subscribe(
          (response) => {
            if (status != 2) {
              localStorage.setItem("response", JSON.stringify(this.currentSurveyResponse));
            } else if (this.showReportView) {
              if(!this.noCookies){
                document.cookie = `current_survey=${this.currentSurveyId}&expiry_at=${new Date()}`;
              }
              this.generateViewReport();
            } else {
              if(!this.noCookies){
                document.cookie = `current_survey=${this.currentSurveyId}&expiry_at=${new Date()}`;
              }
              this.generateIndividualReport();
            }
          },
          (error) => {
            this.surveyLoadingError = error.error.error_msg;
          }
        );
    }
  }

  pdfUrl: any;
  intervalForReportLoad: any;
  /**
   * fn will generate the report
   */
  generateViewReport() {
    clearTimeout(this.reportTimeout);
    this.reportTimeout = setTimeout(() => {
      if (this.surveySettings && this.showReportView && !this.report_sent) {
        this.report_sent = true;
        this.assessmentData.setLoader({ bool: true });

        this.assessmentData
          .generateIndividualReport(this.currentSurveyId, this.currentResponseId, this.individualReportCode)
          .subscribe(
            (response: any) => {
              this.pdfUrl = response.url;
              let index_2 = this.getSettingIndex("report", "generate_individual_report");

              this.intervalForReportLoad = setInterval(() => {
                if (document.getElementById("iframe")) {
                  console.log("interval");
                  this.pdfLoaded = true;
                  this.assessmentData.setLoader({ bool: false });
                  clearInterval(this.intervalForReportLoad);
                }
                $(".data-container").click();
                this.cd.detectChanges();
              }, 2000);

              setTimeout(() => {
                this.cd.detectChanges();
                this.pdfLoaded = true;
                // this.assessmentData.setLoader({ bool: false });
                $(".data-container").click();
                if (this.surveySettings.report[index_2].value) {
                  this.toastrService.success("Report has been sent via email. Please check your email.");
                }
              }, 6000);

              this.cd.detectChanges();
            },
            (err) => {
              this.report_sent = false;
              this.toastrService.warning("Unable to download report. Please try again later.");
              this.assessmentData.setLoader({ bool: false });
            }
          );
      }
    }, 1000);
  }

  pdfLoaded: boolean = false;

  /**
   * fn will after all content loaded to reset and remove intervals
   */
  contentLoaded() {
    this.cd.detectChanges();
    this.assessmentData.setLoader({ bool: false });
    setTimeout(() => {
      $(".data-container").click();
      this.pdfLoaded = true;
      this.cd.detectChanges();
    }, 1000);

    console.log("content loaded.");
    clearInterval(this.intervalForReportLoad);
  }

  /**
   * fn is used to generate individual report
   */
  generateIndividualReport() {
    clearTimeout(this.reportTimeout);
    this.reportTimeout = setTimeout(() => {
      let index_2 = this.getSettingIndex("report", "generate_individual_report");
      if (this.surveySettings && this.surveySettings.report[index_2].value && !this.report_sent) {
        this.report_sent = true;
        this.assessmentData.setLoader({ bool: true });
        this.assessmentData
          .generateIndividualReport(this.currentSurveyId, this.currentResponseId, this.individualReportCode)
          .subscribe(
            (response: any) => {
              this.toastrService.success("Report has been sent via email. Please check your email.");
              // this.isLoading = false;
              // var FileSaver = require('file-saver');
              // FileSaver.saveAs(response.url, `Individual_Report.pdf`);
              this.assessmentData.setLoader({ bool: false });
            },
            (err) => {
              // this.isLoading = false;
              this.report_sent = false;
              this.toastrService.warning("Unable to download report. Please try again later.");
              this.assessmentData.setLoader({ bool: false });
            }
          );
      }
    }, 1000);
  }

  /**
   * Rank order question required validation check
   */
  getSuitableRankREquiredError(q: any) {
    let rearrange_error =
      `This is required. Please fill out to complete survey.` +
      "\n" +
      `Note: If the given order is your preference, please change and revert the position of a single response option to continue.`;
    let req_error = "This is required. Please fill out to complete survey.";
    // return req_error;
    switch (q.option_type) {
      case 1:
        return rearrange_error;
      case 2:
        return rearrange_error;
      case 3:
        return req_error;
      case 4:
        return req_error;
    }
  }

  /**
   * fn will returned the number of answered questions
   */
  totalAnsFilled(ques: any) {
    let answered: any = 0;
    for (let index = 0; index < ques.answer.length; index++) {
      const element = ques.answer[index];
      if (element.answer.length > 0) {
        answered++;
      }
    }
    return answered;
  }

  /**
   * fn will check the valdiation of all type of questions
   */
  public checkQuestionsValidation(forAutoProgress?: any): boolean {
    /**
     * 1. check if question is required/requested
     * 2. check custom validation
     *
     */
    let allQuestionInCurrentPageAreValid: boolean = true;
    this.numberOfRequestedQuestions = 0;

    let resetError = (q: any) => {
      delete q["error"];
      allQuestionInCurrentPageAreValid = allQuestionInCurrentPageAreValid && true;
      this.showRequiredQuestionErrorBox = false;
    };

    let alertError = (q: any, otherErr?: any) => {
      // let index = this.getSettingIndex('appearance','appearance_required_astericks');
      allQuestionInCurrentPageAreValid = allQuestionInCurrentPageAreValid && false;
      this.showRequiredQuestionErrorBox = this.custom_theme.appearance_required_astericks ? true : false;
      q["error"] = {
        hasError: true,
        errorType: "required",
        errorMsg: otherErr
          ? "A response for the other text box is required. Please fill out to complete survey."
          : q._type == 4
            ? this.getSuitableRankREquiredError(q)
            : "This is required. Please fill out to complete survey.",
      };
    };
    this.currentQuestions
      .filter((q: any) => !q.hide)
      .forEach((question: any) => {
        if (question._type == 9) {
          if (question.answer) {
            resetError(question);
          } else {
            alertError(question);
          }
        }
        if (question.validation_option && question.validation_option.is_validation) {
          // require/requested validation
          switch (question.validation_option.validation) {
            case 1:
              // question answer is required

              switch (question._type) {
                case 1:
                  if (!question.answer) {
                    alertError(question);
                  } else {
                    if (question.answer.options === null) {
                      alertError(question);
                    } else {
                      if (question.option_type == 2) {
                        if (
                          question.answer.options.length > 0 &&
                          (!question.validation_option["is_comment_box"] ||
                            (question.validation_option["is_comment_box"] &&
                              typeof question.answer["other"] == "undefined") ||
                            (question.validation_option["is_comment_box"] &&
                              typeof question.answer["other"] != "undefined" &&
                              question.answer["other"].trim()))
                        ) {
                          resetError(question);
                        } else {
                          if (
                            (question.validation_option["is_comment_box"] &&
                              typeof question.answer["other"] != "undefined" &&
                              question.answer["other"].trim()) ||
                            (question.validation_option["to_observe"] && question.answer["not_observed"])
                          ) {
                            resetError(question);
                          } else {
                            alertError(
                              question,
                              question.validation_option["is_comment_box"] &&
                                typeof question.answer["other"] != "undefined" &&
                                !question.answer["not_observed"]
                                ? "otherErr"
                                : null
                            );
                          }
                        }
                      } else {
                        if (question.answer.options.length > 0) {
                          if (this.currentPage.questions.every(q => q.answer && q.answer.options && q.answer.options.length > 0)) {
                            resetError(question);
                          }
                        } else {
                          if (
                            (question.validation_option["is_comment_box"] &&
                              typeof question.answer["other"] != "undefined" &&
                              question.answer["other"].trim()) ||
                            (question.validation_option["to_observe"] && question.answer["not_observed"])
                          ) {
                            resetError(question);
                          } else {
                            alertError(
                              question,
                              question.validation_option["is_comment_box"] &&
                                typeof question.answer["other"] != "undefined" &&
                                !question.answer["not_observed"]
                                ? "otherErr"
                                : null
                            );
                          }
                        }
                      }
                    }
                  }
                  break;
                case 2:
                  if (!question.answer) {
                    alertError(question);
                  } else {
                    if (question.answer.length === 0) {
                      alertError(question);
                    } else {
                      resetError(question);
                    }
                  }

                  break;

                case 3:
                  if (!question.answer) {
                    alertError(question);
                  } else {
                    let filledRows = question.answer.filter((a: any) => {
                      return a.column.length > 0 && a.row !== null;
                    });

                    if (question.question_options.rows.length === filledRows.length) {
                      resetError(question);
                    } else {
                      alertError(question);
                    }
                  }
                  break;

                case 4:
                  if (typeof question.answered != "undefined" && question.answered == false) {
                    alertError(question);
                  } else {
                    if (!question.answer) {
                      alertError(question);
                    } else {
                      let fetchNullAnswer = question.answer.filter((a: any) => {
                        return !a.option || !a.order;
                      });

                      if (fetchNullAnswer.length > 0 || question.answer.length < question.question_options.length) {
                        alertError(question);
                      } else {
                        resetError(question);
                      }
                    }
                  }

                  break;
                case 5:
                  if (!question.answer) {
                    alertError(question);
                  } else {
                    let nulls = question.answer.filter((a: any) => {
                      return a.answer == null;
                    });

                    if (
                      (question.question_options.length === question.answer.length && nulls.length === 0) ||
                      (nulls.length == question.question_options.length &&
                        question.validation_option.to_observe &&
                        question.answer[0]["not_observed"])
                    ) {
                      resetError(question);
                    } else {
                      alertError(question);
                    }
                  }
                  break;

                case 6:
                  if (!question.answer) {
                    alertError(question);
                  } else {
                    let nullAnswer = question.answer.filter((a: any) => {
                      return !a.option || !a.answer;
                    });

                    if (nullAnswer.length > 0 || question.answer.length < question.question_options.length) {
                      alertError(question);
                    } else {
                      resetError(question);
                    }
                  }
                  break;

                case 7:
                  if (!question.answer) {
                    alertError(question);
                  } else {
                    if (
                      question.answer.length <
                      question.question_options.rows.length * question.question_options.cols.length ||
                      this.totalAnsFilled(question) !=
                      question.question_options.rows.length * question.question_options.cols.length
                    ) {
                      alertError(question);
                    } else {
                      resetError(question);
                    }
                  }
                  break;
                default:
                  break;
              }
              break;
            case 2:
              let fetched = this.answeredQuestions.filter((quest: any) => {
                return question.url === quest.url && question.answer;
              });

              if (fetched.length > 0) {
                delete question.error;
              } else {
                allQuestionInCurrentPageAreValid = false;
                this.numberOfRequestedQuestions++;
                // question answer is requested
                // show alert confirmation popup
                question["error"] = {
                  hasError: true,
                  errorType: "requested",
                  errorMsg: "An answer is requested for this item.",
                };

                // this.skipRequestedQuestionAlert();
              }

              break;

            default:
              // code...
              break;
          }
        }

        if (
          question["hasCustomValidationError"] === true ||
          question["customValidError"] ||
          question["hasMustTotalError"] === true ||
          question["hasSameRankError"] === true ||
          question["hasLengthValidationError"] === true ||
          (forAutoProgress && this.isQuestionDoneValid(question) == false)
        ) {
          allQuestionInCurrentPageAreValid = allQuestionInCurrentPageAreValid && false;
        }
      });
    if (this.numberOfRequestedQuestions > 0) {
      this.skipRequestedQuestionAlert();
    }
    return allQuestionInCurrentPageAreValid;
  }

  /**
   * fn will check for the question is done or not for not_observed case
   */
  isQuestionDoneValid(question: any) {
    let reture_value = true;
    switch (question._type) {
      case 1:
        if (!question.answer) {
          reture_value = true;
        } else {
          if (question.answer.options === null) {
            reture_value = true;
          } else {
            if (question.answer.options.length > 0) {
              // || (question.answer.options.length === 0 && question.answer['other'].trim())
              reture_value = true;
            } else {
              if (
                // (question.validation_option['is_comment_box'] && typeof question.answer['other'] != 'undefined' && question.answer['other'].trim()) ||
                question.validation_option["to_observe"] &&
                question.answer["not_observed"]
              ) {
                reture_value = true;
              } else {
                reture_value = false;
              }
            }
          }
        }
        // reture_value = true;
        break;
      // case 2:
      //   reture_value = true;
      // break;
      case 3:
        if (!question.answer) {
          reture_value = true;
        } else {
          let filledRows = question.answer.filter((a: any) => {
            return a.column.length > 0 && a.row !== null;
          });

          if (question.question_options.rows.length === filledRows.length) {
            reture_value = true;
          } else {
            reture_value = false;
          }
        }
        break;

      // case 4:
      //   if (!question.answer) {
      //     reture_value = true;
      //   } else {
      //     let fetchNullAnswer = question.answer.filter((a: any)=>{
      //       return a.option === null || a.order === null
      //     });

      //     if(fetchNullAnswer.length > 0 || question.answer.length < question.question_options.length){
      //       reture_value = false;
      //     }else{
      //       reture_value = true;
      //     }
      //   }

      //   break;
      // case 5:
      //   if (!question.answer) {
      //     reture_value = true;
      //   } else {
      //     let nulls = question.answer.filter((a: any)=>{
      //       return a.answer == null
      //     });

      //     if(question.question_options.length === question.answer.length && (nulls.length == 0 || nulls.length == question.answer.length)){
      //       reture_value = true;
      //     }else{
      //       reture_value = false;
      //     }
      //   }
      // break;

      // case 6:
      //   if (!question.answer) {
      //     reture_value = true;
      //   } else {
      //     let nullAnswer = question.answer.filter((a: any)=>{
      //       return a.option === null || a.order === null
      //     });

      //     if(nullAnswer.length > 0 || question.answer.length < question.question_options.length){
      //       reture_value = false;
      //     }else{
      //       reture_value = true;
      //     }
      //   }
      // break;

      case 7:
        if (!question.answer) {
          reture_value = true;
        } else {
          if (question.answer.length > 0) {
            let no_ans = question.answer.filter((item: any) => {
              return item.answer.length == 0;
            });
            if (no_ans.length > 0 && no_ans.length != question.answer.length) {
              reture_value = false;
            } else {
              reture_value = true;
            }
          } else {
            reture_value = true;
          }
        }
        break;
      default:
        break;
    }
    return reture_value;
  }

  modelRef: any;
  /**
   * fn will open a modal to ask user if he/she proceed with answer the required question or proceed 
     without answering
   */
  public skipRequestedQuestionAlert(): void {
    this.alertContent = {
      title: "Response Requested",
      message: `There ${this.numberOfRequestedQuestions > 1 ? "are" : "is"} ${this.numberOfRequestedQuestions
        } unanswered question${this.numberOfRequestedQuestions > 1 ? "s" : ""
        } with a requested response. How would you like to continue?`,
      data: null,
      positiveAction: "skipAnswer",
    };

    this.modelRef = this.modalService.open(this.skipAnswerAlertRef, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      size: "lg",
      windowClass: "modal-holder",
    });
    this.modelRef.result.then(
      (result: any) => {
        // do nothing here
      },
      (reason: boolean) => {
        if (reason === true) {
          this.assessmentData.setLoader({ bool: true });
          let canSubmitSurvey: boolean = true;
          let erroneous_question_count = 0;

          // continue without answering : before doing that we have to recheck validation for other questions
          this.currentPage.questions.forEach((question: any) => {
            if (
              question["error"] &&
              question["error"]["hasError"] === true &&
              question["error"]["errorType"] != "requested"
            ) {
              erroneous_question_count++;
            }
            if (
              question["hasCustomValidationError"] === true ||
              (question["hasMustTotalError"] === true &&
                question["mustTotalErrorMessage"] !=
                "Distribute total among all options or fill remaining options with '0'.") ||
              question["hasSameRankError"] === true ||
              question["hasLengthValidationError"] === true
            ) {
              canSubmitSurvey = false;
            }
          });
          if (canSubmitSurvey && !erroneous_question_count) {
            this.doSubmitSurvey();
            // this.assessmentData.setLoader({bool: false});
          } else {
            this.assessmentData.setLoader({ bool: false });
          }
        } else {
          this.numberOfRequestedQuestions = 0;
        }
      }
    );
  }

  /**
   * fn will run when user select to skip answer events
   */
  canSkipAnswerEvent(reason: any) {
    if (reason === true) {
      this.assessmentData.setLoader({ bool: true });
      let canSubmitSurvey: boolean = true;
      let erroneous_question_count = 0;

      // continue without answering : before doing that we have to recheck validation for other questions
      this.currentPage.questions.forEach((question: any) => {
        if (
          question["error"] &&
          question["error"]["hasError"] === true &&
          question["error"]["errorType"] != "requested"
        ) {
          erroneous_question_count++;
        }
        if (
          question["hasCustomValidationError"] === true ||
          (question["hasMustTotalError"] === true &&
            question["mustTotalErrorMessage"] !=
            "Distribute total among all options or fill remaining options with '0'.") ||
          question["hasSameRankError"] === true ||
          question["hasLengthValidationError"] === true
        ) {
          canSubmitSurvey = false;
        }
      });
      if (canSubmitSurvey && !erroneous_question_count) {
        this.doSubmitSurvey();
        // this.assessmentData.setLoader({bool: false});
      } else {
        this.assessmentData.setLoader({ bool: false });
      }
    } else {
      this.numberOfRequestedQuestions = 0;
    }
    if (this.modelRef) {
      this.modelRef.close();
    }
  }

  /**
   * naivgate to home (dashboard )
   */
  navigateToHome() {
    this.router.navigate(["/"]);
  }

  closeModal() {
    // this.modalService.dismissAll();
  }

  /**
   * logic: option choosen of mcq type subject changes detect function
   */
  listenToMcqOptionUpdate() {
    this.subscription.add(
      this.assessmentData.optionChoosen$.subscribe((data: any) => {
        if (data) {
          this.checkDisplayLogic(data);
          this.checkSkipLogic(data);
          this.checkBranchLogic(data);
        }
      })
    );
  }

  /**
   * check if question logic contains logic of branching type logic or not
   */
  doesLogicHasMultipleBranching(ques: any) {
    let branchingArr: any = [];
    ques.logic.forEach((lgc: any) => {
      if (lgc["logic_type"] == "branching") {
        branchingArr.push(lgc);
      }
    });

    return branchingArr.length > 1 ? true : false;
  }

  /**
   * fn will run for every option choose and if question contains logic with logic type branching
     then it will branch to question as defined in logic after checking the condition mentioned
   */
  checkBranchLogic(optionInfo: {
    id: number;
    option_tooltip: string;
    position: number;
    question_id: number;
    text: string;
    ques_pos: number;
    q_id: number;
    option_type: number;
    url: string;
  }) {
    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any, qIndex: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];

        if (
          optionInfo.q_id == id &&
          ques &&
          ques.logic &&
          ques.logic.length &&
          ques.logic[0]["conditions"] &&
          ques.logic[0]["conditions"].length
        ) {
          if (!this.doesLogicHasMultipleBranching(ques)) {
            ques.logic.forEach((lgc: any) => {
              if (lgc.logic_type == "branching") {
                lgc.conditions.forEach((cond: any) => {
                  cond["question_id_from_locator"] = id;
                  cond["question"] = id;
                  if (lgc.conditions.length == 1) {
                    let ans = this.coverForBranchCondition(cond, optionInfo, ques, qIndex, false);
                    cond["ans3"] = ans;
                  }
                  else {
                    if (
                      cond.question_choice_locator &&
                      optionInfo.id &&
                      cond.question_choice_locator == optionInfo.id
                    ) {
                      let ans = this.coverForBranchCondition(cond, optionInfo, ques, qIndex, true);
                      cond["ans3"] = ans;
                    } else if (
                      cond.question_choice_locator != optionInfo.id ||
                      (!cond.question_choice_locator && !optionInfo.id)
                    ) {
                      let ans = this.coverForBranchCondition(cond, optionInfo, ques, qIndex, true);
                      cond["ans3"] = ans;
                    }
                  }
                });

                if (lgc.conditions.length > 1) {
                  let lastVal: any;
                  let arr: any;
                  lgc["conditions"].forEach((condition: any, cIndex: any) => {
                    arr = this.hideAllQuestionsTillDestinationForBranchLogic(
                      ques,
                      qIndex,
                      condition.question_destination,
                      false
                    );
                    if (cIndex > 0) {
                      lastVal =
                        condition["conjuction"] == "AND"
                          ? lastVal && lgc["conditions"][cIndex]["ans3"]
                          : lastVal || lgc["conditions"][cIndex]["ans3"];
                    } else {
                      lastVal = lgc["conditions"][cIndex]["ans3"];
                    }
                  });
                  lgc["lastVal"] = lastVal;
                  arr.forEach((ar: any) => {
                    ar["hide_via_branch"] = lastVal;
                  });

                  // set redirect URL dynamically on the basis of user response and logic
                  this.setRedirectURL(ques.logic);
                } else {
                  let lastVal: any;
                  lastVal = lgc["conditions"][0]["ans3"];
                  lgc["lastVal"] = lastVal;

                  this.setRedirectURL(ques.logic);
                }

                //if(multi case)
                // make an array of same logic condns
              }
            });
          } else {
            let finArr: any = [];
            ques.logic.forEach((lgc: any) => {
              if (lgc.logic_type == "branching") {
                lgc.conditions.forEach((cond: any) => {
                  cond["question_id_from_locator"] = id;
                  cond["question"] = id;
                  let ans = this.coverForBranchCondition(cond, optionInfo, ques, qIndex, true);
                  cond["ans3"] = ans;
                });

                if (lgc.conditions.length) {
                  let lastVal: any;
                  let arr: any;
                  lgc["conditions"].forEach((condition: any, cIndex: any) => {
                    arr = this.hideAllQuestionsTillDestinationForBranchLogic(
                      ques,
                      qIndex,
                      condition.question_destination,
                      false
                    );
                    if (cIndex > 0) {
                      lastVal =
                        condition["conjuction"] == "AND"
                          ? lastVal && lgc["conditions"][cIndex]["ans3"]
                          : lastVal || lgc["conditions"][cIndex]["ans3"];
                    } else {
                      lastVal = lgc["conditions"][cIndex]["ans3"];
                    }
                  });

                  lgc["lastVal"] = lastVal;
                  if (lastVal) {
                    lgc["array"] = arr;
                    finArr = arr;
                  }
                }
              }
            });

            finArr.forEach((ar: any) => {
              ar["hide_via_branch"] = true;
            });
            // set redirect URL dynamically on the basis of user response and logic
            this.setRedirectURL(ques.logic);
          }
        }
      });
    });
  }


  /**
    set redirect url by checking all logic and their condition 
    if the logic type is branching and destination is end_of_survey then set redirect URL according to set in 
  */
  setRedirectURL(logicList: any[]) {
    let trueLogics = logicList.filter((lg) => lg.lastVal);
    let redirect: any = null;
    trueLogics.forEach((lg) => {
      if (lg.logic_type === "branching" || lg.logic_type === "skip") {
        if (lg.conditions && lg.conditions.length) {
          if (lg.conditions[0].question_destination_type === "end_of_survey") {
            redirect = lg.conditions[0].redirect_url;
          }
        }
      }
    })

    let exp_settings = this.surveySettings.experience;

    let filterExp = exp_settings.find((ex: any) => ex.code === redirect);
    if (filterExp) {
      this.dynamicRedirectURLFromLogic = filterExp.value;
    } else {
      this.dynamicRedirectURLFromLogic = null;
    }
  }

  /**
    append variable in redirect url if {{}} are present with embed params data
  */

  appendVariableIfAny(redirectURL: string) {
    let embedData = this.getEmbedDataFromURL();
    if (embedData && embedData.length) {
      embedData.forEach((ed: any) => {
        redirectURL = redirectURL.replace(`{{${ed.name}}}`, ed.value);
      });
    }
    return redirectURL;
  }

  /**
   * fn will return the destination question for branching type logic
   */
  getDestinationQuesForBranch(condition: any) {
    if (!condition.question_destination && condition.question_destination_type == "end_of_survey") {
      let lastQues: any = this.getEndOfSurvey();
      // for end of survey keep question_destination to null
      condition.question_destination = null;
    } else if (condition.question_destination_type == "question") {
      condition.question_destination = this.getSectionIdForBranch(
        condition.section_destination,
        condition.question_destination
      );
    }
  }

  /**
   * fn will return section ID for branching type logic
   */
  getSectionIdForBranch(secDestId: any, quesDestId: any) {
    let currSectionId: any;
    let firstQuesOfSec: any;
    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];
        let secArr = ques.section.split("/");
        let secId = +secArr[qArr.length - 2];
        if (!quesDestId && secId == secDestId) {
          currSectionId = secId;
          firstQuesOfSec = id;
          quesDestId = firstQuesOfSec;
        } else if (quesDestId && quesDestId == id) {
          currSectionId = secId;
          firstQuesOfSec = id;
        }
      });
    });

    return firstQuesOfSec;
  }

  /**
   * fn will check the condition with all type of operator and will will ans key with true or false 
     on the basis of condition has been met or not
   */
  coverForBranchCondition(condition: any, optionInfo: any, ques: any, quesIndex: any, isMultipleConditions: boolean) {
    let ans: boolean = false;
    this.getDestinationQuesForBranch(condition);
    if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      if (!optionInfo["checked"] && optionInfo["option_type"]) {
        if (!isMultipleConditions)
          this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
        ans = false;
      } else {
        this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
        ans = true;
      }
    } else if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question"] == optionInfo["question_id"] &&
      condition["question_choice_locator"] !== optionInfo.id
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] !== optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }
    //equal to
    else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] != condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is not equal to
    else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] !== condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // Is greater than
    else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] > +condition["operator_value"]
        : optionInfo["value"] > condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] <= +condition["operator_value"]
        : optionInfo["value"] <= condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is greater than or equal to"
    else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] >= +condition["operator_value"]
        : optionInfo["value"] >= condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] < +condition["operator_value"]
        : optionInfo["value"] < condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is less than"
    else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] < +condition["operator_value"]
        : optionInfo["value"] < condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] >= +condition["operator_value"]
        : optionInfo["value"] >= condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    // "Is less than or equal to"
    else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] <= +condition["operator_value"]
        : optionInfo["value"] <= condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] > +condition["operator_value"]
        : optionInfo["value"] > condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is empty
    else if (
      condition.operator == "Is empty" &&
      !optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is empty" &&
      optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Is not empty
    else if (
      condition.operator == "Is not empty" &&
      optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Is not empty" &&
      !optionInfo["value"] &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Contains
    else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    //Does not contain
    else if (
      condition.operator == "Does not contain" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, true);
      ans = true;
    } else if (
      condition.operator == "Does not contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    } else {
      this.hideAllQuestionsTillDestinationForBranchLogic(ques, quesIndex, condition.question_destination, false);
      ans = false;
    }

    return ans;
  }

  /**
   * fn will set hide key for all question till the destination question for branch type logic type
   */
  hideAllQuestionsTillDestinationForBranchLogic(cQues: any, quesIndex: any, destQuesId: any, hideValue: boolean) {
    let arr: any = [];
    // destQuesId = 38429;
    let destFound: boolean = false;
    let currQuesFound: boolean = false;
    let currQues = cQues.url.split("/");
    let currQuesId = +currQues[currQues.length - 2];
    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any, index: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];

        if (currQuesFound && id !== destQuesId && !destFound) {
          ques["hide_via_branch"] = hideValue;
          arr.push(ques);
        } else if (currQuesFound && id == destQuesId) {
          destFound = true;
        }
        if (currQuesId == id) {
          currQuesFound = true;
        }
      });
    });
    return arr;
  }

  /**
   * fn will run for every option choose and if question contains logic with logic type display
     then it will hide questions as defined in logic after checking the condition mentioned
   */
  checkDisplayLogic(optionInfo: {
    id: number;
    option_tooltip: string;
    position: number;
    question_id: number;
    text: string;
    ques_pos: number;
    q_id: number;
    option_type: number;
    url: string;
  }) {
    let finArr: any = [];

    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];

        if (
          optionInfo.q_id == id &&
          ques &&
          ques.logic &&
          ques.logic.length &&
          ques.logic[0]["conditions"] &&
          ques.logic[0]["conditions"].length
        ) {
          ques.logic
            .filter((lg: any) => {
              if (ques.option_type === 2) {
                let currentOptionCheckedId = optionInfo.id;
                let flag = false;
                if (lg.conditions.length) {
                  lg.conditions.forEach((cn: any) => {
                    if (cn.question_choice_locator === currentOptionCheckedId) {
                      flag = true;
                    }
                  });
                }
                if (flag) return lg;
              } else return lg;
            })
            .forEach((lgc: any, index2: any) => {
              if (lgc.logic_type == "display") {
                lgc.conditions.forEach((condition: any) => {
                  setTimeout(() => {
                    this.checkDisplayLogic({
                      ...optionInfo,
                      q_id: condition.question_destination,
                    });
                  });
                  let qArr = ques.url.split("/");
                  let id = +qArr[qArr.length - 2];
                  condition["question_id_from_locator"] = id;

                  if (lgc.conditions.length == 1) {
                    let q = this.getDestination(condition.question_destination);
                    this.coverForOneConditionOnly(condition, optionInfo, q, false);
                  } else {
                    if (
                      condition.question_choice_locator &&
                      optionInfo.id &&
                      condition.question_choice_locator == optionInfo.id
                    ) {
                      let q = this.getDestination(condition.question_destination);
                      let ans = this.coverForOneConditionOnly(condition, optionInfo, q, true);
                      condition["ans"] = ans;
                    } else if (
                      (!optionInfo.option_type && condition.question_choice_locator != optionInfo.id) ||
                      (!condition.question_choice_locator && !optionInfo.id)
                    ) {
                      let q = this.getDestination(condition.question_destination);
                      let ans = this.coverForOneConditionOnly(condition, optionInfo, q, true);
                      condition["ans"] = ans;
                    }
                  }
                });

                let mQ: any;
                if (lgc.conditions.length > 1) {
                  let lastVal: any;
                  lgc["conditions"].forEach((condition: any, cIndex: any) => {
                    mQ = this.getDestination(condition.question_destination);
                    if (cIndex > 0) {
                      lastVal =
                        condition["conjuction"] == "AND"
                          ? lastVal && lgc["conditions"][cIndex]["ans"]
                          : lastVal || lgc["conditions"][cIndex]["ans"];
                    } else {
                      lastVal = lgc["conditions"][cIndex]["ans"];
                    }
                  });
                  mQ["hide"] = !lastVal;
                }
              }
            });
        }

        // if (
        //   ques &&
        //   ques.logic &&
        //   ques.logic.display_logic &&
        //   ques.logic.display_logic.length &&
        //   ques.logic.display_logic[0]["conditions"] &&
        //   ques.logic.display_logic[0]["conditions"].length
        // ) {
        //   ques.logic.display_logic[0]["conditions"].forEach((condition: any) => {
        //     if (ques.logic.display_logic[0]["conditions"].length == 1)
        //       this.coverForOneConditionOnly(condition, optionInfo, ques, false);
        //     else if (
        //       condition["question_id_from_locator"] == optionInfo["question_id"]
        //     ) {
        //       let ans = this.coverForOneConditionOnly(
        //         condition,
        //         optionInfo,
        //         ques,
        //         true
        //       );
        //       condition["ans"] = ans;
        //     }
        //   });

        //   if (ques.logic.display_logic[0]["conditions"].length > 1) {
        //     let lastVal: any;
        //     ques.logic.display_logic[0]["conditions"].forEach(
        //       (condition: any, cIndex: any) => {
        //         if (cIndex > 0) {
        //           lastVal =
        //             condition["conjuction"] == "AND"
        //               ? lastVal &&
        //                 ques.logic.display_logic[0]["conditions"][cIndex]["ans"]
        //               : lastVal ||
        //                 ques.logic.display_logic[0]["conditions"][cIndex]["ans"];
        //         } else {
        //           lastVal =
        //             ques.logic.display_logic[0]["conditions"][cIndex]["ans"];
        //         }
        //       }
        //     );
        //     ques["hide"] = !lastVal;
        //   }
        // }
      });
    });
  }

  /**
   * fn will check the condition with all type of operator and will will ans key with true or false 
     on the basis of condition has been met or not
   */
  coverForOneConditionOnly(condition: any, optionInfo: any, ques: any, isMultipleConditions: boolean) {
    ques["hide"] = true;  
    let ans: boolean = false;
    if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) {
        ques["hide"] = false;
      }
      ans = true;

      if (!optionInfo["checked"] && optionInfo["option_type"]) {
        if (!isMultipleConditions) ques["hide"] = true;
        ans = false;
      }
    } else if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"] &&
      condition["question_choice_locator"] !== optionInfo.id
    ) {
      if (optionInfo["option_type"]) {
        //do nothing
        // if (!isMultipleConditions) ques["hide"] = true;
        // ans = false;
      } else if (!isMultipleConditions) {
        ques["hide"] = true;
        ans = false;
      }
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] !== optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
      if (!optionInfo["checked"] && optionInfo["option_type"]) {
        if (!isMultipleConditions) ques["hide"] = false;
        ans = true;
      }
    }
    //equal to
    else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] != condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Is not equal to
    else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] !== condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // Is greater than
    else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] > +condition["operator_value"]
        : optionInfo["value"] > condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] <= +condition["operator_value"]
        : optionInfo["value"] <= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // "Is greater than or equal to"
    else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] >= +condition["operator_value"]
        : optionInfo["value"] >= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] < +condition["operator_value"]
        : optionInfo["value"] < condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // "Is less than"
    else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] < +condition["operator_value"]
        : optionInfo["value"] < condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] >= +condition["operator_value"]
        : optionInfo["value"] >= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    // "Is less than or equal to"
    else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] <= +condition["operator_value"]
        : optionInfo["value"] <= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] > +condition["operator_value"]
        : optionInfo["value"] > condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Is empty
    else if (
      condition.operator == "Is empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Is not empty
    else if (
      condition.operator == "Is not empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Is not empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Contains
    else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    }

    //Does not contain
    else if (
      condition.operator == "Does not contain" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = false;
      ans = true;
    } else if (
      condition.operator == "Does not contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      if (!isMultipleConditions) ques["hide"] = true;
      ans = false;
    } else {
      ans = false;
      ques["hide"] = true;
    }

    if (!ans) {
      if (ques.answer) {
        if (ques.answer.constructor === String) {
          ques.answer = "";
        } else if (ques.answer.constructor === Object) {
          ques.answer.options = [];
        }

        ques.answered = false;
      }
    }

    return ans;
  }

  /**
   * check if question has hide key
   */
  checkQuesHide(ques: any) {
    if (!ques.hide) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * check if the question is hide because of skip type logic
   */
  checkSkipQuesHide(ques: any) {
    if (ques.hide_via_skip) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * fn will run for every option choose and if question contains logic with logic type display
     then it will skip to  questions as defined in logic after checking the condition mentioned
   */
  checkSkipLogic(optionInfo: any) {
    let finArr: any = [];

    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any, qIndex: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];

        if (
          optionInfo.q_id == id &&
          ques &&
          ques.logic &&
          ques.logic.length &&
          ques.logic[0]["conditions"] &&
          ques.logic[0]["conditions"].length
        ) {
          ques.logic.forEach((lgc: any) => {
            if (lgc.logic_type == "skip") {
              lgc.conditions.forEach((cond: any) => {
                cond["question_id_from_locator"] = id;
                cond["question"] = id;
                if (lgc["conditions"].length == 1) {
                  let ans = this.coverForSkipCondition(cond, optionInfo, ques, qIndex, false);
                  cond["ans2"] = ans;
                }
                else {
                  if (cond.question_choice_locator && optionInfo.id && cond.question_choice_locator == optionInfo.id) {
                    let ans = this.coverForSkipCondition(cond, optionInfo, ques, qIndex, true);
                    cond["ans2"] = ans;
                  } else if (
                    cond.question_choice_locator != optionInfo.id ||
                    (!cond.question_choice_locator && !optionInfo.id)
                  ) {
                    let ans = this.coverForSkipCondition(cond, optionInfo, ques, qIndex, true);
                    cond["ans2"] = ans;
                  }
                }
              });

              if (lgc.conditions.length > 1) {
                let lastVal: any;
                let arr: any;
                lgc["conditions"].forEach((condition: any, cIndex: any) => {
                  arr = this.hideAllQuestionsTillDestination(
                    ques,
                    qIndex,
                    condition.question_destination,
                    false,
                    condition
                  );
                  if (cIndex > 0) {
                    lastVal =
                      condition["conjuction"] == "AND"
                        ? lastVal && lgc["conditions"][cIndex]["ans2"]
                        : lastVal || lgc["conditions"][cIndex]["ans2"];
                  } else {
                    lastVal = lgc["conditions"][cIndex]["ans2"];
                  }
                });
                lgc["lastVal"] = lastVal;
                arr.forEach((ar: any) => {
                  ar["hide_via_skip"] = lastVal;
                });

                this.setRedirectURL(ques.logic);
              } else {
                let lastVal: any;
                lastVal = lgc["conditions"][0]["ans2"];
                lgc["lastVal"] = lastVal;

                this.setRedirectURL(ques.logic);
              }
            }
          });
        }
      });
    });
  }

  /**
   * check if section is hide because of skip type logic
   */
  checkSkipSecHide(sec: any) {
    let notHideFound: boolean = false;
    sec.forEach((sc: any) => {
      sc.questions.forEach((ques: any) => {
        if (!ques.hide_via_skip && ques.obj_type != "pagebrake") {
          notHideFound = true;
        }
      });
    });
    return notHideFound;
  }

  /**
   * check if section is hide because of skip type logic
   */
  checkSkipSecHide2(arr: any) {
    let notHideFound: boolean = false;
    arr.forEach((ques: any) => {
      if (!ques.hide_via_skip && ques.obj_type != "pagebrake") {
        notHideFound = true;
      }
    });
    if (!notHideFound) {
      // this.next();
    }
    return notHideFound;
  }

  /**
   * check if section is hide because of branch type logic
   */
  checkBranchSecHide2(arr: any) {
    let notHideFound: boolean = false;
    arr.forEach((ques: any) => {
      if (!ques.hide_via_branch && ques.obj_type != "pagebrake") {
        notHideFound = true;
      }
    });
    if (!notHideFound) {
      // this.next();
    }
    return notHideFound;
  }

  /**
   * check if section is hide because of branch type logic
   */
  checkBranchSecHide(sec: any) {
    let notHideFound: boolean = false;
    sec.forEach((sc: any) => {
      sc.questions.forEach((ques: any) => {
        if (!ques.hide_via_branch && ques.obj_type != "pagebrake") {
          notHideFound = true;
        }
      });
    });
    return notHideFound;
  }

  /**
   * hide all question till destination question
   */
  hideAllQuestionsTillDestination(cQues: any, quesIndex: any, destQuesId: any, hideValue: boolean, condition: any) {
    let arr: any = [];
    let destFound: boolean = false;
    let currQuesFound: boolean = false;
    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any, index: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];
        let currQues = cQues.url.split("/");
        let currQuesId = +currQues[currQues.length - 2];

        if (currQuesFound && id !== destQuesId && !destFound) {
          ques["hide_via_skip"] = hideValue;
          arr.push(ques);
        } else if (currQuesFound && id == destQuesId) {
          if (condition.question_destination_type === "end_of_block" && currQuesId !== destQuesId) {
            ques["hide_via_skip"] = hideValue;
            arr.push(ques);
          }
          destFound = true;
        }
        if (id == destQuesId) {
          destFound = true;
        }
        if (currQuesId == id) {
          currQuesFound = true;
        }
      });
    });
    return arr;
  }

  /**
   * get the destination question as mentioned in condition
   */
  getDestinationQues(condition: any) {
    if (!condition.question_destination && condition.question_destination_type == "end_of_survey") {
      let lastQues: any = this.getEndOfSurvey();
      // keep question destination to null for end of survey
      condition.question_destination = null;
    } else if (!condition.question_destination && condition.question_destination_type == "end_of_block") {
      condition.question_destination = this.getSectionId(condition.question);
    }
  }

  /**
   * fn will return the last question of survey
   */
  getEndOfSurvey() {
    let lastSection = this.sections[this.sections.length - 1];
    let lastques = lastSection.questions[lastSection.questions.length - 1];
    let qArr = lastques.url.split("/");
    let id = +qArr[qArr.length - 2];
    return id;
  }

  /**
   * get section Id of question Id passed in argument
   */
  getSectionId(currQuesId: any) {
    let currSectionId: any;
    let lastQuesOfSec: any;
    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];
        let secArr = ques.section.split("/");
        let secId = +secArr[secArr.length - 2];
        if (id == currQuesId) {
          currSectionId = secId;
          lastQuesOfSec = currQuesId;
        } else if (currSectionId && secId == currSectionId) {
          lastQuesOfSec = id;
        }
      });
    });

    return lastQuesOfSec;
  }

  /**
   * fn will run for every option choose and if question contains logic with logic type display
     then it will skip to  questions as defined in logic after checking the condition mentioned
   */
  coverForSkipCondition(condition: any, optionInfo: any, ques: any, quesIndex: any, isMultipleConditions: boolean) {
    let ans: boolean = false;
    this.getDestinationQues(condition);
    if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question"] == optionInfo["question_id"]
    ) {
      if (!optionInfo["checked"] && optionInfo["option_type"]) {
        if (!isMultipleConditions) {
          this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
          ans = true;
        }
        ans = false;
      } else {
        this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
        ans = true;
      }
    } else if (
      condition.operator == "Is Selected" &&
      condition["question_choice_locator"] &&
      condition["question"] == optionInfo["question_id"] &&
      condition["question_choice_locator"] !== optionInfo.id
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] !== optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is Not Selected" &&
      condition["question_choice_locator"] &&
      condition["question_choice_locator"] == optionInfo.id &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }
    //equal to
    else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] != condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    //Is not equal to
    else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] !== condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is not equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"] == condition["operator_value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    // Is greater than
    else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] > +condition["operator_value"]
        : optionInfo["value"] > condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is greater than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] <= +condition["operator_value"]
        : optionInfo["value"] <= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    // "Is greater than or equal to"
    else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] >= +condition["operator_value"]
        : optionInfo["value"] >= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is greater than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] < +condition["operator_value"]
        : optionInfo["value"] < condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    // "Is less than"
    else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] < +condition["operator_value"]
        : optionInfo["value"] < condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is less than" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] >= +condition["operator_value"]
        : optionInfo["value"] >= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    // "Is less than or equal to"
    else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] <= +condition["operator_value"]
        : optionInfo["value"] <= condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is less than or equal to" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      (this.containsOnlyNumbers(condition["operator_value"])
        ? +optionInfo["value"] > +condition["operator_value"]
        : optionInfo["value"] > condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    //Is empty
    else if (
      condition.operator == "Is empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    //Is not empty
    else if (
      condition.operator == "Is not empty" &&
      optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Is not empty" &&
      !optionInfo["value"] &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    //Contains
    else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    //Does not contain
    else if (
      condition.operator == "Does not contain" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      !optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, true, condition);
      ans = true;
    } else if (
      condition.operator == "Does not contains" &&
      optionInfo["value"] &&
      condition["operator_value"] &&
      optionInfo["value"].includes(condition["operator_value"]) &&
      condition["question_id_from_locator"] == optionInfo["question_id"]
    ) {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    } else {
      this.hideAllQuestionsTillDestination(ques, quesIndex, condition.question_destination, false, condition);
      ans = false;
    }

    return ans;
  }

  /**
   * fn will destination question when passing question destination Id
   */
  getDestination(question_destination: any) {
    let quesReqd: any;

    this.sections.forEach((section: any) => {
      section.questions.forEach((ques: any) => {
        let qArr = ques.url.split("/");
        let id = +qArr[qArr.length - 2];

        if (id == question_destination) {
          quesReqd = ques;
        }
      });
    });

    return quesReqd;
  }

  ngOnDestroy() {
    clearInterval(this.intervalForReportLoad);
    this.dataShareSub ? this.dataShareSub.unsubscribe() : "";
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.focusAnswerOkClickedSub.unsubscribe();
    // Clean up the timeout on component destroy
    if (this.questionContainerStyleTimeout) {
      clearTimeout(this.questionContainerStyleTimeout);
    }
  }
}
