// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  expiry_min:20,
  production: true,
  version: env.npm_package_version + '-qa',
  deployment_version: env.build_version,
  BASE_URL: 'https://qaapp.ambivista.com/',
  PLATFORM_BASE_URL: 'https://webapp7-qa.ambivista.com/',
  serverUrl: '',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  geoLocationApiKey : '9a4d672cc0e742359cbed45d5b086353',
  editorApiKey:"vupbl94ed00x3yd8gr21a8jx4dzqsnpw6b1vl2v19qzo213a"
};
