import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentDataService } from '../../core/assessment-data.service';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { Observable, Subject, concat, of, debounceTime, distinctUntilChanged, tap, switchMap, Subscription, BehaviorSubject, map } from 'rxjs';
import { EmailTemplate, EmailTemplateResponse } from '../../models/email-template.model';
import { UtilsService } from '../../_helpers/utils.service';




@Component({
  selector: 'app-template-list-select',
  templateUrl: './template-list-select.component.html',
  styleUrls: ['./template-list-select.component.scss']
})
export class TemplateListSelectComponent implements OnInit {

  @Input() templateType = "portal"
  @Input() form: FormGroup;
  @Input() templateId: number;
  @Input() templateIndexToAutoSelect: number = 0;

  @Input() patchControls:boolean = true;

  @Output() onEmailBodyChanged = new EventEmitter();


  constructor(
    private assessmentDataService: AssessmentDataService,
    private modalService: NgbModal
  ) {

  }

  ngOnInit(): void {
    this.getInitialTemplates();
  }


  templates_loaded: boolean;
  templatePageNo: number = 0;
  initialTemplateList: any = [];
  templateName: any;
  getInitialTemplates() {
    if (!this.templates_loaded) {
      this.templatePageNo++;
      this.assessmentDataService
        .getEmailTemplates(this.getQueryParams())
        .subscribe({
          next: (data: EmailTemplateResponse) => {

            let hasInitialTemplates: boolean = !!this.initialTemplateList.length;
            if (hasInitialTemplates) {
              this.handleIfInitialTemplatesExist(data.results)
            } else {
              this.initialTemplateList = data.results;
            }

            this.loadTemplates();
            if(this.patchControls){
              this.fillDefault();
            }

            if(this.templateId){
              let selectedTemplate = this.initialTemplateList.find(template=>template.id == this.templateId)
              this.templateName = selectedTemplate?.name;
              this.lookForUpdatesInEditor(selectedTemplate?.message);
            }
      
          },
          error: (err) => {
            if (err.error.error_msg == "Invalid page.") {
              this.templates_loaded = true;
            }
            this.loadTemplates();
          }
        });
    }
  }

  getQueryParams(){
    let search = this.typedValue$.value ? this.typedValue$.value.trim() : ""
    return UtilsService.createHttpParams({
      type:this.templateType,
      deactivated:'false',
      ordering: search ? "name" :  "created_at",
      page:this.templatePageNo,
      search
    })
  }

  handleIfInitialTemplatesExist(emailTemplates: EmailTemplate[]) {
    let existingTemplateIds: any = [];
    existingTemplateIds = this.getInitialTemplateIds();

    for (let i = 0; i < emailTemplates.length; i++) {
      const template = emailTemplates[i];
      const doesTemplateExistInInitialTemplates: boolean = existingTemplateIds.indexOf(template.id) != -1;
      if (!doesTemplateExistInInitialTemplates) {
        this.initialTemplateList = [
          ...this.initialTemplateList,
          template,
        ];
      }

    }
  }

  getInitialTemplateIds() {
    return this.initialTemplateList.map((obj: any) => {
      return obj.id;
    });
  }

  fillDefault() {
    if (this.hasInitialTemplates) {
      const selectedTemplate = this.initialTemplateList[this.templateIndexToAutoSelect];
  
      if (this.mSubscription) {
        this.mSubscription.unsubscribe();
      }
  
      this.templateName = selectedTemplate.name;
      this.form.controls["email_template"].patchValue(selectedTemplate.id);
  
      if (selectedTemplate.subject) {
        setTimeout(() => {
          this.form.controls["subject"]?.patchValue(selectedTemplate.subject);
        }, 1000);
      }

      this.form.controls["message"].patchValue(selectedTemplate?.message);
      this.lookForUpdatesInEditor(selectedTemplate?.message);
    }
  }
  

  onTemplateSelect(template: any) {

    let isMessageDirty:boolean = this.form.controls["message"].dirty;
    let isPrestine:boolean = this.form.controls["message"].pristine;

    if(!isPrestine){
      this.openConfirmationModal(template)
      
    }else{
      this.onSelectTemplate(template);
      
    }
    this.form.controls["message"].markAsPristine();

  }

  openConfirmationModal(template:any){
    let modalOptions: NgbModalOptions = {
      centered: true,
      keyboard: false,
      size: "sm",
      backdrop: "static",
      windowClass: "modal-holder",
    }
    const modalRef = this.modalService.open(ConfirmationModalComponent, modalOptions);
    modalRef.componentInstance.description = "Selecting this email template will replace your preexisting content. Do you wish to continue?"
    modalRef.componentInstance.confirmButtonText = "Confirm";
    modalRef.componentInstance.cancelButtonText = "Cancel";

    modalRef.result.then(
      (yesClicked) => {
        this.onSelectTemplate(template)
        
      })
  }


  onSelectTemplate(template){
    if (this.mSubscription) {
      this.mSubscription.unsubscribe();
    }
    if (template && template.message) {
      this.onEmailBodyChanged.emit(template.message);
      this.form.controls["email_template"].patchValue(
        template.id
      );
      this.form.controls["message"].patchValue(template.message);
      if (template.subject) {
        this.form.controls["subject"]?.patchValue(
          template.subject
        );
      }
      this.lookForUpdatesInEditor(template.message);
    } else {
      this.onEmailBodyChanged.emit("");
      this.form.controls["email_template"].patchValue(null);
      this.form.controls["message"].patchValue("");
      this.form.controls["subject"]?.patchValue("");
    }
  }

  searchLoading: boolean;
  filteredTemplates$: Observable<any>;
  typedValue$ = new BehaviorSubject<string>("");
  loadTemplates() {
    let initialTemplates$:Observable<EmailTemplate[]> = of(this.initialTemplateList);
    let typeAheadSubscription$:Observable<any> =  this.typedValue$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap((val) => {
        this.searchLoading = true;
      }),
      switchMap((term) =>
        this.assessmentDataService
          .getEmailTemplates(this.getQueryParams())
          .pipe(
            map((data:EmailTemplateResponse)=>data.results),
            tap((data) => {
              {
                this.searchLoading = false;
              }
            })
          )
      )
    )
    this.filteredTemplates$ = concat(initialTemplates$, typeAheadSubscription$)
  }

  search_template_term: any = "";
  itemCleared() {
    this.templatePageNo = 1;
    this.templates_loaded = false;
    this.search_template_term = "";

    this.onEmailBodyChanged.emit("");
    this.form.controls["email_template"].patchValue(null);
    this.form.controls["message"].patchValue("");
  }

  getBlurValue() {
    if (!this.templateName) {
      this.itemCleared();
    }
  }

  mSubscription = new Subscription();
  lookForUpdatesInEditor(message: any) {
    this.mSubscription = this.form.controls[
      "message"
    ].valueChanges.subscribe((newMessage: any) => {
      if (newMessage !== message) {
        this.templateName = null;
        this.form.controls["email_template"].patchValue(null);
      }
    });
  }

  get hasInitialTemplates() {
    return this.initialTemplateList && this.initialTemplateList.length > 0
  }
}
