import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AssessmentDataService } from "../../../../../../app/core/assessment-data.service";
@Component({
  selector: "app-common-logic",
  templateUrl: "./app-common-logic.component.html",
  styleUrls: ["./app-common-logic.component.scss"],
})
export class CommonLogicComponent implements OnInit {
  @Output()
  onCloseModalTriggered: EventEmitter<any> = new EventEmitter();

  @Output()
  submitTriggered: EventEmitter<any> = new EventEmitter();

  @Input() allQuestions: any;

  @Input() currentQuestion: any;
  @Input() surveyData: any;

  /**
   * Observable for shifts list.
   */
  quesOptions$: Observable<any>;

  isDefault: boolean = false;
  postQuesSet: any = [];
  postSameSecQuesSet: any = [];
  postSecSet: any = [];
  includeSecQuesSet: any = [];

  conditionSetForMCQ: any = [
    { id: "Is Selected", name: "Is Selected" },
    { id: "Is Not Selected", name: "Is Not Selected" },
  ];

  conditionSetForTextbox: any = [
    { id: "Is greater than", name: "Is greater than" },
    { id: "Is greater than or equal to", name: "Is greater than or equal to" },
    { id: "Is less than", name: "Is less than" },
    { id: "Is less than or equal to", name: "Is less than or equal to" },
    { id: "Is equal to", name: "Is equal to" },
    { id: "Is not equal to", name: "Is not equal to" },
    { id: "Is empty", name: "Is empty" },
    { id: "Is not empty", name: "Is not empty" },
    { id: "Contains", name: "Contains" },
    { id: "Does not contain", name: "Does not contain" },
  ];

  interConditionSet: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];

  id: any;
  displayLogicForm: FormGroup;
  isSubmitted: boolean = false;
  type: any = "display";
  editCondition: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private assessmentData: AssessmentDataService
  ) {}
  ngOnInit() {
    this.createQuestionList();
    this.createForm();
    this.checkForEditCase();
  }

  editCase: boolean = false;
  displayLogicId: any;
  checkForEditCase() {
    if (this.currentQuestion.logic && this.currentQuestion.logic.length) {
      this.editCase = true;
    } else {
    }
  }

  //List of all mcq and text entry type questions
  createQuestionList() {
    let cQues: boolean = false;
    let cSec: any;
    let cSecIdArr = this.currentQuestion.section.split("/");
    let currSecId = +cSecIdArr[cSecIdArr.length - 2];
    this.allQuestions.forEach((sec: any) => {
      // if (sec.obj_type == "pagebreak") {
      //   return;
      // }
      let sArr = sec.url.split("/");
      let sId = +sArr[sArr.length - 2];
      if (cQues) {
        sec["_id"] = sId;
        this.postSecSet.push(sec);
        this.includeSecQuesSet.push(sec);
      }
      if (currSecId == sId) {
        sec["_id"] = sId;
        this.includeSecQuesSet.push(sec);
      }
      sec.questions.forEach((ques: any) => {
        if (ques.obj_type != "question") {
          return;
        }
        if (cQues && sId == cSec) {
          if (ques["_type"] == 8) {
            ques["name"] = this.extractContent(ques.text);
          } else {
            ques["name"] =
              "Q" + ques.numbering + " " + this.extractContent(ques.text);
          }

          let qArr = ques.url.split("/");
          let id = +qArr[qArr.length - 2];
          ques["_id"] = id;
          this.postSameSecQuesSet.push(ques);
        }
        if (cQues) {
          if (ques["_type"] == 8) {
            ques["name"] = this.extractContent(ques.text);
          } else {
            ques["name"] =
              "Q" + ques.numbering + " " + this.extractContent(ques.text);
          }
          let qArr = ques.url.split("/");
          let id = +qArr[qArr.length - 2];
          ques["_id"] = id;
          // this.postQuesSet.push(ques);
        } else if (ques.id == this.currentQuestion.id && !cQues) {
          cQues = true;
          cSec = sId;
        }
      });
    });
    this.postSecSet.push({ name: "End of Survey", _id: "end_of_survey" });
    this.postSameSecQuesSet.push(
      { name: "End of Section", _id: "end_of_block" },
      { name: "End of Survey", _id: "end_of_survey" }
    );
  }

  extractContent(s: any) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  getInnerHtml() {
    return `Response Logic ${this.currentQuestion.tag} ${this.currentQuestion.text}`;
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public findInvalidControlsRecursive(formToInvestigate:FormGroup|FormArray):string[] {
    var invalidControls:string[] = [];
    let recursiveFunc = (form:FormGroup|FormArray) => {
      Object.keys(form.controls).forEach(field => { 
        const control = form.get(field);
        if (control?.invalid) invalidControls.push(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }        
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  save(isCancel?: boolean) {
    
    if (this.displayLogicForm.invalid) {
      console.log(this.findInvalidControlsRecursive(this.displayLogicForm))
      this.markFormGroupTouched(this.displayLogicForm);
      return;
    }
    this.isSubmitted = true;
    let obj = {};
    obj = this.displayLogicForm.getRawValue();
    // if (
    //   this.currentQuestion.logic &&
    //   this.currentQuestion.logic.display_logic &&
    //   this.currentQuestion.logic.display_logic[0] &&
    //   this.currentQuestion.logic.display_logic[0].conditions
    // ) {
    //   obj["id"] = this.displayLogicId;
    // }

    obj = this.createPostObject(obj);
    if (obj) obj = this.sendEditCase(obj);
    if (!obj) {
      return;
    }

    let qArr = this.currentQuestion.url.split("/");
    let id = +qArr[qArr.length - 2];
    let fObj = { body: obj, id: id, isEdit: this.editCase, isCancel: isCancel };
    this.submitTriggered.next(fObj);
    setTimeout(() => {
      this.onCloseModalTriggered.next(true);
    }, 300);
  }

  sendEditCase(object: any) {
    let obj = object["data"];
    if (this.currentQuestion.logic && this.currentQuestion.logic.length) {
      obj.forEach((ob: any, index: any) => {
        if (
          this.currentQuestion.logic[index] &&
          this.currentQuestion.logic[index]["id"]
        ) {
          ob["id"] = this.currentQuestion.logic[index]["id"];
          if (this.currentQuestion.logic[index]["conditions"]) {
            ob["conditions"].forEach((cond: any, idx: any) => {
              if (
                this.currentQuestion.logic[index]["conditions"][idx] &&
                this.currentQuestion.logic[index]["conditions"][idx]["id"]
              ) {
                cond["id"] = this.currentQuestion.logic[index]["conditions"][
                  idx
                ]["id"];
              }
            });
          }
        }
      });
    }
    return object;
  }

  createPostObject(obj: any) {
    let arr = obj.data;
    let duplicateActions: boolean = false;
    let contradictCondition: boolean = false;

    let conditionArr: any = [];
    let branchingArr: any = [];
    let branchingMArr: any = [];

    let actionNames = [
      { name: "display", applied: false },
      { name: "skip", applied: false },
      { name: "branching", applied: false },
    ];
    arr.forEach((ar: any, index: any) => {
      if (ar["conditions"][0]) {
        ar["logic_type"] = ar["conditions"][0]["logic_type_name"];
      }
      if (ar["logic_type"] == "branching") {
        branchingMArr.push(ar["conditions"]);
      }

      ar["conditions"].forEach((cond: any, cIndex: any) => {
        let logic_type = ar["logic_type"];
        delete cond["logic_type_name"];
        delete cond["question_id_from_locator"];
        if (cIndex > 0) {
          delete cond["question_destination_type"];
        }
        if (
          cond["question_destination_type"] &&
          cond["question_destination_type"] == "end_of_survey"
        ) {
          cond["section_destination"] = null;
          cond["question_destination"] = null;
          // cond["question_destination_type"] = "end_of_survey";
        } else if (
          cond["section_destination"] == "end_of_survey" ||
          cond["question_destination"] == "end_of_survey" ||
          ar["conditions"][0]["question_destination_type"] == "end_of_survey"
        ) {
          cond["question_destination_type"] = "end_of_survey";
          cond["section_destination"] = null;
          cond["question_destination"] = null;
        }else if (
          cond["question_destination"] == "end_of_block" ||
          ar["conditions"][0]["question_destination_type"] == "end_of_block"
        ) {
          cond["question_destination_type"] = "end_of_block";
          cond["question_destination"] = null;
        }

        if (
          cond["question_destination_type"] &&
          cond["question_destination_type"] == "end_of_block"
        ) {
          cond["question_destination"] = null;
        } else if (cond["section_destination"] == "end_of_block") {
          cond["question_destination_type"] = "end_of_block";
          cond["question_destination"] = null;
        }
        if (
          logic_type == "display" ||
          (logic_type == "skip" && !cond["question_destination_type"])
        ) {
          cond["question_destination_type"] = "question";
        } else if (
          logic_type == "branching" &&
          !cond["question_destination_type"]
        ) {
          cond["question_destination_type"] = "question";
        }

        cond["question_destination"] =
          ar["conditions"][0]["question_destination"];
        cond["question_type"] = ar["conditions"][0]["question_type"];
        cond["section_destination"] =
          ar["conditions"][0]["section_destination"];

        if (
          cond["operator"] == "Is empty" ||
          cond["operator"] == "Is not empty"
        ) {
          cond["operator_value"] = null;
        }

        conditionArr.forEach((cnd: any) => {
          if (
            cnd.question_choice_locator == cond["question_choice_locator"] &&
            cnd.operator == cond["operator"]
          ) {
             contradictCondition = true;
          }
        });

        if (
          ar["logic_type"] == "branching" &&
          ar["conditions"] &&
          ar["conditions"].length == 1
        ) {
          cond["index"] = index;
          if (cond) branchingArr.push(cond);
        }
        conditionArr.push({
          logic_type: ar["logic_type"],
          question_choice_locator: cond["question_choice_locator"],
          operator: cond["operator"],
        });
      });

      actionNames.forEach((action: any) => {
        if (ar["logic_type"] == "branching" || ar["logic_type"] == "display") {
          return;
        } else if (action.name == ar["logic_type"] && action.applied == true) {
          duplicateActions = true;
        } else if (action.name == ar["logic_type"]) {
          action.applied = true;
        }
      });
    });

    if (duplicateActions)
      this.toastService.error(
        "Duplicate action types are restricted for skip logic."
      );
    // else if (contradictCondition) {
    //   this.toastService.error("Same conditions can't be applied for actions.");
    // }

    let duplicateBranchCond: any = false;
    branchingArr.forEach((brnch: any, brIndx: any) => {
      branchingArr.forEach((brnch2: any, brIndex2: any) => {
        if (brIndx !== brIndex2 && brnch["index"] !== brnch2["index"]) {
          let thisCond: any = false;
          if (
            brnch["operator"] &&
            brnch2["operator"] &&
            brnch["operator_value"] &&
            brnch2["operator_value"] &&
            brnch["question_choice_locator"] ==
              brnch2["question_choice_locator"] &&
            brnch["operator"] == brnch2["operator"] &&
            brnch["operator_value"] == brnch2["operator_value"]
          ) {
            duplicateBranchCond = true;
            thisCond = true;
          } else if (
            brnch["question_choice_locator"] &&
            brnch2["question_choice_locator"] &&
            brnch["operator"] &&
            brnch2["operator"] &&
            brnch["question_choice_locator"] ==
              brnch2["question_choice_locator"] &&
            brnch["operator"] == brnch2["operator"]
          ) {
            duplicateBranchCond = true;
            thisCond = true;
          }

          // if (
          //   thisCond == true &&
          //   (brnch["conjuction"] == "AND" || brnch2["conjuction"] == "AND")
          // ) {
          //   duplicateBranchCond = true;
          // } else if (
          //   thisCond == true &&
          //   (brnch["conjuction"] == "OR" || brnch2["conjuction"] == "OR")
          // ) {
          //   duplicateBranchCond = false;
          // }
        }
      });
    });
    let duplicateExist: boolean = false;
    if (branchingMArr && branchingMArr.length > 1) {
      duplicateExist = true;
    }
    branchingMArr.forEach((brm1: any, index1: any) => {
      branchingMArr.forEach((brm2: any, index2: any) => {
        if (index1 !== index2 && brm1.length == brm2.length) {
          brm1.forEach((b1: any, b1Index: any) => {
            let b2 = brm2[b1Index];
            if (
              b1["conjuction"] == b2["conjuction"] &&
              b1["operator"] &&
              b2["operator"] &&
              b1["operator_value"] &&
              b2["operator_value"] &&
              b1["question_choice_locator"] == b2["question_choice_locator"] &&
              b1["operator"] == b2["operator"] &&
              b1["operator_value"] == b2["operator_value"]
            ) {
              duplicateExist = true;
            } else if (
              b1["conjuction"] == b2["conjuction"] &&
              b1["question_choice_locator"] &&
              b2["question_choice_locator"] &&
              b1["operator"] &&
              b2["operator"] &&
              b1["question_choice_locator"] == b2["question_choice_locator"] &&
              b1["operator"] == b2["operator"]
            ) {
              duplicateExist = true;
            } else {
              duplicateExist = false;
            }
          });
        } else if (index1 !== index2 && brm1.length !== brm2.length) {
          duplicateExist = false;
        }
      });
    });
    if (duplicateExist || duplicateBranchCond) {
      this.toastService.error(
        "Duplicate conditions are being applied for branching logic."
      );
      return false;
    }

    return duplicateActions ? false : obj;
  }

  closeModal() {
    this.onCloseModalTriggered.next(true);
  }

  disableSubmitButton() {
    return true;
  }

  private createForm() {
    let qArr = this.currentQuestion.url.split("/");
    let id = +qArr[qArr.length - 2];

    this.displayLogicForm = this.formBuilder.group({
      data: new FormArray([]),
    });

    if (
      this.currentQuestion.logic &&
      this.currentQuestion.logic.length &&
      this.currentQuestion.logic[0].conditions &&
      this.currentQuestion.logic[0].conditions.length
    ) {
      this.currentQuestion.logic.forEach((data: any) => {
        this.addRowEditCondition(data);
      });
    } else {
      this.addLogicRow();
    }
  }

  addRowEditCondition(data: any) {
    const control = <FormArray>this.displayLogicForm.controls["data"];
    control.push(this.initList(data));
    return control;
  }

  addLogicRow() {
    this.assessmentData.setLoader({ bool: true });
    setTimeout(() => {
      this.assessmentData.setLoader({ bool: false });
    }, 1000);
    const control = <FormArray>this.displayLogicForm.controls["data"];
    control.push(this.initList());
    return control;
  }

  removeLogicEvent(i: number) {
    // this.assessmentData.setLoader({ bool: true });
    this.removeLogicForEditCase(i);
  }

  removeLogicForEditCase(i: any) {
    let isDelete: boolean = false;
    this.currentQuestion.logic.forEach((lgc: any, index: any) => {
      if (index == i && lgc.id) {
        isDelete = true;
        this.delete(lgc.logic_type, i);
      }
    });

    if (!isDelete) {
      const control = <FormArray>this.displayLogicForm.controls["data"];
      control.removeAt(i);
    }
  }

  currentDeletingLogic: any = null;
  delete(logicType: any, i: any) {
    this.currentDeletingLogic = null;
    let qArr = this.currentQuestion.url.split("/");
    let id = +qArr[qArr.length - 2];

    let logicId: any;
    let arr = this.currentQuestion.logic;
    let logicIdx: any;
    if (arr.length) {
      arr.forEach((ar: any, index: number) => {
        if (ar.logic_type == logicType && i == index) {
          logicId = ar.id;
          this.currentDeletingLogic = ar;
          logicIdx = index;
        }
      });
    }
    this.assessmentData.setLoader({ bool: false });
    // this.toastService.success("Logic removed successfully.");
    // this.assessmentData.setLoader({ bool: false });
    this.assessmentData.deleteDisplayLogic$.next(
      this.currentDeletingLogic
    );

    // let arr = this.currentQuestion.logic;
    // this.currentQuestion.logic.splice(logicIdx, 1);
    let event = {
      body: {
        data: this.currentQuestion.logic,
      },
      id: id,
    };
    if (!this.currentQuestion.logic.length) {
      this.onCloseModalTriggered.next(true);
    }
    this.assessmentData.displayLogicAdded$.next(event);
    const control = <FormArray>this.displayLogicForm.controls["data"];
    control.removeAt(i);
    // this.assessmentData
    //   .deleteLogicCondition(this.surveyData["identifier"], id, logicId)
    //   .subscribe(
    //     (obj: any) => {
    //       this.toastService.success("Logic removed successfully.");
    //       this.assessmentData.setLoader({ bool: false });
    //       this.assessmentData.deleteDisplayLogic$.next(
    //         this.currentDeletingLogic
    //       );

    //       let arr = this.currentQuestion.logic;
    //       this.currentQuestion.logic.splice(logicIdx, 1);
    //       let event = {
    //         body: {
    //           data: this.currentQuestion.logic,
    //         },
    //         id: id,
    //       };
    //       if (!this.currentQuestion.logic.length) {
    //         this.onCloseModalTriggered.next(true);
    //       }
    //       this.assessmentData.displayLogicAdded$.next(event);
    //       const control = <FormArray>this.displayLogicForm.controls["data"];
    //       control.removeAt(i);
    //     },
    //     (error) => {
    //       this.assessmentData.setLoader({ bool: false });
    //       if (error && error.error && error.error.message) {
    //         this.toastService.error(error.error.message);
    //       } else {
    //         this.toastService.error("Something went wrong.");
    //       }
    //     }
    //   );
  }

  private initList(data?: any) {
    const group = new FormGroup({
      logic_type: new FormControl(
        data && data.logic_type ? data.logic_type : null,
        []
      ),
      group_type : new FormControl(
        data && data.group_type ? data.group_type  : (this.currentQuestion._type === 8 ? "rater-group":"question")
      ),
      user_type  : new FormControl(
        data && data.user_type  ? data.user_type   : null
      ),
      conditions: new FormArray([], [Validators.maxLength(50)]),
    });

    if (!data) this.addConditionList(group, data);
    else if (data) {
      data.conditions.forEach((cond: any) => {
        this.addConditionList(
          group,
          cond,
          data && data.logic_type ? data.logic_type : null
        );
      });
    }
    return group;
  }

  addConditionList(group: FormGroup, cond?: any, logicType?: any) {
    const control = <FormArray>group.controls["conditions"];
    control.push(this.initConditionList(cond, logicType));
    return control;
  }

  private initConditionList(data?: any, logicType?: any) {
    let currentQuesName = this.currentQuestion._type === 8 ? this.extractContent(this.currentQuestion.text) : `Q${this.currentQuestion.numbering} ${this.extractContent(this.currentQuestion.text)}`;
    const group = new FormGroup({
      question_id_from_locator: new FormControl(currentQuesName, []),
      question_choice_locator: new FormControl(
        data && data.question_choice_locator
          ? data.question_choice_locator
          : null,
        this.currentQuestion._type === 1 ? [] : []
        // TODO this.currentQuestion._type === 1 ? [Validators.required] : []
      ),
      logic_type_name: new FormControl(logicType ? logicType : null, [
        Validators.required,
      ]),
      operator: new FormControl(data && data.operator ? data.operator : null, [
        Validators.required,
      ]),
      conjuction: new FormControl(
        data && data.conjuction ? data.conjuction : null,
        []
      ),
      question_type: new FormControl(
        data && data.question_type
          ? data.question_type
          : this.currentQuestion._type,
        [Validators.required]
      ),
      question_destination_type: new FormControl(
        data && data.question_destination_type
          ? data.question_destination_type
          : null,
        []
      ),
      operator_value: new FormControl(
        data && data.operator_value ? data.operator_value : null,
        this.currentQuestion._type === 2 ? [Validators.required] : []
      ),

      section_destination: new FormControl(
        data && data.section_destination ? data.section_destination : null,
        []
      ),
      question_destination: new FormControl(
        data && data.question_destination ? data.question_destination : null,
        []
      ),
      redirect_url: new FormControl(
        data && data.redirect_url ? data.redirect_url : null,
      )
    });
    return group;
  }

  getArrayControl() {
    return (<FormArray>this.displayLogicForm.controls["data"]).controls;
  }
}
